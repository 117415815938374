export const languages = {
  login: {
    GRE: {
      language: "Γλώσσα",
      server: "Server",
      username: "Χρήστης",
      password: "Κωδικός",
      nextButton: "Επόμενο",
      connectButton: "Σύνδεση",
      company: "Εταιρεία",
      branch: "Υπ/μα",
      user: "Χρήστης",
    },
    ENG: {
      language: "Language",
      server: "Server",
      username: "Username",
      password: "Password",
      nextButton: "Next",
      connectButton: "Connect",
      company: "Company",
      branch: "Branch",
      user: "User",
    },
  },
  settings: {
    GRE: {
      app: "Εφαρμογή",
      calendar: "Ημερολόγιο",
      cloudStorage: "Αποθηκευτικός χώρος",
      browserLimit: "Όριο εγγραφών ανα λίστα",
      gridVisibleLines: "Ορατές γραμμές σε πλέγμα",
      confirmExit: "Επιβεβαίωση εξόδου",
      askForDeleteInGrid: "Ερώτηση για διαγραφή σε πλέγμα",
      giveNameToUploads: "Αλλαγή σε όνομα επισυναπτόμενου",
      browserSearchActive: "Διατήρηση ενεργής αναζήτηση σε λίστες",
      darkMode: "Σκοτεινή λειτουργία",
      calendarFirstDay: "Πρώτη ημέρα",
      calendarBussinesDays: "Εργάσιμες ημέρες",
      calendarStart: "Έναρξη",
      calendarEnd: "Λήξη",
      calendarColor: "Χρώμα",
      connect: "Σύνδεση",
      disconnect: "Αποσύνδεση",
      enablePortal: "Ενεργοποίηση Portal",
    },
    ENG: {
      app: "Application",
      calendar: "Calendar",
      cloudStorage: "Cloud storage",
      browserLimit: "Limit records on list",
      gridVisibleLines: "Visible lines on grids",
      confirmExit: "Confirm exit",
      askForDeleteInGrid: "Confirm deletion in grids",
      giveNameToUploads: "Give name to attachment",
      browserSearchActive: "Preserve active search on browsers",
      darkMode: "Dark mode",
      calendarFirstDay: "First day",
      calendarBussinesDays: "Bussiness days",
      calendarStart: "Start",
      calendarEnd: "End",
      calendarColor: "Color",
      connect: "Connect",
      disconnect: "Disconnect",
      enablePortal: "Enable Portal",
    },
  },
  generalText: {
    GRE: {
      yes: "Ναι",
      no: "Όχι",
      close: "Κλείσιμο",
      done: "Κλείσιμο",
      calendar: "Ημερολόγιο",
      list: "Λίστα",
      print: "Εκτύπωση",
      signature: "Υπογραφή",
      views: "Μορφή",
      cancel: "Ακύρωση",
      closing: "Κλείσιμο",
      clear: "Καθαρισμός",
      apply: "Εφαρμογή",
      proceed: "Συνέχεια",
      save: "Καταχώρηση",
      confirm: "Επιβεβαίωση",
      printer: "Εκτυπωτής",
      form: "Φόρμα",
      actions: "Ενέργειες",
      relJobs: "Σχετικές εργασίες",
      edit: "Επεξεργασία",
      newRecord: "Νέα εγγραφή",
      delete: "Διαγραφή",
      search: "Αναζήτηση",
      question: "Ερώτηση",
      shouldDeleteLine: "Επιβεβαίωση διαγραφής;",
      shouldSaveRecordText: "Να καταχωρηθεί η εγγραφή;",
      download: "Λήψη",
      preview: "Preview",
      logout: "Αποσύνδεση",
      sureAboutLogout: "Είστε σίγουροι για τον τερματισμό της εφαρμογής;",
      fileCreatedSuccess: "Επιτυχής δημιουργία αρχείου",
      checkInOutSuccess: "Επιτυχής καταχώρηση",
      saveTemplate: "Αποθήκευση προτύπου",
      aboutToDelete: "Πρόκειται να διαγράψετε οριστικά μια εγγραφή!",
      wishToContinue: "Θέλετε να συνεχίσετε;",
      error: "Σφάλμα",
      info: "Πληροφορία",
      infoSums: "Πληροφορίες",
      sums: "Σύνολα",
      noDataToShow: "Δεν υπάρχουν δεδομένα προς απεικόνιση",
      checkFilters: "Ελέγξτε τα φίλτρα",
      sort: "Ταξινόμηση",
      asc: "Αύξουσα",
      desc: "Φθίνουσα",
      successSignature: "Επιτυχής καταχώρηση υπογραφής",
    },
    ENG: {
      yes: "Yes",
      no: "No",
      close: "Close",
      done: "Done",
      calendar: "Calendar",
      list: "List",
      print: "Print",
      signature: "Signature",
      views: "View",
      cancel: "Cancel",
      closing: "Cancel",
      clear: "Clear",
      apply: "Apply",
      proceed: "Proceed",
      save: "Save",
      confirm: "Confirm",
      printer: "Printer",
      form: "Form",
      actions: "Actions",
      relJobs: "Relative jobs",
      edit: "Edit",
      newRecord: "New record",
      delete: "Delete",
      search: "Search",
      question: "Question",
      shouldDeleteLine: "Delete current line?",
      shouldSaveRecordText: "Save current record?",
      download: "Download",
      preview: "Preview",
      logout: "Log out",
      sureAboutLogout: "Are you sure about ending the application?",
      fileCreatedSuccess: "File created successfully",
      checkInOutSuccess: "Successful",
      saveTemplate: "Save template",
      aboutToDelete: "You are going to delete a record permanently!",
      wishToContinue: "Are you sure?",
      error: "Error",
      info: "Info",
      infoSums: "Info",
      sums: "Sums",
      noDataToShow: "There are no data to show",
      checkFilters: "Please check filters",
      sort: "Sort",
      asc: "Ascending",
      desc: "Descending",
      successSignature: "Signature saved",
    },
  },
  warnings: {
    GRE: {
      aboutToEnd: "Η άδεια χρήσης του λογισμικού σας λήγει την ",
      isEnded: "Η άδεια χρήσης του λογισμικού σας έληξε.",
      callAt: "Kαλέστε στο: ",
      end: "Λήξη:",
      contact1: "Παρακαλούμε, επικοινωνήστε έγκαιρα με την DayOne στο: ",
      contact2: "για ανανέωση.",
      note: "Σημειώνεται πως με την λήξη, παύει αυτομάτως η πρόσβαση στην Web πλατφόρμα.",
    },
    ENG: {
      aboutToEnd: "Your software license expires on ",
      isEnded: "Your software license has expired.",
      callAt: "Call at: ",
      end: "Ended:",
      contact1: "Please contact DayOne in time at: ",
      contact2: "for renewal.",
      note: "It is noted that upon expiration, access to the Web platform automatically ceases.",
    },
  },
  calendar: {
    GRE: {
      month: "Μήνας",
      week: "Εβδομάδα",
      day: "Ημέρα",
      today: "Σήμερα",
    },
    ENG: {
      month: "Month",
      week: "Week",
      day: "Day",
      today: "Today",
    },
  },
  user: {
    GRE: {
      name: "Όνομα",
      companyName: "Επωνυμία",
      branchName: "Υπ/μα",
      address: "Διεύθυνση",
      afm: "ΑΦΜ",
      irs: "ΔΟΥ",
      d1users: "Web CRM χρήστες",
      d1blcdate: "Λήξη",
      changePassword: "Αλλαγή κωδικού",
      oldPassword: "Παλιός κωδικός",
      newPassword: "Νέος κωδικός",
      confirmPassword: "Επιβεβαίωση κωδικού",
    },
    ENG: {
      name: "Name",
      companyName: "Company name",
      branchName: "Branch",
      address: "Address",
      afm: "V.A.T. number",
      irs: "I.R.S.",
      d1users: "Web CRM users",
      d1blcdate: "Block date",
      changePassword: "Change password",
      oldPassword: "Old password",
      newPassword: "New password",
      confirmPassword: "Confirm password",
    },
  },
};
