import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";
import PrintBrowser from "./PrintBrowser";
import { getBrowserInfoSums, getCommandByIndex, getTableColumns } from "../../../../../redux/selectors";
import { useSelector } from "react-redux";
import { calendarObjectsFields } from "../../../../../logic/calendarObjectFields";
import CalendarListView from "./CalendarListView";
import { languages } from "../../../../languages";
import Sums from "./Sums";
import Sort from "./Sort";

const MenuBrowser = ({ tabID }) => {
  const object = useSelector((state) => getCommandByIndex(state, tabID).split("&")[0]);
  const sums = useSelector((state) => getBrowserInfoSums(state, tabID)) ?? [""];
  const tableColumns = useSelector((state) => getTableColumns(state, tabID));
  const fromDateIndex = tableColumns.findIndex(
    (column) => column.dataIndex == calendarObjectsFields?.[object]?.fromDate
  );
  const toDateIndex = tableColumns.findIndex((column) => column.dataIndex == calendarObjectsFields?.[object]?.toDate);

  const [canCalendar, setCanCalendar] = React.useState(fromDateIndex !== -1 && toDateIndex !== -1);

  const currentLang = useSelector((state) => state.settings.language);

  React.useEffect(() => {
    setCanCalendar(fromDateIndex !== -1 && toDateIndex !== -1);
  }, []);

  const RelJobDropDown = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      className="d-flex align-items-center justify-content-center user-select-none"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));
  return (
    <Dropdown className="d-flex" align="start">
      <Dropdown.Toggle variant="secondary" as={RelJobDropDown}>
        <Button variant="secondary">
          <HiDotsVertical />
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="shadow"
        style={{
          maxWidth: "55vw",
          // minWidth: "50vw",
          maxHeight: "55vh",
          overflowY: "auto",
          overflow: "hidden",
        }}
      >
        <div className="d-flex flex-column">
          {canCalendar && (
            <>
              <span className="s1-browser-menu-title">{languages.generalText[currentLang].views}</span>
              <CalendarListView tabID={tabID} />
            </>
          )}
          <span className="s1-browser-menu-title">{languages.generalText[currentLang].actions}</span>
          <PrintBrowser tabID={tabID} />
          <Sort tabID={tabID} />
          <Sums tabID={tabID} />
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MenuBrowser;
