import React from "react";
import { useSelector } from "react-redux";
import { getReportData, getReportInfo } from "../../../redux/selectors";
import ReportFooter from "./ReportFooter";
import ErrorDisplay from "../ErrorDisplay";
import NoBrowserData from "../NoBrowserData";
import "../../../css/report.css";

const Browser = ({ tabID }) => {
  const reportInfo = useSelector((state) => getReportInfo(state, tabID));
  const reportData = useSelector((state) => getReportData(state, tabID));
  return (
    <>
      <div style={{ flex: 1, overflow: "auto" }}>
        {reportInfo?.success ? (
          reportInfo?.npages > 0 ? (
            reportData && (
              <div
                className="softone-report"
                dangerouslySetInnerHTML={{ __html: reportData?.data }}
                style={{
                  flex: 1,
                  overflow: "auto",
                  height: "100%",
                  width: "100%",
                }}
              />
            )
          ) : (
            <NoBrowserData tabID={tabID} />
          )
        ) : (
          <ErrorDisplay error={reportInfo?.error} />
        )}
      </div>
      {reportInfo?.success && reportData?.success && <ReportFooter tabID={tabID} />}
    </>
  );
};

export default Browser;
