import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDetailGrid,
  deleteLineFromGrid,
  setCurrentDetailLine,
  triggerGridChange,
} from "../../../redux/features/ui/uiSlice";
import { getCurrentGridLine, getNewData, getObjectState, getRecData } from "../../../redux/selectors";
import { ImBin } from "react-icons/im";
import { calculate } from "../../../services/calculate";
import DeleteLineModal from "../detail grid/DeleteLineModal";
import { Table } from "react-bootstrap";
import S1RelDocsBar from "./S1RelDocsBar";

const S1RelDocs = ({ tabID, element }) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.app);
  const dataApi = useSelector((state) => getRecData(state, tabID)?.[element.model]);
  const currentGridLine = useSelector((state) => getCurrentGridLine(state, tabID, element.model));

  const newData = useSelector((state) => getNewData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const columns = [
    {
      Header: "Type",
      accessor: "TYPE",
    },
    {
      Header: "Name",
      accessor: "NAME",
    },
    {
      Header: "Link",
      accessor: "SOFNAME",
    },
  ];

  const data = React.useMemo(
    () =>
      dataApi
        ? dataApi.map((item) => ({
            ...item,
            TYPE: getType(getName(item.NAME ?? item.SOFNAME)),
            // NAME: item.NAME,
          }))
        : [],
    [dataApi]
  );

  React.useEffect(() => {
    dispatch(addDetailGrid({ tabID, value: { ...element, hiddenColumns: ["LINENUM"] } }));
  }, []);

  const handleRowClick = (value) => {
    dispatch(setCurrentDetailLine({ tabID, name: element.model, value: { ...value, name: element.model } }));
    if (isValidUrl(dataApi[value.index].SOFNAME)) {
      window.open(dataApi[value.index].SOFNAME);
    }
  };

  const handleDelete = async (e, lineIndex) => {
    e.stopPropagation();
    dispatch(setCurrentDetailLine({ tabID, name: element.model, value: { index: lineIndex, name: element.model } }));

    if (settings.askForDeleteInGrid == 1) {
      setOpenDeleteModal(true);
    } else {
      var gridLines = newData?.[element.model] ?? [];
      gridLines.length == 0 && dispatch(triggerGridChange({ tabID, name: element.model }));
      dispatch(deleteLineFromGrid({ tabID, name: element.model, lineIndex }));
      await calculate(tabID);
      dispatch(setCurrentDetailLine({ tabID, name: element.model, value: undefined }));
    }
  };
  return (
    <div className="s1reldocs w-100">
      <S1RelDocsBar tabID={tabID} element={element} />
      <div className="rel-doc-lines" style={{ height: "15rem" }}>
        {(data ?? []).map((item, index) => {
          return (
            <div
              key={index}
              className={`rel-doc-line ${currentGridLine?.index == index ? " row-selected" : ""}`}
              onClick={() => handleRowClick({ index })}
            >
              <div style={{ maxWidth: objectState != "view" ? "calc(100% - 2rem)" : "100%" }}>
                {columns.map((column, sIndex) => {
                  return (
                    <div key={sIndex} className="rel-doc-line-col">
                      <span>{`${column.Header}: `}</span>
                      <span>{item[column.accessor]}</span>
                    </div>
                  );
                })}
              </div>
              {objectState != "view" && (
                <div style={{ flex: 1 }} className="browser-table-td d-flex justify-content-center align-items-center">
                  <ImBin role="button" size="1.5rem" color="var(--bs-danger)" onClick={(e) => handleDelete(e, index)} />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <DeleteLineModal tabID={tabID} show={openDeleteModal} setShow={setOpenDeleteModal} element={element} />
    </div>
  );
};

export default S1RelDocs;

const isFolder = (string) => string.replace(/^.*[\\\/]/, "").indexOf(".") == -1;

const getType = (string) => {
  if (isFolder(string)) {
    return "folder";
  } else {
    const path = getPathFromUrl(string.replace(/^.*[\\\/]/, ""));
    return "." + path.split(".")[path.split(".").length - 1].toLowerCase();
  }
};

const getName = (string) => getPathFromUrl(decodeURI(string).replace(/^.*[\\\/]/, ""));

const getPathFromUrl = (url) => url.split("?")[0];

const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
