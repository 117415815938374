import React from "react";
import TopBarOnUserInfo from "../../bars/top bars/user info/TopBarOnUserInfo";
import UserPanel from "./UserPanel";

const User = () => {
  return (
    <>
      <TopBarOnUserInfo />
      <div className="s1-userinfo">
        <UserPanel />
      </div>
    </>
  );
};

export default User;
