import React from "react";
import { Button, Modal } from "react-bootstrap";
import { BiError } from "react-icons/bi";

const LoginError = ({ show, setShow, message }) => {
  return (
    <Modal show={show} centered backdrop="static" keyboard={false} size="lg">
      <span
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%,-50%)",
          backgroundColor: "var(--bs-danger)",
          borderRadius: "50%",
          color: "var(--bs-light)",
        }}
        className="d-flex justify-content-center align-items-center shadow"
      >
        <BiError size="3rem" className="m-1 p-1" />
      </span>

      <Modal.Body className="d-flex justify-content-start align-items-center mt-4" style={{ userSelect: "none" }}>
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </Modal.Body>
      <Modal.Footer className="p-0 d-flex align-items-center justify-content-center">
        <Button variant="danger w-100" onClick={() => setShow(false)}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginError;
