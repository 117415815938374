import React from "react";
import Back from "./Back";
import Password from "./Password";
const TopBarOnUserInfo = () => {
  return (
    <div className="object-top-bar justify-content-between">
      <Back />
      <Password />
    </div>
  );
};

export default TopBarOnUserInfo;
