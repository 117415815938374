import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setLoading,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";
import { BsSearch } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import {
  getCommandParams,
  getDialog,
  getFilters,
  getFormDesign,
  getObjectState,
  getRecData,
} from "../../../redux/selectors";
import getSelectorData from "../../../services/getSelectorData";
import PickerSpinner from "../PickerSpinner";
import getSelectorFields from "../../../services/getSelectorFields";
import { setError } from "../../../redux/features/modals/modalsSlice";
import { calculate } from "../../../services/calculate";
import cleanEditor from "../../functions/cleanEditor";
import { Button, ButtonGroup, FloatingLabel, Form, InputGroup, Modal } from "react-bootstrap";
import { HiX, HiCheck } from "react-icons/hi";
import { languages } from "../../languages";

const S1MemoryTable = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getRecData(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const dialog = useSelector((state) => getDialog(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID)?.data);
  const currentLang = useSelector((state) => state.settings.language);

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const currentGridLine = grid?.currentLine;

  const objectState = useSelector((state) => getObjectState(state, tabID));

  const [pickerLoading, setPickerLoading] = React.useState(false);
  const [selectorData, setSelectorData] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [editorString, setEditorString] = React.useState("");

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model =
    screenState == "form"
      ? formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0]
      : dialog && dialog.model[table].fields.filter((el) => el.name == key)[0];

  const searchRef = React.useRef(null);
  const tableRef = React.useRef(null);

  const [searchText, setSearchText] = React.useState("");

  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState("");

  const handleChange = async (value) => {
    if (grid) {
      dispatch(setGridChange({ tabID, name: grid.model, key, value }));
    } else {
      dispatch(setChange({ tabID, table, key, value }));
      model?.updates && (await calculate(tabID, objectState));
    }
  };

  React.useEffect(() => {
    const setTheValue = async () => {
      dispatch(setLoading({ show: true, tabID }));
      const editor = cleanEditor(tabID, model, element, grid);
      setEditorString(editor);
      const GetSelectorData = await getSelectorData({ value: "", editor, tabID });
      setSelectorData(GetSelectorData);
      if (GetSelectorData.success) {
        if (element.selectorVF && element.selectorVF != "") {
          const selectorObj = {};
          GetSelectorData.model.map((item, index) => {
            selectorObj[item.name] = GetSelectorData.data.find((x) => x[0] == element.value)[index];
          });
          const resultfields = element.selectorVF.split("|").map((i) => i.split(".")[1]);

          dispatch(
            setFilter({
              tabID,
              table,
              key,
              value: `${selectorObj?.[resultfields[0]]}|${selectorObj?.[resultfields[1]]}`,
            })
          );
        }
      }
      dispatch(setLoading({ show: false, tabID }));
    };

    if (screenState == "dialog" && element.value && element.value != "") {
      if (element.selectorVF && element.selectorVF != "") {
        setTheValue();
      } else {
        setValue(element.value);
      }
    }
  }, []);

  React.useEffect(() => {
    if (screenState == "form" && !grid) {
      const values = (data?.[table]?.[0]?.[key] ?? "").split("|");
      setValue(values[values.length - 1]);
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if (screenState == "dialog") {
      const values = (filters?.[table]?.[key] ?? "").split("|");
      setValue(values[values.length - 1]);
    }
  }, [filters?.[table]?.[key]]);

  React.useEffect(() => {
    if ((currentGridLine?.line?.[key] || currentGridLine?.line?.[key] == "") && grid) {
      const values = (currentGridLine?.line?.[key] ?? "").split("|");
      setValue(values[values.length - 1]);
    }
  }, [currentGridLine?.line?.[key]]);

  React.useEffect(() => {
    setSearchText("");
  }, [openPicker]);

  const handlePicker = async (e) => {
    e.stopPropagation();
    if (openPicker) {
      setOpenPicker(false);
    } else {
      setSearchText("");
      setPickerLoading(true);
      setOpenPicker(true);
      const editor = cleanEditor(tabID, model, element, grid);
      setEditorString(editor);
      const GetSelectorData = await getSelectorData({ value: "", editor, tabID });
      setSelectorData(GetSelectorData);
      setPickerLoading(false);
    }
  };

  const handleClear = () => {
    setSelectedRow([]);
    setValue("");
    setOpenPicker(false);
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else if (screenState == "form") {
      handleChange("");
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    if (selectorData?.success) {
      var filter, td, i, txtValue;
      var is = [];
      filter = value.toUpperCase();
      td = tableRef.current.querySelectorAll(".searchable");
      for (i = 0; i < td.length; i++) {
        td[i].parentElement.style.display = "none";
        txtValue = td[i].textContent || td[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          is.push(td[i].parentElement);
        } else {
          td[i].parentElement.style.display = "none";
        }
        is.forEach((t) => (t.style.display = ""));
      }
    }
  };

  const handleAccept = async () => {
    setOpenPicker(false);
    if (element.selectorVF && element.selectorVF != "") {
      const selectorObj = {};
      selectorData.model.map((item, index) => {
        selectorObj[item.name] = selectedRow[index];
      });
      const resultfields = element.selectorVF.split("|").map((i) => i.split(".")[1]);
      if (screenState == "dialog") {
        dispatch(
          setFilter({
            tabID,
            table,
            key: element.name.split(".")[1],
            value: `${selectorObj?.[resultfields[0]]}|${selectorObj?.[resultfields[1]]}`,
          })
        );
      } else if (screenState == "form") {
        if (objectState == "view") {
          dispatch(changeToBuffer({ tabID }));
          dispatch(setObjectState({ value: "edit", tabID }));
        }
        await handleChange(`${selectorObj?.[resultfields[0]]}|${selectorObj?.[resultfields[1]]}`);
      }
    } else {
      dispatch(setLoading({ show: true, tabID }));
      const filters = editorString.split("|")[4];
      const tablename = editorString.split("|")[1];
      const keyname = editorString.split("|")[2];
      const keyvalue = selectedRow[0];
      const resultfields = element.selectorVF.split("|").map((i) => i.split(".")[1]);
      var key = resultfields[0];
      var value = resultfields[resultfields.length - 1];
      const selectorFields = await getSelectorFields({
        filters,
        tablename,
        keyvalue,
        keyname,
        resultfields: resultfields.join(","),
      });
      if (selectorFields.success) {
        if (screenState == "dialog") {
          dispatch(
            setFilter({
              tabID,
              table,
              key,
              value: `${selectorFields.rows[0][key]}|${selectorFields.rows[0][value]}`,
            })
          );
        } else if (screenState == "form") {
          if (objectState == "view") {
            dispatch(changeToBuffer({ tabID }));
            dispatch(setObjectState({ value: "edit", tabID }));
          }
          await handleChange(`${selectorFields.rows[0][key]}|${selectorFields.rows[0][value]}`);
        }
      } else {
        dispatch(setError({ show: true, message: selectorFields.error }));
      }
      dispatch(setLoading({ show: false, tabID }));
    }
  };

  return (
    <div
      className="s1memorytable w-100"
      style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}
      hidden={!element.visible}
    >
      <InputGroup className={`crm-floating-with-icon${element.required && value == "" ? " input-required" : ""}`}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            role="button"
            className={`w-100 ps-1 border-0${
              element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
            }`}
            disabled={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            readOnly={true}
            value={value}
            name={element.name}
            placeholder={element.caption}
            onFocus={(e) => e.target.blur()}
            onClick={(e) =>
              !(
                element.readOnly ||
                (screenState == "form" && commandParams.readonly) ||
                (screenState == "form" && objectState == "view")
              ) && handlePicker(e)
            }
          />
        </FloatingLabel>
        {(screenState == "dialog" || (screenState == "form" && objectState != "view")) &&
          value != "" &&
          !element.readOnly && (
            <div className="d-flex align-items-center justify-content-center" onClick={handleClear}>
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
      </InputGroup>
      {openPicker && (
        <Modal
          show={openPicker}
          fullscreen
          animation={false}
          className="d-flex align-items-end"
          dialogClassName="p-3"
          contentClassName="rounded overflow-hidden shadow"
        >
          {pickerLoading && <PickerSpinner />}
          <div className="picker-search">
            <div className="ms-1 d-flex justify-content-center align-items-center">
              <BsSearch color="var(--bs-primary)" />
            </div>
            <input
              ref={searchRef}
              style={{ height: "40px" }}
              placeholder="Αναζήτηση"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {searchText != "" && (
              <div
                onClick={() => {
                  handleSearch("");
                }}
              >
                <TiDeleteOutline size="30px" role="button" />
              </div>
            )}
          </div>
          <div className="input-picker  w-100 h-100" style={{ overflow: "auto" }}>
            {selectorData?.success ? (
              <div className="input-picker-table w-100" ref={tableRef}>
                {selectorData.data.map((el, index) => {
                  return (
                    <div
                      key={index}
                      className={`input-picker-table-row${
                        selectedRow?.[0] == el?.[0] ? " input-picker-table-row-selected" : ""
                      }`}
                      onClick={() => setSelectedRow(el)}
                    >
                      {el.map((item, index) => {
                        return (
                          <span className="searchable" key={index}>
                            {item}
                          </span>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100">{selectorData.error}</div>
            )}
          </div>
          <Modal.Footer className="picker-buttons">
            <ButtonGroup>
              <Button
                variant="outline-secondary"
                className="rounded-0"
                onClick={() => {
                  setOpenPicker(false);
                }}
              >
                <HiX size={25} color="var(--bs-danger)" className="me-2" />
                <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                  {languages.generalText[currentLang].closing}
                </div>
              </Button>
              <Button className="rounded-0" variant="outline-secondary" onClick={handleAccept}>
                <HiCheck size={25} color="var(--bs-success)" className="me-2" />
                <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                  {languages.generalText[currentLang].apply}
                </div>
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default S1MemoryTable;
