import React from "react";
import FirstDay from "../custom inputs/FirstDay";
import BussinessDays from "../custom inputs/BussinessDays";
import Slots from "../custom inputs/Slots";
import CalendarStart from "../custom inputs/CalendarStart";
import CalendarEnd from "../custom inputs/CalendarEnd";
import CalendarColour from "../custom inputs/CalendarColor";
import { useSelector } from "react-redux";
import { getCurrentTabIndex } from "../../../redux/selectors";

const Calendar = () => {
  const tabID = useSelector((state) => getCurrentTabIndex(state));
  return (
    <div className="d-flex flex-column w-100">
      <FirstDay tabID={tabID} />
      <BussinessDays tabID={tabID} />
      <Slots tabID={tabID} />
      <CalendarStart tabID={tabID} />
      <CalendarEnd tabID={tabID} />
      <CalendarColour tabID={tabID} />
    </div>
  );
};

export default Calendar;
