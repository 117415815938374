import React from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { HiCheck, HiX } from "react-icons/hi";
import { languages } from "../../../languages";
import { useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import {
  addMultipleLinesToGrid,
  changeToBuffer,
  setGetData,
  triggerGridChange,
} from "../../../../redux/features/ui/uiSlice";
import { useDispatch } from "react-redux";
import { getData, getFormDesign, getNewData, getObjectState, getSignature } from "../../../../redux/selectors";
import { setError, setMessage } from "../../../../redux/features/modals/modalsSlice";
import ScreenSpinner from "../../ScreenSpinner";
import serviceSetData from "../../../../services/setData";

const ModalSignature = ({ show, setShow, tabID }) => {
  const dispatch = useDispatch();
  const signature = useSelector((state) => getSignature(state, tabID));
  const dropbox = useSelector((state) => state.settings?.cloudStorage?.dropbox);
  const newData = useSelector((state) => getNewData(state, tabID));
  const data = useSelector((state) => getData(state, tabID));
  const [loading, setLoading] = React.useState(false);
  const formDesign = useSelector((state) => getFormDesign(state, tabID));

  const objectState = useSelector((state) => getObjectState(state, tabID));

  const currentLang = useSelector((state) => state.settings.language);
  const canvasRef = React.useRef(null);

  const handleAccept = async () => {
    setLoading(true);
    const config = {
      clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
      clientSecret: process.env.REACT_APP_DROPBOX_CLIENT_SECRET,
      refreshToken: dropbox?.token,
    };
    var Dropbox = require("dropbox").Dropbox;
    var dbx = new Dropbox(config);
    const base64Response = await fetch(canvasRef.current.getTrimmedCanvas().toDataURL("image/png"));
    const blob = await base64Response.blob();
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    await dbx
      .filesUpload({
        path: `/Apps/Web CRM/${timestamp}/Signature.png`,
        contents: blob,
      })
      .then(async (response) => {
        await dbx
          .sharingCreateSharedLinkWithSettings({
            path: response.result.path_display,
          })
          .then(async (res) => {
            const { url, name } = res.result;
            const lines = [
              {
                SOFNAME: url,
                NAME: name,
              },
            ];

            var gridLines = [];
            if (objectState == "view") {
              gridLines = data?.[signature.model] ?? [];
              dispatch(changeToBuffer({ tabID }));
              dispatch(triggerGridChange({ tabID, name: signature.model }));
            } else {
              gridLines = newData?.[signature.model] ?? [];
            }
            gridLines.length == 0 && dispatch(triggerGridChange({ tabID, name: signature.model }));
            dispatch(addMultipleLinesToGrid({ tabID, value: lines, name: signature.model }));
            if (objectState == "view") {
              const keyValue = data?.[formDesign.key.split(".")[0]]?.[0]?.[formDesign.key.split(".")[1]] ?? "";

              var locateinfo = "";
              Object.entries(formDesign.model).forEach(([key, value], index) => {
                locateinfo += index == 0 ? `${key}:` : `;${key}:`;
                if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
                else
                  value.fields.map((item) => {
                    locateinfo += `${item.name},`;
                  });
              });
              const res = await serviceSetData({
                key: keyValue,
                locateinfo,
                tabID,
              });
              if (res.success) {
                dispatch(setGetData({ value: res, tabID }));
                dispatch(setMessage({ message: languages.generalText[currentLang].successSignature, show: true }));
              } else {
                dispatch(setError({ message: res.error, show: true }));
              }
            }
            setLoading(false);
            setShow(false);
          })
          .catch((er) => {
            dispatch(setError({ show: true, message: er.message }));
            dispatch(setLoading(tabID));
          });
      })
      .catch((er) => {
        dispatch(setError({ show: true, message: er.message }));
        setLoading(false);
      });
  };

  return (
    <Modal
      show={show}
      fullscreen
      className="d-flex align-items-end"
      dialogClassName="p-3"
      contentClassName="rounded overflow-hidden shadow"
      onHide={() => setShow(false)}
    >
      {loading && <ScreenSpinner />}
      <Modal.Header closeButton />
      <Modal.Body>
        <SignatureCanvas ref={canvasRef} canvasProps={{ className: "h-100 w-100 sigCanvas" }} />
      </Modal.Body>
      <Modal.Footer className="picker-buttons">
        <ButtonGroup>
          <Button variant="outline-secondary" className="rounded-0" onClick={() => canvasRef.current.clear()}>
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].clear}
            </div>
          </Button>
          <Button className="rounded-0" variant="outline-secondary" onClick={handleAccept}>
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].apply}
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSignature;
