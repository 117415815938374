import React from "react";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardArrowLeft } from "react-icons/md";

const ArrowsBack = ({ canPreviousPage, gotoPage, previousPage }) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() => canPreviousPage && gotoPage(0)}
        style={{
          userSelect: "none",
          cursor: canPreviousPage ? "pointer" : "default",
          opacity: canPreviousPage ? 1 : 0.5,
        }}
      >
        <MdOutlineKeyboardDoubleArrowLeft size="1.3rem" />
      </div>
      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() => canPreviousPage && previousPage()}
        style={{
          userSelect: "none",
          cursor: canPreviousPage ? "pointer" : "default",
          opacity: canPreviousPage ? 1 : 0.5,
        }}
      >
        <MdOutlineKeyboardArrowLeft size="1.3rem" />
      </div>
    </div>
  );
};

export default ArrowsBack;
