import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getNewData, getObjectState } from "../../../../redux/selectors";
import { ImFilm } from "react-icons/im";
import { useDispatch } from "react-redux";
import { addMultipleLinesToGrid, setLoading, triggerGridChange } from "../../../../redux/features/ui/uiSlice";
import { setError } from "../../../../redux/features/modals/modalsSlice";
import ModalGivenName from "./ModalGivenName";

const Video = ({ tabID, element }) => {
  const dispatch = useDispatch();
  const libraryRef = React.useRef();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const dropbox = useSelector((state) => state.settings?.cloudStorage?.dropbox);
  const newData = useSelector((state) => getNewData(state, tabID));
  const [fileObj, setFileObj] = React.useState({});
  const [fileName, setFileName] = React.useState("");
  const [fileType, setFileType] = React.useState("");
  const [showGiveFileName, setShowGiveFileName] = React.useState(false);

  const giveNameToUploads = useSelector((state) => state?.settings?.app?.giveNameToUploads);

  const handlePhotoUpload = async (event) => {
    const file = event?.target?.files?.[0];
    if (file) {
      if (giveNameToUploads) {
        const temp_name = getFileName(file.name);
        const temp_type = getFileType(file.name);
        setFileName(temp_name);
        setFileType(temp_type);
        setFileObj(file);
        setShowGiveFileName(true);
      } else {
        upload2Dropbox(file, file.name);
      }
    }
  };

  const handleAcceptFromGivenName = (name) => {
    setShowGiveFileName(false);
    upload2Dropbox(fileObj, `${name}${fileType}`);
  };

  const handleClick = () => {
    if (!dropbox) {
      dispatch(setError({ show: true, message: "Δεν έχετε συνδεδεμένο λογαριασμό dropbox." }));
    } else {
      libraryRef.current.click();
    }
  };

  const upload2Dropbox = async (file, name) => {
    dispatch(setLoading({ show: true, tabID }));
    const config = {
      clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
      clientSecret: process.env.REACT_APP_DROPBOX_CLIENT_SECRET,
      refreshToken: dropbox?.token,
    };
    var Dropbox = require("dropbox").Dropbox;
    var dbx = new Dropbox(config);
    const photoUrl = URL.createObjectURL(file);
    const base64Response = await fetch(photoUrl);
    const blob = await base64Response.blob();
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    await dbx
      .filesUpload({
        path: `/Apps/Web CRM/${timestamp}/${name}`,
        contents: blob,
      })
      .then(async (response) => {
        await dbx
          .sharingCreateSharedLinkWithSettings({
            path: response.result.path_display,
          })
          .then((res) => {
            const { url, name } = res.result;
            const lines = [
              {
                SOFNAME: url,
                NAME: name,
              },
            ];

            const gridLines = newData?.[element.model] ?? [];
            gridLines.length == 0 && dispatch(triggerGridChange({ tabID, name: element.model }));
            dispatch(addMultipleLinesToGrid({ tabID, value: lines, name: element.model }));
            dispatch(setLoading({ show: false, tabID }));
          })
          .catch((er) => {
            dispatch(setError({ show: true, message: er.message }));
            dispatch(setLoading({ show: false, tabID }));
          });
      })
      .catch((er) => {
        dispatch(setError({ show: true, message: er.message }));
        dispatch(setLoading({ show: false, tabID }));
      });
  };
  return (
    <>
      <Button disabled={objectState == "view"} variant="secondary" onClick={handleClick}>
        <ImFilm size="1.5rem" />
      </Button>
      <input ref={libraryRef} hidden={true} type="file" onChange={handlePhotoUpload} accept="video/*" />
      {showGiveFileName && (
        <ModalGivenName
          show={showGiveFileName}
          setShow={setShowGiveFileName}
          value={fileName}
          setValue={setFileName}
          acceptFunction={handleAcceptFromGivenName}
        />
      )}
    </>
  );
};

export default Video;

const getFileType = (string) => {
  return "." + string.split(".")[string.split(".").length - 1].toLowerCase();
};

const getFileName = (string) => {
  return string.split(".")[0];
};
