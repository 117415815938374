export const _setTableFields = (state, action) => {
  if (state.tabs[action.payload.tabID]) state.tabs[action.payload.tabID].tableFields = action.payload.value;
};

export const _editListSetGetData = (state, action) => {
  if (state.tabs[action.payload.tabID]?.tableFields?.success) {
    if (!state.tabs[action.payload.tabID].tableColumns) {
      const hiddenColumns = [...(state.tabs[action.payload.tabID].hiddenColumns ?? [])];
      const columns = action.payload.value.fields
        ? action.payload.value.fields.map((field) => {
            if (field.readOnly) hiddenColumns.push(field.name.indexOf(".") < 0 ? field.name : field.name.split(".")[1]);
            return {
              ...field,
              Header: field.caption,
              accessor: field.name.indexOf(".") < 0 ? field.name : field.name.split(".")[1],
              canReveal: field.readOnly,
            };
          })
        : [];
      state.tabs[action.payload.tabID].tableColumns = columns;
      if (!state.tabs[action.payload.tabID].hiddenColumns)
        state.tabs[action.payload.tabID].hiddenColumns = hiddenColumns;
    }

    const data = action.payload.value.data
      ? action.payload.value.data.filter((row) => !row.COMPANY || row.COMPANY == action.payload.value.company)
      : [];
    state.tabs[action.payload.tabID].tableData = data;
    state.tabs[action.payload.tabID].tableBrowserData = action.payload.value.data;
  }
};
