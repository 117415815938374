import { createSlice } from "@reduxjs/toolkit";
import { clear } from "../session/sessionSlice";

const initialState = JSON.parse(localStorage.getItem("login"));

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    set: (state, action) => {
      localStorage.setItem("login", JSON.stringify(action.payload));
      return { ...state, ...action.payload };
    },
    clear: () => {
      localStorage.setItem("login", null);
      return null;
    },
    setNewPassword: (state, action) => {
      localStorage.setItem("login", JSON.stringify(action.payload));
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clear, () => {
      localStorage.setItem("login", null);
      return null;
    });
  },
});

export const { set, setNewPassword } = loginSlice.actions;

export default loginSlice.reducer;
