import React from "react";
import { AiOutlineUndo } from "react-icons/ai";

const Cancel = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-start"
      // onClick={() => objectState != "view" && handleCancel()}
      // style={{
      //   userSelect: "none",
      //   cursor: objectState != "view" ? "pointer" : "default",
      //   opacity: objectState != "view" ? 1 : 0.5,
      // }}
    >
      <AiOutlineUndo size="1.2rem" className="me-1" />
      Ακύρωση
    </div>
  );
};

export default Cancel;
