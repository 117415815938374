import React from "react";
import store from "../../../../../redux/store";
import { getBrowserInfo } from "../../../../../redux/selectors";
import { setNextBrowserData } from "../../../../../redux/features/ui/uiSlice";
import getBrowserData from "../../../../../services/getBrowserData";

const useFetch = ({ start, tabID, refID }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const state = store.getState();
  const reqID = getBrowserInfo(state, tabID)?.reqID;
  const handleSetNextBrowserData = (value) => store.dispatch(setNextBrowserData({ tabID, rows: value }));
  const browserinfo = getBrowserInfo(state, tabID);

  const [hasNextPage, setHasNextPage] = React.useState(
    browserinfo?.rows && browserinfo.rows.length < browserinfo?.totalcount
  );

  React.useEffect(() => {
    if (start == 0) {
      browserinfo?.rows && setHasNextPage(browserinfo.rows.length < browserinfo?.totalcount);
      return;
    }

    if (browserinfo?.rows && browserinfo?.rows.length < browserinfo?.totalcount) {
      setHasNextPage(true);
      setIsLoading(true);
      getBrowserData({
        reqID,
        start: start * parseInt(process.env.REACT_APP_GETBROWSERDATA_LIMIT),
      })
        .then((data) => {
          if (data.success) {
            handleSetNextBrowserData(data.rows);
            setHasNextPage(browserinfo.rows.length + data.rows.length < browserinfo.totalcount);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      setHasNextPage(false);
    }

    // return () => controller.abort();
  }, [start, refID]);

  return { isLoading, hasNextPage };
};

export default useFetch;
