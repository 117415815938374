import React from "react";
import AddSingleGridLine from "./buttons/AddSingleGridLine";
const S1DetailGridBar = ({ tabID, element, setShow }) => {
  return (
    <div className="s1detailgrid-bar">
      <AddSingleGridLine tabID={tabID} element={element} setShow={setShow} />
    </div>
  );
};

export default S1DetailGridBar;
