import React from "react";
import Dropbox from "./storage/Dropbox";
import Google from "./storage/Google";

const CloudStorage = () => {
  return (
    <div className="d-flex flex-column w-100 flex-1">
      <Dropbox />
      {/* <Google /> */}
    </div>
  );
};

export default CloudStorage;
