import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../redux/features/modals/modalsSlice";
import { BsInfo } from "react-icons/bs";

const ModalMessage = () => {
  const dispatch = useDispatch();
  const buttonRef = React.useRef(null);
  const message = useSelector((state) => state.modals.message);

  React.useEffect(() => {
    if (message.show) {
      buttonRef.current.focus();
    }
  }, [message?.show]);
  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={message.show}
      animation={false}
      onHide={() => dispatch(setMessage({ show: false }))}
      centered
    >
      <span
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%,-50%)",
          backgroundColor: "var(--bs-primary)",
          borderRadius: "50%",
          color: "var(--bs-light)",
        }}
        className="d-flex justify-content-center align-items-center shadow"
      >
        <BsInfo size="3rem" className="m-1 p-1" />
      </span>
      <Modal.Body className="d-flex justify-content-start align-items-center mt-4" style={{ userSelect: "none" }}>
        <div dangerouslySetInnerHTML={{ __html: (message.message ?? "").replaceAll("\r\n", "<br/>") }} />
      </Modal.Body>
      <Modal.Footer className="p-0">
        <Button variant="primary w-100" ref={buttonRef} onClick={() => dispatch(setMessage({ show: false }))}>
          ΟΚ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalMessage;
