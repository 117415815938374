import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
const CrmInput = (props) => {
  const [toogle, setTogle] = React.useState(false);
  return (
    <InputGroup
      className="crm-floating-with-icon my-1"
      style={{
        borderBottom: props.condition ? "1px solid var(--bs-danger)" : "1px solid var(--bs-gray)",
      }}
    >
      <FloatingLabel controlId={props.id} label={props.label} style={{ whiteSpace: "nowrap" }} className="w-100">
        <Form.Control
          className="crm-input ps-1 mt-1"
          autoCapitalize="off"
          autoComplete={props.autoComplete}
          placeholder={props.label}
          type={toogle ? "text" : props.type}
          name={props.name}
          inputMode={props.inputMode}
          required
          readOnly={props.readOnly}
          disabled={props.readOnly}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          style={{
            border: "0",
          }}
        />
      </FloatingLabel>
      {props.type == "password" && props.value != "" && (
        <div className="d-flex align-items-center justify-content-center" onClick={() => setTogle((x) => !x)}>
          {toogle ? <AiOutlineEyeInvisible size="1.75rem" /> : <AiOutlineEye size="1.75rem" />}
        </div>
      )}
    </InputGroup>
  );
};

export default CrmInput;
