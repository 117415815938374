export const autoCalcFields = ["SALDOC.DISC1PRC", "SALDOC.SUMAMNT"];

export const excludeFromCalcFields = {
  SALDOC: {
    SERIES: true,
    TRDR: true,
  },
  PURDOC: {
    SERIES: true,
    TRDR: true,
  },
};
