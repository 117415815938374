import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toogleMenu } from "../../redux/features/modals/modalsSlice";
import {
  BsBuildingGear,
  BsFileEarmarkMedical,
  BsFillFileEarmarkSpreadsheetFill,
  BsFolder2,
  BsGlobe,
  BsTable,
} from "react-icons/bs";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { setNewTab } from "../../redux/features/ui/uiSlice";
import { Offcanvas } from "react-bootstrap";
import ActionsOnSideBar from "./side bar/ActionsOnSideBar";

const SideBar = ({
  currentMenus,
  setCurrentMenus,
  previousStack,
  setPreviousStack,
  currrentTitle,
  setCurrentTitle,
  titleStack,
  setTitleStack,
}) => {
  const menuOpen = useSelector((state) => state.modals.menu);
  const screens = useSelector((state) => state.screens.screens);
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.settings);

  const openMenuFolder = (data, item) => {
    previousStack.push(data);
    titleStack.push(currrentTitle);
    setPreviousStack(previousStack);
    setTitleStack(titleStack);
    setCurrentMenus(item.children);
    setCurrentTitle(item.text);
  };

  const MenuItem = ({ item, index }) => (
    <div
      id={`id-mn${index}`}
      onClick={() => {
        dispatch(toogleMenu());
        dispatch(setNewTab({ ...screens[item.idx], id: Date.now() }));
      }}
      className="s1-web-menu-item"
    >
      <div className="d-flex">
        {
          {
            EditMaster: <BsFileEarmarkMedical color="var(--bs-orange)" size="1.5rem" />,
            Report: <BsFillFileEarmarkSpreadsheetFill color="var(--bs-teal)" size="1.5rem" />,
            Dialog: <BsBuildingGear color="var(--bs-danger)" size="1.5rem" />,
            WebPage: <BsGlobe color="var(--bs-primary)" size="1.5rem" />,
            Ergani: <MdOutlineQrCodeScanner color="var(--bs-gray-600)" size="1.5rem" />,
            EditList: <BsTable color="var(--bs-yellow)" size="1.5rem" />,
          }[item.cmd]
        }
      </div>
      <span className="text-truncate me-2">{item?.text}</span>
    </div>
  );

  const FolderItem = ({ index, data, item }) => (
    <div id={`id-mn${index}`} onClick={() => openMenuFolder(data, item)} className="s1-web-menu-item">
      <div className="d-flex">
        <BsFolder2 color="var(--bs-gray-500)" size="1.5rem" />
      </div>
      <span className="text-truncate me-2">{item?.text}</span>
    </div>
  );

  const renderMenuItems = (data) => {
    return data.map((item, index) =>
      item?.children && item?.children.length ? (
        !menuOpen ? (
          <FolderItem data={data} key={index} index={index} item={item} />
        ) : (
          <FolderItem data={data} key={index} index={index} item={item} />
        )
      ) : !menuOpen ? (
        <MenuItem key={index} index={index} item={item} />
      ) : (
        <MenuItem key={index} index={index} item={item} />
      )
    );
  };

  return (
    <Offcanvas
      backdropClassName="sideBar-backdrop"
      className="sideBar"
      show={menuOpen}
      onHide={() => dispatch(toogleMenu())}
    >
      <div style={{ width: "100%", display: "flex", flexDirection: "column", overflowY: "hidden" }}>
        <ActionsOnSideBar />
        <div className="s1-web-menu">
          {previousStack.length ? (
            <div className="s1-web-menu-item current-folder">
              <div
                className="d-flex h-100 justify-content-center align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const prevState = previousStack.pop();
                  setPreviousStack(previousStack);
                  setCurrentMenus(prevState);
                  const prevTitle = titleStack.pop();
                  setTitleStack(titleStack);
                  setCurrentTitle(prevTitle);
                }}
              >
                <IoIosArrowBack size="1rem" />
              </div>

              <div className="vr my-2 me-2"></div>
              <span className="mx-2 text-truncate">{currrentTitle}</span>
            </div>
          ) : null}
          {currentMenus ? (
            renderMenuItems(currentMenus)
          ) : (
            <div className="d-flex justify-content-center align-items-center">No data</div>
          )}
        </div>
      </div>
    </Offcanvas>
  );
};

export default SideBar;
