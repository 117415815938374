import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommandParams, getCurrentTab, getScreenState } from "../../../redux/selectors";
import TopBarOnDialog from "../../bars/top bars/report/TopBarOnDialog";
import Dialog from "../edit master/Dialog";
import Browser from "./Browser";
import TopBarOnBrowser from "../../bars/top bars/report/TopBarOnBrowser";
import {
  initialize,
  setLoading,
  setReportData,
  setReportInfo,
  setScreenState,
} from "../../../redux/features/ui/uiSlice";
import getReportInfo from "../../../services/getReportInfo";
import getReportData from "../../../services/getReportData";
const Report = ({ tabID }) => {
  const dispatch = useDispatch();
  const screenSTate = useSelector((state) => getScreenState(state, tabID));
  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const ae1 = async () => {
    dispatch(setLoading({ show: true, tabID }));
    const reportInfo = await getReportInfo(tabID);
    dispatch(setReportInfo({ value: reportInfo, tabID }));
    if (reportInfo.success && reportInfo.npages > 0) {
      const reportData = await getReportData({ reqID: reportInfo.reqID, pagenum: 1 });
      dispatch(setReportData({ value: reportData, tabID }));
    }
    dispatch(setScreenState({ value: "browser", tabID }));
    dispatch(setLoading({ show: false, tabID }));
  };

  const checkParams = async () => {
    if (commandParams?.ae) {
      if (commandParams.ae == 1) await ae1();
      return;
    }
    if (commandParams?.ff) {
      await ae1();
      return;
    }
    dispatch(setScreenState({ value: "dialog", tabID }));
  };

  const openReport = async () => {
    dispatch(initialize(tabID));
    await checkParams();
  };

  React.useEffect(() => {
    openReport();
  }, []);

  return (
    <>
      {
        {
          dialog: <TopBarOnDialog tabID={tabID} />,
          browser: <TopBarOnBrowser tabID={tabID} />,
        }[screenSTate]
      }
      <Dialog tabID={tabID} style={{ display: screenSTate == "dialog" ? "block" : "none" }} />
      <div
        style={{ display: screenSTate == "browser" ? "flex" : "none", flexDirection: "column" }}
        className="s1-object-browser"
      >
        <Browser tabID={tabID} />
      </div>

      {/* <Form tabID={tabID} style={{ display: screenSTate == "form" ? "block" : "none" }} /> */}
      <link
        rel="stylesheet"
        type="text/css"
        href="https://s1sites01.blob.core.windows.net/lib/weblib/prints/printsnew.css"
      />
    </>
  );
};

export default Report;
