import React from "react";
import { Button, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { batchLoading } from "../../redux/features/ui/uiSlice";
import { useSelector } from "react-redux";
import { getCurrentTab } from "../../redux/selectors";

const BatchMessage = ({ tabID }) => {
  const dispatch = useDispatch();
  const tab = useSelector((state) => getCurrentTab(state, tabID));
  return (
    <div
      className="w-100 h-100 d-flex justify-content-center align-items-center"
      style={{ zIndex: 2001, position: "absolute" }}
    >
      <Card style={{ minWidth: "400px" }} className="shadow">
        <Card.Header className="py-2" style={{ userSelect: "none" }}>
          Εργασία Συστήματος
        </Card.Header>
        <Card.Body>
          <div>
            <span className="me-2">Έναρξη:</span>
            <b>{tab.batchStart ? formatDate(tab.batchStart) : ""}</b>
          </div>
          <div>
            <span className="me-2">Λήξη:</span>
            <b>{tab.batchEnd ? formatDate(tab.batchEnd) : ""}</b>
          </div>
          <div>
            <span className="me-2">Αποτελέσματα:</span>
            <b>{tab.batchMessage != '""' ? tab.batchMessage : "Ολοκλήρωση"}</b>
          </div>
        </Card.Body>
        <Card.Footer className="p-1 d-flex justify-content-end gap-1">
          <Button onClick={() => dispatch(batchLoading({ tabID, value: false }))}>Κλείσιμο</Button>
        </Card.Footer>
      </Card>
    </div>
  );
};

export default BatchMessage;

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return (
    [date.getDate().padLeft(), (date.getMonth() + 1).padLeft(), date.getFullYear()].join("/") +
    " " +
    [date.getHours().padLeft(), date.getMinutes().padLeft(), date.getSeconds().padLeft()].join(":")
  );
};
