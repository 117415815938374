import React from "react";
import { Button } from "react-bootstrap";
import { FiSave } from "react-icons/fi";
import { useSelector } from "react-redux";
import { setSettings } from "../../../../redux/features/settings/settingsSlice";
import { setLoading } from "../../../../redux/features/modals/modalsSlice";
import saveSettings from "../../../../services/saveSettings";
import { useDispatch } from "react-redux";

const Save = () => {
  const dispatch = useDispatch();
  const draft = useSelector((state) => state.settings.draft);
  const hasSettingsInDB = useSelector((state) => state.settings.hasSettingsInDB);
  const wa = useSelector((state) => state.systemParams?.wa);

  const handleSave = async () => {
    dispatch(setSettings(draft));
    dispatch(setLoading(true));
    await saveSettings({
      key: hasSettingsInDB ? wa : "",
      data: { CCCD1WEBCRMSETTINGS: [hasSettingsInDB ? draft : { ...draft, CCCD1WEBCRMSETTINGS: wa }] },
    });
    dispatch(setLoading(false));
  };

  return (
    <Button disabled={Object.keys(draft).length == 0} variant="secondary" onClick={handleSave}>
      <FiSave />
    </Button>
  );
};

export default Save;
