export const getItemQty = (state, idx, mtrl) => state.showRooms?.[idx]?.cart?.[mtrl]?.QTY ?? 0;
export const getItemData = (state, idx, mtrl) => state.showRooms?.[idx]?.cart?.[mtrl]?.data;
export const getItemsCount = (state, idx) => {
  let count = 0;
  for (const key in state.showRooms?.[idx]?.cart) {
    if (state.showRooms[idx].cart[key].QTY > 0) {
      count++;
    }
  }
  return count;
};

export const getCard = (state, idx) => state.showRooms?.[idx]?.cart;
