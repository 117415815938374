import React from "react";
import { useSelector } from "react-redux";
import { getGadgetBrowserInfo, getGadgetCategory, getGadgetCollapsed } from "../../../redux/selectors";
import Category5 from "./category5/Category5";
import Category1 from "./category 1/Category1";
import Category2 from "./category 2/Category2";

const Gadget = React.memo(({ id, provided }) => {
  const collapsed = useSelector((state) => getGadgetCollapsed(state, id));
  const category = useSelector((state) => getGadgetCategory(state, id));
  const [size, setSize] = React.useState({ width: "100%", height: 350 });
  const fullScreen = useSelector((state) => state.portal.fullWindow);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{
        ...provided.draggableProps.style,
        height: fullScreen && fullScreen == id ? "100%" : collapsed ? 54 : size.height,
        display: fullScreen ? (fullScreen == id ? "block" : "none") : "block",
        marginBottom: "1rem",
        borderRadius: "8px",
        overflowY: "hidden",
      }}
      className="shadow "
    >
      {/* <div style={{ ...style, height: fullScreen && fullScreen == id ? "100%" : collapsed ? 54 : size.height }}> */}
      {
        {
          1: <Category1 id={id} dndProps={provided.dragHandleProps} />,
          2: <Category2 id={id} dndProps={provided.dragHandleProps} />,
          5: <Category5 id={id} dndProps={provided.dragHandleProps} />,
        }[category]
      }
      {/* </div> */}
    </div>
  );
});

export default Gadget;
