import { createSlice } from "@reduxjs/toolkit";
import { killTab, setTab } from "../ui/uiSlice";

const userLanguage = navigator.language || navigator.userLanguage;
const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

const local = JSON.parse(localStorage.getItem("settings")) ?? {};
var language = "ENG";
if (local.hasOwnProperty("language")) {
  language = local.language;
} else {
  switch (userLanguage.toUpperCase()) {
    case "GR":
      language = "GRE";
      break;
    default:
      language = "ENG";
      break;
  }
}

const initialState = {
  app: local?.app ?? {
    singleClickOnRecord: 0,
    confirmExit: 0,
    browserLimit: 30,
    gridVisibleLines: 5,
    browserSearchActive: 0,
    askForDeleteInGrid: 0,
    calendarFirstDay: 1,
    calendarBussinesDays: "1,2,3,4,5",
    calendarSlots: "00:30:00",
    calendarStart: "09:00",
    calendarEnd: "17:00",
    calendarColor: "#0d6efd",
    calendarColorZoneField: "",
    calendarColorZones: [],
    giveNameToUploads: 0,
    enablePortal: 0,
  },
  darkMode: local.hasOwnProperty("darkMode") ? local.darkMode : 0, //darkModeMediaQuery.matches,
  cloudStorage: local?.cloudStorage ?? {},
  printerOptions: local?.printerOptions ?? {},
  draft: {},
  language,
  hasSettingsInDB: false,
  fromAuth: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    set: (state, action) => {
      state.draft[action.payload.key] = action.payload.value;
    },
    deleteFromDraft: (state, action) => {
      delete state.draft[action.payload];
    },
    setSettings: (state, action) => {
      state.draft = {};
      state.app = { ...state.app, ...action.payload };
      localStorage.setItem("settings", JSON.stringify(state));
    },
    hasSettingsInDB: (state) => {
      state.hasSettingsInDB = true;
    },
    setPrinterOptions: (state, action) => {
      state.printerOptions = { ...state.printerOptions, [action.payload.key]: action.payload.value };
      localStorage.setItem("settings", JSON.stringify(state));
    },
    setDropBox: (state, action) => {
      state.cloudStorage.dropbox = action.payload;
      localStorage.setItem("settings", JSON.stringify(state));
    },
    setGoogle: (state, action) => {
      state.cloudStorage.google = action.payload;
      localStorage.setItem("settings", JSON.stringify(state));
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem("settings", JSON.stringify(state));
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
      localStorage.setItem("settings", JSON.stringify(state));
    },
    setFromAuth: (state) => {
      state.fromAuth = true;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(killTab, (state, action) => {
        if (action.payload == -2) {
          state.draft = {};
        }
      })
      .addCase(setTab, (state, action) => {
        if (action.payload == -2) {
          state.draft = {};
          state.fromAuth = false;
        }
      }),
});

export const {
  set,
  deleteFromDraft,
  setSettings,
  hasSettingsInDB,
  setPrinterOptions,
  setDropBox,
  setGoogle,
  setLanguage,
  setDarkMode,
  setFromAuth,
} = settingsSlice.actions;

export default settingsSlice.reducer;
