import React from "react";
import { Alert, Button, ButtonGroup, Modal } from "react-bootstrap";
import { setLoading, setPasswordModal } from "../../../redux/features/modals/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import CrmInput from "../CrmInput";
import changePassword from "../../../services/changePassword";
import { setNewPassword } from "../../../redux/features/login/loginSlice";
import { HiCheck, HiX } from "react-icons/hi";
import { languages } from "../../languages";

const ModalChangePassWord = () => {
  const dispatch = useDispatch();
  const show = useSelector((state) => state.modals.password);
  const login = useSelector((state) => state.login);
  const [currentPass, setCurrentPass] = React.useState("");
  const [newPass, setNewPass] = React.useState("");
  const [confirmPass, setConfirmPass] = React.useState("");

  const [currPassError, setCurrPassError] = React.useState(false);
  const [newPassError, seNewPassError] = React.useState(false);
  const [confirmPassError, setConfirmPassError] = React.useState(false);

  const currentLang = useSelector((state) => state.settings.language);

  const [generalError, setGeneralError] = React.useState({ show: false, message: "" });
  const inputs = [
    {
      label: languages.user[currentLang].oldPassword,
      required: true,
      name: "old-password",
      type: "password",
      id: "old-pass",
      readOnly: false,
      value: currentPass,
      onChange: setCurrentPass,
      condition: currPassError,
      autoComplete: "current-password",
    },
    {
      label: languages.user[currentLang].newPassword,
      required: true,
      name: "new-password",
      type: "password",
      id: "new-pass",
      readOnly: false,
      value: newPass,
      onChange: setNewPass,
      condition: newPassError,
      autoComplete: "new-password",
    },
    {
      label: languages.user[currentLang].confirmPassword,
      required: true,
      name: "new-password",
      type: "password",
      id: "confirm-pass",
      readOnly: false,
      value: confirmPass,
      onChange: setConfirmPass,
      condition: confirmPassError,
      autoComplete: "new-password",
    },
  ];

  React.useEffect(() => {
    if (!show) {
      setCurrentPass("");
      setNewPass("");
      setConfirmPass("");
      setCurrPassError(false);
      seNewPassError(false);
      setConfirmPassError(false);
    }
  }, [show]);

  const handleAcept = async (e) => {
    setGeneralError({ show: false, message: "" });
    setCurrPassError(false);
    seNewPassError(false);
    setConfirmPassError(false);
    e.preventDefault();
    if (currentPass != login.password) {
      setCurrPassError(true);
      return;
    }
    if (newPass != confirmPass) {
      seNewPassError(true);
      setConfirmPassError(true);
      return;
    }
    dispatch(setLoading(true));
    const response = await changePassword({
      oldPass: currentPass,
      newPass: newPass,
    });
    if (response.success) {
      dispatch(setNewPassword({ ...login, password: newPass }));
      dispatch(setPasswordModal(false));
    } else {
      setGeneralError({ show: true, message: response.error });
    }
    dispatch(setLoading(false));
  };
  return (
    <Modal show={show} onHide={() => dispatch(setPasswordModal(false))} centered>
      <form onSubmit={handleAcept}>
        <Modal.Body className="d-flex flex-column" style={{ userSelect: "none" }}>
          {inputs.map((item, index) => (
            <CrmInput {...item} key={index} />
          ))}
          {generalError.show && (
            <Alert variant="danger" className="m-0 mt-2">
              {generalError.message}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer className="picker-buttons">
          <ButtonGroup>
            <Button variant="outline-secondary" className="rounded-0" onClick={() => dispatch(setPasswordModal(false))}>
              <HiX size={25} color="var(--bs-danger)" className="me-2" />
              <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                {languages.generalText[currentLang].cancel}
              </div>
            </Button>
            <Button className="rounded-0" variant="outline-secondary" type="submit">
              <HiCheck size={25} color="var(--bs-success)" className="me-2" />
              <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                {languages.generalText[currentLang].confirm}
              </div>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ModalChangePassWord;
