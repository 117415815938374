import React from "react";
import { useSelector } from "react-redux";
import { languages } from "../../../../languages";
import { Dropdown } from "react-bootstrap";
import { BiSortUp } from "react-icons/bi";
import ModalSort from "../../../../ui/edit master/browser/sort/ModalSort";
import { getBrowserInfo } from "../../../../../redux/selectors";

const Sort = ({ tabID }) => {
  const [show, setShow] = React.useState(false);
  const currentLang = useSelector((state) => state.settings.language);
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));

  return (
    <>
      <Dropdown.Item
        disabled={!(browserInfo?.totalcount > 0)}
        className="d-flex align-items-center justify-content-start"
        onClick={() => setShow(true)}
      >
        <BiSortUp size="1.2rem" className="me-1" />
        <span>{languages.generalText[currentLang].sort}</span>
      </Dropdown.Item>
      <ModalSort tabID={tabID} show={show} setShow={setShow} />
    </>
  );
};

export default Sort;
