import React from "react";
import { Container } from "react-bootstrap";
import ArrowsBack from "../../edit master/browser/table/footer/ArrowsBack";
import Pages from "../../edit master/browser/table/footer/Pages";
import ArrowsNext from "../../edit master/browser/table/footer/ArrowsNext";

const Foot = (props) => {
  return (
    <Container
      fluid
      style={{ color: "white", backgroundColor: "var(--bs-gray-dark)", gap: "1rem", height: 40, flexShrink: 0 }}
      className=" d-flex align-items-center py-2"
    >
      <ArrowsBack
        id={props.id}
        canPreviousPage={props.canPreviousPage}
        gotoPage={props.gotoPage}
        previousPage={props.previousPage}
      />
      <div className="vr" />
      <Pages id={props.id} pageIndex={props.pageIndex} pageOptions={props.pageOptions} gotoPage={props.gotoPage} />
      <div className="vr" />
      <ArrowsNext
        id={props.id}
        canNextPage={props.canNextPage}
        gotoPage={props.gotoPage}
        pageCount={props.pageCount}
        nextPage={props.nextPage}
      />
    </Container>
  );
};

export default Foot;
