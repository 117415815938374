import React from "react";
import { Button, ButtonGroup, FloatingLabel, Form, InputGroup, Modal } from "react-bootstrap";
import { HiCheck, HiX } from "react-icons/hi";
import { TiDeleteOutline } from "react-icons/ti";

const ModalGivenName = ({ show, setShow, value, acceptFunction }) => {
  const [currentName, setCurrentName] = React.useState("");
  const inputRef = React.useRef();

  React.useEffect(() => {
    setCurrentName(value);
  }, [show]);

  const validateFileName = (e) => {
    const validFileNamePattern = /^$|^[a-zA-Z0-9_ \u0391-\u03A9\u03B1-\u03C9]+$/;
    if (validFileNamePattern.test(e.target.value)) {
      setCurrentName(e.target.value);
    }
  };

  const handleAccept = () => {
    acceptFunction(currentName);
  };
  return (
    <Modal show={show} animation={false} onHide={() => setShow(false)} backdrop="static" centered>
      <Modal.Header closeButton className="p-2 ps-3">
        Δώστε όνομα στο αρχείο
      </Modal.Header>
      <Modal.Body className="pt-0">
        <InputGroup
          className="crm-floating-with-icon my-1"
          style={{
            borderBottom: currentName == "" ? "1px solid var(--bs-danger)" : "1px solid var(--bs-gray)",
          }}
        >
          <FloatingLabel controlId="fileName" label="Όνομα Αρχείου" style={{ whiteSpace: "nowrap" }} className="w-100">
            <Form.Control
              ref={inputRef}
              className="crm-input ps-1 mt-1"
              autoCapitalize="off"
              placeholder="Όνομα Αρχείου"
              name="fileName"
              required
              value={currentName}
              onChange={validateFileName}
              style={{
                border: "0",
              }}
            />
          </FloatingLabel>
          {currentName != "" && (
            <div
              className="d-flex align-items-center justify-content-center me-1"
              onClick={() => {
                setCurrentName("");
                inputRef.current.focus();
              }}
            >
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
        </InputGroup>
      </Modal.Body>
      <Modal.Footer className="picker-buttons">
        <ButtonGroup>
          <Button
            variant="outline-secondary"
            className="rounded-0"
            onClick={() => {
              setShow(false);
            }}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              Άκυρο
            </div>
          </Button>
          <Button className="rounded-0" variant="outline-secondary" disabled={currentName == ""} onClick={handleAccept}>
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              Ανέβασμα
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalGivenName;
