import axios from "axios";

const getSystemParams = async ({ url, clientID }) =>
  await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "getSystemParams",
        clientID,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));

export default getSystemParams;
