import React from "react";
import { BiCookie } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, toogleMenu } from "../../../redux/features/modals/modalsSlice";
import axios from "axios";
import { getURL } from "../../../redux/selectors";

const Cookie = () => {
  const dispatch = useDispatch();
  const url = useSelector((state) => getURL(state));
  const handleClear = async () => {
    dispatch(toogleMenu());
    dispatch(setLoading(true));
    await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url: `${url}?refresh`,
      data: {
        service: "refresh",
      },
    });
    window.location.reload(true);
  };
  return (
    <div onClick={handleClear}>
      <BiCookie size="1.5rem" />
    </div>
  );
};

export default Cookie;
