import React from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import CrmDropDownPrinter from "../../../../ui/custom inputs/CrmDropDownPrinter";
import CrmDropDownTemplates from "../../../../ui/custom inputs/CrmDropDownTemplates";
import { getCommand } from "../../../../../redux/selectors";
import { setPrinterOptions } from "../../../../../redux/features/settings/settingsSlice";
import { HiCheck, HiX } from "react-icons/hi";
import { languages } from "../../../../languages";

const ModalPrintRecord = ({ show, setShow, accept, tabID }) => {
  const dispatch = useDispatch();
  const outputFormats = useSelector((state) => state.systemParams?.outputformats?.form);
  const [printer, setPrinter] = React.useState("");
  const [template, setTemplate] = React.useState(["", ""]);
  const command = useSelector((state) => getCommand(state));
  const object = command && command.substring(0, command.indexOf("&"));
  const currentLang = useSelector((state) => state.settings.language);

  const settings = useSelector((state) => state.settings);

  React.useEffect(() => {
    if (settings?.printerOptions?.[object]) {
      setPrinter(settings?.printerOptions?.[object]?.printer);
      setTemplate(settings?.printerOptions?.[object]?.template);
    } else {
      setPrinter("");
      setTemplate(["", ""]);
    }
  }, [show]);

  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={show}
      backdrop="static"
      animation={false}
      onHide={() => setShow(false)}
      centered
      size="lg"
    >
      {/* <Modal.Header closeButton className="p-2 ps-3">
        Εκτύπωση Φόρμας
      </Modal.Header> */}
      <Modal.Body className="d-flex justify-content-center align-items-center flex-column">
        <CrmDropDownPrinter
          label={languages.generalText[currentLang].printer}
          type="text"
          name="printer"
          inputMode="none"
          required
          value={printer}
          data={{ ...outputFormats }}
          function={(value) => setPrinter(value)}
        />
        <CrmDropDownTemplates
          label={languages.generalText[currentLang].form}
          type="text"
          name="template"
          inputMode="none"
          required
          value={template[1]}
          tabID={tabID}
          function={(value) => setTemplate(value)}
        />
        <div className="d-flex justify-content-center align-items-center mt-3">
          <Button
            variant="secondary"
            onClick={() => dispatch(setPrinterOptions({ key: object, value: { printer, template } }))}
          >
            {languages.generalText[currentLang].saveTemplate}
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer className="picker-buttons">
        <ButtonGroup>
          <Button variant="outline-secondary" className="rounded-0" onClick={() => setShow(false)}>
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].cancel}
            </div>
          </Button>
          <Button
            className="rounded-0"
            variant="outline-secondary"
            disabled={printer == "" || template.every((element) => element == "")}
            onClick={() => {
              accept(printer, template[0]);
              setShow(false);
            }}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].print}
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPrintRecord;
