let WebMenu = {};
let Roots = [];
let showRooms = {};

export const deserializeWebMenu = (elements, fromInside) => {
  if (!fromInside) {
    WebMenu = {};
    Roots = [];
    showRooms = {};
  }
  const pattern = /^\{([^{}]+)\}(.*)/;
  elements.forEach((itemReadOnly) => {
    const item = { ...itemReadOnly };
    if (!item?.children) {
      const match = item.command.match(pattern);
      let customParams = {};
      if (match) {
        const keyValuePairs = match[1].split(",");
        keyValuePairs.forEach((pair) => {
          const [key, value] = pair.split(":");
          if (key.toLowerCase() == "editoptions") {
            const editArray = value.toLowerCase().split(";");
            editArray.forEach((x) => (customParams[x.replaceAll("'", "").replaceAll('"', "")] = true));
          } else {
            customParams[key.toLowerCase()] = value.toLowerCase();
          }
        });
      }

      item.command = match ? match[2] : item.command;
      const params = new URLSearchParams(item.command);
      const properties = {};
      for (const [key, value] of params.entries()) {
        properties[key] = value;
        if (key == "root" && value == 1)
          Roots.push({
            key: item.command.split("&")[0],
            value: {
              command: `${item.command.split("&")[0]}${params.get("list") ? `&list=${params.get("list")}` : ""}${
                params.get("form") ? `&form=${params.get("form")}` : ""
              }${params.get("rj") ? `&rj=${params.get("rj")}` : ""}&AppId=${process.env.REACT_APP_SOFTONE_APPID}`,
              list: params.get("list"),
              form: params.get("form"),
              AppId: process.env.REACT_APP_SOFTONE_APPID,
              rj: params.get("rj"),
              text: item.text,
            },
          });
      }
      properties.ae = properties.ae == 2 ? 2 : 1;
      item.commandParams = { ...properties, ...customParams };
      item.screenState = { present: undefined, past: undefined };
      item.objectState = "view";
      item.browseExpandable = item.cmd === "EditMaster" ? true : undefined;
      item.isShowRoom = item.command.toLowerCase().startsWith("_showroom");
      WebMenu[item.idx] = item;
      if (item.command.toLowerCase().startsWith("_showroom")) showRooms[item.idx] = {};
    } else {
      deserializeWebMenu(item.children, true);
    }
  });

  return { menu: { ...WebMenu }, roots: [...Roots], showRooms: { ...showRooms } };
};
