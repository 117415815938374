import React from "react";
import { useSelector } from "react-redux";
import { getBrowserInfo, getBrowserInfoFields, getTableColumns, getTableData } from "../../../redux/selectors";
import useFetch from "./../edit master/browser/table/useFetch";
import ItemCard from "./ItemCard";

const ShowRoomList = ({ tabID }) => {
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));
  const browserInfoFields = useSelector((state) => getBrowserInfoFields(state, tabID));
  const tableData = useSelector((state) => getTableData(state, tabID));
  const tableColumns = useSelector((state) => getTableColumns(state, tabID));
  const [start, setStart] = React.useState(0);
  const [refID, setRefID] = React.useState(0);
  const { isLoading, hasNextPage } = useFetch({ start, tabID, refID });
  const settings = useSelector((state) => state.settings);
  const [hasImage, setHasImage] = React.useState(false);
  const [imageAccessor, setImageAccessor] = React.useState("");
  const [columns, setColumns] = React.useState([]);

  React.useEffect(() => {
    if (tableColumns) {
      const arr = tableColumns.filter((x) => x.dataIndex != "ZOOMINFO" && x.dataIndex != "FLD-1");
      setColumns(arr);
      if (browserInfoFields) {
        const imageIndex = browserInfoFields.findIndex((field) => field.type == "image");
        setHasImage(imageIndex > -1);
        if (imageIndex > -1) {
          const index = arr.findIndex((x) => x.dataIndex == browserInfoFields[imageIndex].name);
          setImageAccessor(arr[index].accessor);
        }
      }
    }
  }, [tableColumns]);

  const intObserver = React.useRef();
  const lastRowRef = React.useCallback(
    (row) => {
      if (isLoading) return;
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((rows) => {
        if (rows[0].isIntersecting && hasNextPage) {
          browserInfo?.totalcount >
            parseInt(settings?.app?.browserLimit ?? process.env.REACT_APP_GETBROWSERDATA_LIMIT) &&
            setStart((prev) => prev + 1);
        }
      });

      if (row) intObserver.current.observe(row);
    },
    [isLoading, hasNextPage]
  );

  React.useEffect(() => {
    setStart(0);
    setRefID((x) => x + 1);
  }, [browserInfo?.upddate]);

  return (
    <div className="s1-object-showroom">
      {tableData.map((row, index) => (
        <ItemCard
          key={index}
          tabID={tabID}
          columns={columns}
          row={row}
          hasImage={hasImage}
          imageAccessor={imageAccessor}
          ref={tableData.length === index + 1 ? lastRowRef : undefined}
        />
      ))}
    </div>
  );
};

export default ShowRoomList;
