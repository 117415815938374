export const _setGetData = (state, action) => {
  if (state.tabs[action.payload.tabID]) state.tabs[action.payload.tabID].getData = action.payload.value;
};

export const _setCalculateData = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].shouldCalc = false;
    // if (action.payload.objectState == "new") {
    //   state.tabs[action.payload.tabID].newData = action.payload.data;
    // } else if (action.payload.objectState == "edit") {
    //   state.tabs[action.payload.tabID].buffData = action.payload.data;
    // }
    state.tabs[action.payload.tabID].buffData = action.payload.data;
  }
};

export const _setChange = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID] = {
      ...state.tabs[action.payload.tabID],
      shouldCalc: true,
      newData: {
        ...state.tabs[action.payload.tabID]?.newData,
        [action.payload.table]: [
          {
            ...state.tabs[action.payload.tabID]?.newData?.[action.payload.table]?.[0],
            [action.payload.key]: action.payload.value,
          },
        ],
      },
      buffData: {
        ...state.tabs[action.payload.tabID]?.buffData,
        [action.payload.table]: [
          {
            ...state.tabs[action.payload.tabID]?.buffData?.[action.payload.table]?.[0],
            [action.payload.key]: action.payload.value,
          },
        ],
      },
    };
  }
};

export const _deleteSelectorFields = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].buffData[action.payload.table][0][action.payload.key] = "";
    Object.keys(state.tabs[action.payload.tabID].buffData[action.payload.table][0]).map((currentKey) => {
      if (currentKey.startsWith(`${action.payload.key}_`)) {
        state.tabs[action.payload.tabID].buffData[action.payload.table][0][currentKey] = "";
      }
    });
    if (!state.tabs[action.payload.tabID].newData) {
      state.tabs[action.payload.tabID].newData = { [action.payload.table]: [{}] };
    }

    state.tabs[action.payload.tabID].newData[action.payload.table][0][action.payload.key] = "";
    Object.keys(state.tabs[action.payload.tabID].newData[action.payload.table][0]).map((currentKey) => {
      if (currentKey.startsWith(`${action.payload.key}_`)) {
        state.tabs[action.payload.tabID].newData[action.payload.table][0][currentKey] = "";
      }
    });
    state.tabs[action.payload.tabID].shouldCalc = true;
  }
};

export const _setSelectorFields = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].buffData[action.payload.table][0][action.payload.key] = action.payload.value;
    Object.keys(action.payload.fields).map((key) => {
      state.tabs[action.payload.tabID].buffData[action.payload.table][0][key] = action.payload.fields[key];
    });
    if (!state.tabs[action.payload.tabID].newData) {
      state.tabs[action.payload.tabID].newData = { [action.payload.table]: [{}] };
    }

    state.tabs[action.payload.tabID].newData[action.payload.table][0][action.payload.key] = action.payload.value;
    Object.keys(action.payload.fields).map((key) => {
      state.tabs[action.payload.tabID].newData[action.payload.table][0][key] = action.payload.fields[key];
    });
    state.tabs[action.payload.tabID].shouldCalc = true;
  }
};

export const _changeToBuffer = (state, action) => {
  state.tabs[action.payload.tabID].buffData = state.tabs[action.payload.tabID].getData.data;
};

export const _newRecord = (state, action) => {
  state.tabs[action.payload.tabID].buffData = state.tabs[action.payload.tabID].prepareNew;
  // state.tabs[action.payload.tabID].newData = state.tabs[action.payload.tabID].prepareNew;
};

export const _canSignature = (state, action) => {
  state.tabs[action.payload.tabID].signature = {
    canProceed: true,
    model: action.payload.model,
  };
};

export const _setCheckInOut = (state, action) => {
  state.tabs[action.payload.tabID].checkInOut = action.payload.value;
};
