import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../../redux/features/modals/modalsSlice";
import { languages } from "../../languages";
import { BiError } from "react-icons/bi";

const ModalError = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.modals.error);
  const currentLang = useSelector((state) => state.settings.language);
  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={error.show}
      // animation={false}
      onHide={() => dispatch(setError({ show: false }))}
      centered
      backdrop="static"
      size="lg"
    >
      <span
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%,-50%)",
          backgroundColor: "var(--bs-danger)",
          borderRadius: "50%",
          color: "var(--bs-light)",
        }}
        className="d-flex justify-content-center align-items-center shadow"
      >
        <BiError size="3rem" className="m-1 p-1" />
      </span>
      <Modal.Body className="d-flex justify-content-start align-items-center mt-4" style={{ userSelect: "none" }}>
        <div dangerouslySetInnerHTML={{ __html: (error.message ?? "").replaceAll("\r\n", "<br/>") }} />
      </Modal.Body>
      <Modal.Footer className="p-0">
        <Button variant="danger w-100" onClick={() => dispatch(setError({ show: false }))}>
          ΟΚ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalError;
