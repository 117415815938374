import React from "react";
import { Button, Container } from "react-bootstrap";
import { AiOutlineFileSearch } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setBrowserInfo, setFilterText, setLoading, setScreenState } from "../../../../redux/features/ui/uiSlice";
import { getFilters } from "../../../../redux/selectors";
import getBrowserInfo from "../../../../services/getBrowserInfo";
const TopBarOnDialog = ({ tabID }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => getFilters(state, tabID));

  const handleGoToBrowser = async () => {
    dispatch(setLoading({ show: true, tabID }));
    var text = "";
    if (filters?.data) {
      for (const [tablename] of Object.entries(filters.data)) {
        for (const [field, value] of Object.entries(filters.data[tablename])) {
          text +=
            value != "" ? (text != "" ? `&${tablename}.${field}=${value}` : `${tablename}.${field}=${value}`) : "";
        }
      }
    }
    dispatch(setFilterText({ value: text, tabID }));
    const browserInfo = await getBrowserInfo(tabID);
    dispatch(setBrowserInfo({ value: browserInfo, tabID }));
    dispatch(setLoading({ show: false, tabID }));
    dispatch(setScreenState({ value: "browser", tabID }));
  };

  return (
    <Container fluid className="object-top-bar">
      <Button variant="secondary" onClick={handleGoToBrowser}>
        <AiOutlineFileSearch />
      </Button>
    </Container>
  );
};

export default TopBarOnDialog;
