import { getFilters } from "../../redux/selectors";
import store from "../../redux/store";

const getValueFromFilterArray = (table, key) => {
  const state = store.getState();
  const filters = getFilters(state);

  var value = "";
  const araryFilters = filters?.text.split("&");
  araryFilters &&
    araryFilters.every((x) => {
      if (x.split("=")[0].split(".")[0] == table && x.split("=")[0].split(".")[1] == key) {
        value = x.split("=")[1] == "*" ? "" : x.split("=")[1];
        return false;
      } else {
        return true;
      }
    });
  return value;
};

export default getValueFromFilterArray;
