import React from "react";
import { Container } from "react-bootstrap";
import Pages from "./footer/Pages";
import ArrowsBack from "./footer/ArrowsBack";
import ArrowsNext from "./footer/ArrowsNext";

const ReportFooter = ({ tabID }) => {
  return (
    <Container
      fluid
      style={{ backgroundColor: "var(--bs-gray-dark)", gap: "1rem", height: 40 }}
      className=" d-flex align-items-center py-2"
    >
      <ArrowsBack tabID={tabID} />
      <div className="vr" />
      <Pages tabID={tabID} />
      <div className="vr" />
      <ArrowsNext tabID={tabID} />
    </Container>
  );
};

export default ReportFooter;
