import React from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentGridLine, getNewData, getObjectState } from "../../../redux/selectors";
import { deleteLineFromGrid, setCurrentDetailLine, triggerGridChange } from "../../../redux/features/ui/uiSlice";
import { calculate } from "../../../services/calculate";
import { languages } from "../../languages";
import { HiCheck, HiX } from "react-icons/hi";
import { BiQuestionMark } from "react-icons/bi";

const DeleteLineModal = ({ show, setShow, tabID, element }) => {
  const dispatch = useDispatch();
  const grid = useSelector((state) => getCurrentGridLine(state, tabID, element.model));
  const newData = useSelector((state) => getNewData(state, tabID));
  const currentLang = useSelector((state) => state.settings.language);

  const handleAccept = async () => {
    setShow(false);
    var gridLines = newData?.[element.model] ?? [];
    gridLines.length == 0 && dispatch(triggerGridChange({ tabID, name: element.model }));
    dispatch(deleteLineFromGrid({ tabID, name: element.model, lineIndex: grid.index }));
    await calculate(tabID);
    dispatch(setCurrentDetailLine({ tabID, name: element.model, value: undefined }));
  };
  return (
    <Modal backdrop="static" size="lg" show={show} centered onHide={() => setShow(false)}>
      <span
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%,-50%)",
          backgroundColor: "var(--bs-warning)",
          color: "var(--bs-dark)",
          borderRadius: "50%",
        }}
        className="d-flex justify-content-center align-items-center shadow"
      >
        <BiQuestionMark size="3rem" className="m-1 p-1" />
      </span>

      <Modal.Header className="p-2 ps-3 pb-0 border-0" closeButton />
      <Modal.Body className="d-flex flex-column " style={{ userSelect: "none" }}>
        {languages.generalText[currentLang].shouldDeleteLine}
      </Modal.Body>
      <Modal.Footer className="picker-buttons">
        <ButtonGroup>
          <Button variant="outline-secondary" className="rounded-0" onClick={() => setShow(false)}>
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].cancel}
            </div>
          </Button>
          <Button className="rounded-0" variant="outline-secondary" onClick={handleAccept}>
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].confirm}
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteLineModal;
