import React from "react";
import { BsCart4 } from "react-icons/bs";
import ModalCart from "../../show room/ModalCart";
import { useSelector } from "react-redux";
import { getTabIdx } from "../../../../redux/selectors";
import { getItemsCount } from "../../../../redux/selectors/showRooms";

const CartButton = ({ tabID }) => {
  const [show, setShow] = React.useState(false);
  const idx = useSelector((state) => getTabIdx(state, tabID));
  const count = useSelector((state) => getItemsCount(state, idx));
  return (
    <>
      <nav className="menu" onClick={() => setShow(true)}>
        {count > 0 && (
          <span
            style={{
              position: "absolute",
              fontSize: 12,
              left: "50%",
              transform: "translate(-50%,40%)",
              zIndex: 3,
              color: "var(--bs-light)",
            }}
          >
            {count}
          </span>
        )}
        <label className="menu-open-button shadow ">
          <BsCart4 className="p-2" />
        </label>
      </nav>
      <ModalCart tabID={tabID} idx={idx} show={show} setShow={setShow} />
    </>
  );
};

export default CartButton;
