import React from "react";
import { IoPencilSharp } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { getAccesRights, getObjectState } from "../../../../../redux/selectors";
import { Dropdown } from "react-bootstrap";
import { changeToBuffer, setObjectState } from "../../../../../redux/features/ui/uiSlice";
import { languages } from "../../../../languages";

const Edit = ({ tabID }) => {
  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const accessRights = useSelector((state) => getAccesRights(state, tabID));
  const currentLang = useSelector((state) => state.settings.language);

  const hanldeClick = () => {
    dispatch(changeToBuffer({ tabID }));
    dispatch(setObjectState({ tabID, value: "edit" }));
  };

  return (
    accessRights?.update == true && (
      <Dropdown.Item
        disabled={objectState != "view"}
        onClick={hanldeClick}
        className="d-flex align-items-center justify-content-start"
      >
        <div className="me-1 d-flex justify-content-start align-items-center">
          <IoPencilSharp size="1.2rem" />
        </div>

        <span className="text-truncate"> {languages.generalText[currentLang].edit}</span>
      </Dropdown.Item>
    )
  );
};

export default Edit;
