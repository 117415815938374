import axios from "axios";
import { setCalculateData, setLoading } from "../redux/features/ui/uiSlice";
import {
  getBuffData,
  getClientID,
  getCommandByIndex,
  getFormDesign,
  getNewData,
  getObjectState,
  getSN,
  getURL,
} from "../redux/selectors";
import store from "../redux/store";
import { setError, setMessage } from "../redux/features/modals/modalsSlice";

export const calculate = async (tabID) => {
  const state = store.getState();

  const url = getURL(state);
  const clientID = getClientID(state);
  const serialnumber = getSN(state);
  const command = getCommandByIndex(state, tabID);
  const object = `${serialnumber}/object=${command}`;

  const objectState = getObjectState(state, tabID);
  const formdesign = getFormDesign(state, tabID);
  const buffData = getBuffData(state, tabID);
  const newData = getNewData(state, tabID);
  const key = formdesign.key;
  const tableName = key.split(".")[0];
  const fieldName = key.split(".")[1];
  const keyValue = buffData?.[tableName]?.[0]?.[fieldName] ?? "";
  const handleIncoming = (data) => store.dispatch(setCalculateData({ data, tabID, objectState }));
  const handleLoading = (value) => store.dispatch(setLoading({ show: value, tabID }));
  const handleSetError = (value) => store.dispatch(setError({ show: true, message: value }));
  const handleSetMessage = (value) => store.dispatch(setMessage({ show: true, message: value }));

  var locateinfo = "";
  Object.entries(formdesign.model).forEach(([key, value], index) => {
    locateinfo += index == 0 ? `${key}:` : `;${key}:`;
    if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
    else
      value.fields.map((item) => {
        locateinfo += `${item.name},`;
      });
  });

  const data = {};
  if (objectState == "new") {
    for (const property in buffData) {
      if (formdesign.model?.[property]?.editable === true) {
        data[property] = buffData[property];
      }
    }
  } else {
    for (const property in newData) {
      if (formdesign.model?.[property]?.editable === true) {
        data[property] = newData[property];
      }
    }
  }

  handleLoading(true);
  const response = await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "calculate",
        clientID,
        object,
        data,
        key: keyValue > 0 ? keyValue : "",
        locateinfo,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
  if (response.success) {
    handleIncoming(response.data);
    if (response.message) {
      handleSetMessage(response.message);
    }
    handleLoading(false);
    return true;
  } else {
    handleSetError(response.error);
    handleLoading(false);
    return false;
  }
};
