import React from "react";
import { getBrowserInfo, getBrowserInfoFields, getTableColumns, getTableData } from "../../../../redux/selectors";
import { useSelector } from "react-redux";
import Row from "./Row";
import useFetch from "./table/useFetch";

const List = ({ tabID }) => {
  const tableData = useSelector((state) => getTableData(state, tabID));
  const [start, setStart] = React.useState(0);
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));
  const browserInfoFields = useSelector((state) => getBrowserInfoFields(state, tabID));
  const tableColumns = useSelector((state) => getTableColumns(state, tabID));
  const [hasImage, setHasImage] = React.useState(false);
  const [AAIndex, setAAIndex] = React.useState(0);
  const [imageIndex, setImageIndex] = React.useState(-1);
  const settings = useSelector((state) => state.settings);
  const { isLoading, hasNextPage } = useFetch({ start, tabID });

  const intObserver = React.useRef();
  const lastRowRef = React.useCallback(
    (row) => {
      if (isLoading) return;
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((rows) => {
        if (rows[0].isIntersecting && hasNextPage) {
          browserInfo?.totalcount >
            parseInt(settings?.app?.browserLimit ?? process.env.REACT_APP_GETBROWSERDATA_LIMIT) &&
            setStart((prev) => prev + 1);
        }
      });

      if (row) intObserver.current.observe(row);
    },
    [isLoading, hasNextPage]
  );

  React.useEffect(() => {
    tableColumns &&
      tableColumns.map((column, index) => {
        if (column.dataIndex && column.dataIndex.includes("-")) {
          setAAIndex(index);
        }
      });
  }, [tableColumns]);

  React.useEffect(() => {
    if (browserInfoFields) {
      for (var i = 0; i < browserInfoFields.length; i++) {
        if (browserInfoFields[i].type == "image") {
          setHasImage(true);
          console.log(i);
          setImageIndex(i);
          break;
        }
      }
    }
  }, [browserInfoFields]);

  React.useEffect(() => {
    setStart(0);
  }, [browserInfo?.upddate]);

  return (
    <div className="browser-list">
      {tableData.map((row, rowIndex) => (
        <Row
          tabID={tabID}
          AAIndex={AAIndex}
          key={rowIndex}
          row={row}
          hasImage={hasImage}
          imageIndex={imageIndex}
          ref={tableData.length === rowIndex + 1 ? lastRowRef : undefined}
        />
      ))}
    </div>
  );
};

export default List;
