import React from "react";
import Signature from "./buttons/Signature";
import Video from "./buttons/Video";
import Image from "./buttons/Image";
import Attachment from "./buttons/Attachment";
const S1RelDocsBar = ({ tabID, element }) => {
  return (
    <div className="s1reldocs-bar">
      <Signature tabID={tabID} element={element} />
      <Video tabID={tabID} element={element} />
      <Image tabID={tabID} element={element} />
      <Attachment tabID={tabID} element={element} />
    </div>
  );
};

export default S1RelDocsBar;
