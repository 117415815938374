import React from "react";
import { Dropdown, Button } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";
import PrintRecord from "./PrintRecord";
import Edit from "./Edit";
import NewRecord from "./NewRecord";
import DeleteRecord from "./DeleteRecord";
import { useDispatch, useSelector } from "react-redux";
import { getCommandParams, getObjectState, getRelJobMenu, getTabIdx } from "../../../../../redux/selectors";
import RelJob from "./RelJob";
import getWebMenu from "../../../../../services/getWebMenu";
import { setCustomRelJobMenuProperty, setLoading, setRelJobMenu } from "../../../../../redux/features/ui/uiSlice";
import { setError } from "../../../../../redux/features/modals/modalsSlice";
import { languages } from "../../../../languages";
import Signature from "./Signature";

const Menu = ({ tabID }) => {
  const dispatch = useDispatch();
  const idx = useSelector((state) => getTabIdx(state, tabID));
  const commandParams = useSelector((state) => getCommandParams(state, tabID));
  const relJobMenu = useSelector((state) => getRelJobMenu(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const currentLang = useSelector((state) => state.settings.language);

  const RelJobDropDown = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      className="d-flex align-items-center justify-content-center user-select-none"
      onClick={(e) => {
        e.preventDefault();
        handleClickonRelJob(() => onClick(e));
      }}
    >
      {children}
    </a>
  ));

  const handleClickonRelJob = async (callback) => {
    if (objectState == "view") {
      if (commandParams.rj && !relJobMenu) {
        dispatch(setLoading({ show: true, tabID }));
        const response = await getWebMenu({ menuname: commandParams.rj });
        if (response.success) {
          Object.keys(response.menu).map((key) => {
            if (response.menu[key].command.startsWith("_CheckIn"))
              dispatch(
                setCustomRelJobMenuProperty({
                  idx,
                  tabID,
                  key: "checkIn",
                  value: response.menu[key].command.split(";")[0].split(":")[1],
                })
              );
            if (response.menu[key].command.startsWith("_CheckOut"))
              dispatch(
                setCustomRelJobMenuProperty({
                  idx,
                  tabID,
                  key: "checkOut",
                  value: response.menu[key].command.split(";")[0].split(":")[1],
                })
              );
          });
          dispatch(setRelJobMenu({ value: response.menu, tabID, idx }));
        } else {
          dispatch(setError({ show: true, error: response.error }));
        }
        dispatch(setLoading({ show: false, tabID }));
      }
      callback();
    }
  };
  return (
    <Dropdown className="d-flex" align="start">
      <Dropdown.Toggle variant="secondary" as={RelJobDropDown}>
        <Button disabled={objectState != "view"} variant="secondary">
          <HiDotsVertical />
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="shadow"
        style={{
          minWidth: 200,
          maxWidth: "55vw",
          maxHeight: "70vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div className="d-flex flex-column">
          <span className="s1-browser-menu-title"> {languages.generalText[currentLang].actions}</span>
          <PrintRecord tabID={tabID} />
          <Signature tabID={tabID} />
          <Edit tabID={tabID} />
          <NewRecord tabID={tabID} />
          <DeleteRecord tabID={tabID} />
          {commandParams.rj && <RelJob tabID={tabID} />}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default Menu;
