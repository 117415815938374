import { createSlice } from "@reduxjs/toolkit";
import { clear } from "../session/sessionSlice";

const initialState = {
  ...JSON.parse(localStorage.getItem("systemParams")),
};

const systemParamsSlice = createSlice({
  name: "systemParams",
  initialState,
  reducers: {
    set: (state, action) => {
      localStorage.setItem("systemParams", JSON.stringify(action.payload));
      return { ...state, ...action.payload };
    },
    setOutpuFormats: (state, action) => {
      const obj = { ...action.payload };
      Object.keys(obj?.form).map((key) => {
        switch (key) {
          case "PDF":
            obj.form[key] = "PDF";
            break;
          case "XPS":
            delete obj.form[key];
            break;
          default:
            break;
        }
      });

      Object.keys(obj?.list).map((key) => {
        switch (key) {
          case "928":
            obj.list[key] = "ASCII";
            break;
          case "Excel":
            obj.list[key] = "Excel";
            break;
          case "Image":
            obj.list[key] = "HTML";
            break;
          case "PDF":
            obj.list[key] = "PDF";
            break;
          case "Word":
            obj.list[key] = "Word";
            break;
          case "XPS":
            delete obj.list[key];
            break;
          default:
            break;
        }
      });
      return { ...state, outputformats: obj };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clear, (state) => {
      localStorage.setItem("systemParams", null);
      return { printerOptions: state.printerOptions };
    });
  },
});

export const { set, setOutpuFormats } = systemParamsSlice.actions;

export default systemParamsSlice.reducer;
