import React from "react";
import MainBar from "./application/bars/MainBar";
import { useDispatch, useSelector } from "react-redux";
import { setOutpuFormats } from "./redux/features/systemParams/systemParamsSlice";
import { set as setRoot } from "./redux/features/roots/rootsSlice";
import { set as setShowRooms } from "./redux/features/showRooms/showRoomsSlice";
import getWebMenu from "./services/getWebMenu";
import { setWebMenu } from "./redux/features/screens/screensSlice";
import { setLoading } from "./redux/features/modals/modalsSlice";
import MainPanel from "./application/screens/app/MainPanel";
import ModalError from "./application/ui/modals/ModalError";
import ModalMessage from "./application/ui/modals/ModalMessage";
import ModalChangePassWord from "./application/ui/modals/ModalChangePassWord";
import ModalWarningForBlock from "./application/ui/modals/ModalWarningForBlock";
import ModalBlocked from "./application/ui/modals/ModalBlocked";
import ModalSettings from "./application/ui/modals/ModalSettings";
import ConfirmLogOut from "./application/ui/modals/ConfirmLogOut";
import { deserializeWebMenu } from "./application/functions/deserializeWebMenu";
import { fetchSystemParams } from "./application/functions/fetchSystemParams";

const App = () => {
  const [webMenuData, setWebMenuData] = React.useState();
  const [showSideBar, setShowSideBar] = React.useState(false);
  const [fetching, setFetching] = React.useState(true);
  const [enableModals, setEnableModals] = React.useState(false);
  const dispatch = useDispatch();
  const systemParams = useSelector((state) => state.systemParams);
  const user = systemParams?.userid;

  const fetchWebMenu = async () => {
    dispatch(setLoading(true));
    const webMenu = await getWebMenu({ user });
    if (webMenu.success) {
      const menuObj = deserializeWebMenu(webMenu.menu);
      setWebMenuData(webMenu.menu);
      dispatch(setWebMenu(menuObj.menu));
      dispatch(setOutpuFormats(webMenu.outputformats));
      menuObj.roots.length > 0 && dispatch(setRoot(menuObj.roots));
      Object.keys(menuObj.showRooms).length > 0 && dispatch(setShowRooms(menuObj.showRooms));
    } else {
      console.log(webMenu);
    }
    dispatch(setLoading(false));
    setFetching(false);
    setEnableModals(true);
  };

  React.useEffect(() => {
    fetchSystemParams();
    fetchWebMenu();
  }, []);

  return (
    <>
      <div className="App d-flex flex-column w-100 h-100">
        <MainBar webMenuData={webMenuData} show={showSideBar} setShow={setShowSideBar} />
        {!fetching && <MainPanel webMenuData={webMenuData} />}
        <ModalError />
        <ModalMessage />
        <ModalChangePassWord />
        <ModalSettings />
        <ConfirmLogOut />

        {enableModals && <ModalWarningForBlock />}
        {enableModals && <ModalBlocked />}
      </div>
    </>
  );
};

export default App;
