import React from "react";
import { Form } from "react-bootstrap";
import { HiSearch, HiX } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { getFilters } from "../../../../../redux/selectors";
import { setBrowserInfo, setLoading, setSearchText } from "../../../../../redux/features/ui/uiSlice";
import getBrowserInfo from "../../../../../services/getBrowserInfo";
import axios from "axios";
import { Button } from "react-bootstrap";
import { languages } from "../../../../languages";

const Search = ({ tabID }) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const filters = useSelector((state) => getFilters(state, tabID));
  const [openSearch, setOpenSearch] = React.useState(settings.app.browserSearchActive && filters?.searchText);
  const [searchValue, setSearchValue] = React.useState("");
  const searchRef = React.useRef(null);
  const currentLang = useSelector((state) => state.settings.language);

  React.useEffect(() => {
    openSearch && searchRef.current.focus();
    setSearchValue(settings.app.browserSearchActive ? filters?.searchText ?? "" : "");
  }, [openSearch]);

  const handleSearch = async (e) => {
    var value = e?.target?.value ?? e;
    if (value != filters?.searchText) {
      e?.target?.value && e.preventDefault();
      e?.target?.value && e.target.blur();
      dispatch(setLoading({ show: true, tabID }));
      dispatch(setSearchText({ value, tabID }));
      const browserInfo = await getBrowserInfo(tabID);
      dispatch(setBrowserInfo({ value: browserInfo, tabID }));
      dispatch(setLoading({ show: false, tabID }));
      settings.app.browserSearchActive != 1 && dispatch(setSearchText({ value: undefined, tabID }));

      (settings.app.browserSearchActive != 1 || value == "") && setOpenSearch(false);
    }
  };

  return (
    <div className="d-flex justify-content-start w-100">
      <Button variant="secondary" onClick={() => setOpenSearch((x) => !x)}>
        <HiSearch />
      </Button>
      <div
        className="align-items-center justify-content-start"
        style={{ display: openSearch ? "flex" : "none", flex: 1 }}
      >
        <Form.Control
          placeholder={languages.generalText[currentLang].search}
          style={{ color: "white" }}
          className="search"
          value={searchValue}
          ref={searchRef}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSearch(event);
            }
          }}
        />
        {searchValue != "" && (
          <Button
            variant="secondary"
            onClick={() => {
              setSearchValue("");
              handleSearch("");
            }}
          >
            <HiX />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Search;
