export const _batchStart = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].batchStart = Date.now();
    state.tabs[action.payload.tabID].batchEnd = undefined;
    state.tabs[action.payload.tabID].batchMessage = undefined;
  }
};

export const _batchEnd = (state, action) => {
  if (state.tabs[action.payload.tabID]) state.tabs[action.payload.tabID].batchEnd = Date.now();
};

export const _batchMessage = (state, action) => {
  if (state.tabs[action.payload.tabID]) state.tabs[action.payload.tabID].batchMessage = action.payload.value;
};
