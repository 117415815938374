import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommandParams, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";
import { calculate } from "../../../services/calculate";
import { TiDeleteOutline } from "react-icons/ti";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";

const S1Time = ({ element, tabID, screenState, currentLine, grid }) => {
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const model = screenState == "form" && formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0];
  const data = useSelector((state) => getRecData(state, tabID));
  const tableIndex = formDesign?.model?.[table]?.relationship == "OneToOne" ? 0 : currentLine?.index ?? 0;
  const currentGridLine = grid?.currentLine;

  const dateRef = React.useRef(null);
  const contRef = React.useRef(null);

  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [viewValue, setViewValue] = React.useState("");

  const handleCalculate = async (table, key, value) => {
    if (grid) {
      dispatch(setGridChange({ tabID, name: grid.model, key, value }));
    } else {
      dispatch(setChange({ tabID, table, key, value }));
      model?.updates && (await calculate(tabID));
    }
  };

  const handleClear = () => {
    setValue("");
    setViewValue("");
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else {
      handleCalculate(table, key, "");
    }
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value);
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    }
  }, [screenState]);

  React.useEffect(() => {
    if (screenState == "form") {
      if (data?.[table]?.[tableIndex]?.[key] && data?.[table]?.[tableIndex]?.[key] != "") {
        setValue(timeConverterForView(data?.[table]?.[tableIndex]?.[key]));
        setViewValue(timeConverterForView(data?.[table]?.[tableIndex]?.[key]));
      } else {
        setValue("");
      }
    }
  }, [data?.[table]?.[tableIndex]?.[key]]);

  React.useEffect(() => {
    if (grid) {
      if (currentGridLine?.line?.[key] && currentGridLine?.line?.[key] != "") {
        setValue(timeConverterForView(currentGridLine?.line?.[key]));
        setViewValue(timeConverterForView(currentGridLine?.line?.[key]));
      } else {
        setValue("");
        setViewValue("");
      }
    }
  }, [currentGridLine?.line?.[key]]);

  React.useEffect(() => {
    if (isFocused) {
      dateRef.current.showPicker();
      dateRef.current.click();
      dateRef.current.focus();
    }
  }, [isFocused]);

  const handleChange = (value) => {
    if (screenState == "dialog") {
      setValue(value);
      dispatch(setFilter({ tabID, table, key, value: timeConverterForData(value) }));
    } else if (screenState == "form") {
      if (objectState == "view") {
        dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ tabID, value: "edit" }));
      }
      handleCalculate(table, key, timeConverterForData(value));
    }
  };

  return (
    <div
      className="s1time w-100"
      ref={contRef}
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <InputGroup className={`crm-floating-with-icon${element.required && value == "" ? " input-required" : ""}`}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            onClick={() => objectState != "view" && setIsFocused(true)}
            className={`d-flex w-100 ps-1 border-0${value != "" ? " date-input-has-value" : ""}${
              element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
            }`}
            disabled={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            readOnly={true}
            value={viewValue}
            name={element.name}
            placeholder={element.caption}
          />
        </FloatingLabel>
        {objectState != "view" && value != "" && !element.readOnly && (
          <div className="d-flex align-items-center justify-content-center me-1" onClick={handleClear}>
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
      <input
        type="time"
        ref={dateRef}
        value={value}
        className="text-truncate"
        onBlur={() => setIsFocused(false)}
        onChange={(e) => !element.readOnly && handleChange(e.target.value)}
        style={{
          pointerEvents: "none",
          position: "fixed",
          top: contRef?.current?.getBoundingClientRect()?.bottom,
          maxWidth: 0,
          maxHeight: 0,
          backgroundColor: "transparent",
          border: 0,
          color: "transparent",
        }}
      />
    </div>
  );
};

export default S1Time;

const timeConverterForView = (value) => {
  if (value != "") {
    var d = new Date(value);
    return d.getHours().padLeft() + ":" + d.getMinutes().padLeft();
  } else {
    return value;
  }
};

const timeConverterForData = (value) => {
  if (value != "") {
    return "1899-12-30 " + value;
  } else {
    return value;
  }
};
