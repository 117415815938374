import React from "react";
import { Button } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { killTab } from "../../../../redux/features/ui/uiSlice";
import Save from "./Save";

const TopBarOnSettings = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(killTab(-2));
  };

  return (
    <div className="object-top-bar justify-content-between">
      <Button variant="secondary" onClick={handleClose}>
        <AiOutlineArrowLeft />
      </Button>
      <Save />
    </div>
  );
};

export default TopBarOnSettings;
