import React from "react";
import { Container } from "react-bootstrap";
import SaveList from "./Save";
import Cancel from "./Cancel";
import New from "./New";
import Print from "./Print";

const TopBarOnEditList = ({ tabID }) => {
  return (
    <Container fluid className="object-top-bar">
      <New tabID={tabID} />
      <SaveList tabID={tabID} />
      <Cancel tabID={tabID} />
      <Print tabID={tabID} />
    </Container>
  );
};

export default TopBarOnEditList;
