import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthenticateRoute = () => {
  const login = useSelector((state) => state.login);
  const session = useSelector((state) => state.session);

  if (session !== null) {
    return <Navigate to="/" />;
  } else {
    if (login !== null) {
      return <Outlet />;
    } else {
      return <Navigate to="/login" />;
    }
  }
};

export default AuthenticateRoute;
