import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGadgetBrowserInfo, getGadgetTSortField } from "../../../../../redux/selectors";
import { BiSortDown, BiSortUp } from "react-icons/bi";
import { setBrowserinfo, setLoading, setSortField } from "../../../../../redux/features/portal/portalSlice";
import analyticGetBrowserInfo from "../../../../../services/analyticGetBrowserInfo";
const THead = React.forwardRef(({ headerGroups, rows, id, BodyRef }, HeadRef) => {
  const browserInfo = useSelector((state) => getGadgetBrowserInfo(state, id));
  const sortField = useSelector((state) => getGadgetTSortField(state, id));
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.settings.app);

  const handleSort = async (column) => {
    console.log(column);
    dispatch(setSortField({ value: sortField == column ? "*" + column : column, id }));
    dispatch(setLoading({ value: true, id }));
    const browserInfo = await analyticGetBrowserInfo(id);
    dispatch(setBrowserinfo({ value: browserInfo, id }));
    dispatch(setLoading({ value: false, id }));
  };
  return (
    <div
      className="browser-table-thead"
      style={{ minHeight: "40px", overflowX: "auto" }}
      ref={HeadRef}
      onScroll={() => {
        const { scrollLeft } = HeadRef.current;
        BodyRef.current.scrollLeft = scrollLeft;
      }}
    >
      {headerGroups.map((headerGroup) => {
        return (
          <div
            className="browser-table-tr"
            {...headerGroup.getHeaderGroupProps()}
            style={
              rows?.getRowProps
                ? {
                    ...rows?.getRowProps()?.style,
                    display: "flex",
                    paddingRight: (BodyRef?.current?.offsetWidth ?? 0) - (BodyRef?.current?.clientWidth ?? 0),
                  }
                : {
                    display: "flex",
                    paddingRight: (BodyRef?.current?.offsetWidth ?? 0) - (BodyRef?.current?.clientWidth ?? 0),
                  }
            }
          >
            {headerGroup.headers.map((column, index) => {
              const field = browserInfo.fields.filter((item) => item.name == column.dataIndex);
              return (
                <div
                  {...column.getHeaderProps()}
                  className="browser-table-th text-truncate"
                  //   onContextMenu={(e) => handleRightClick(e, column.dataIndex)}
                  key={index}
                  style={{
                    ...column.getHeaderProps().style,
                    // display: flexLayout == true ? "flex" : column.getHeaderProps().style.display,
                    // backgroundColor:
                    //   sortField && sortField.indexOf(column.dataIndex) > -1
                    //     ? "var(--bs-gray-600)"
                    //     : "Var(--bs-gray-dark)",
                    backgroundColor: "var(--bs-gray-dark)",
                  }}
                >
                  <div
                    className="d-flex align-items-center text-truncate"
                    style={{
                      justifyContent: column?.align ?? "flex-start",
                    }}
                  >
                    {column.render("Header")}
                    {sortField && sortField.indexOf(column.dataIndex) > -1 ? (
                      sortField.indexOf("*") > -1 ? (
                        <BiSortDown className="ms-1" size="1rem" style={{ minHeight: "1rem", minWidth: "1rem" }} />
                      ) : (
                        <BiSortUp className="ms-1" size="1rem" style={{ minHeight: "1rem", minWidth: "1rem" }} />
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    {...column.getResizerProps()}
                    className={`browser-table-resizer ${column.isResizing ? "isResizing" : ""}`}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
});

export default THead;
