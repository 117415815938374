import React from "react";
import { HiPrinter } from "react-icons/hi";

const Print = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-start"
      style={{
        userSelect: "none",
        cursor: "pointer",
      }}
    >
      <HiPrinter size="1.2rem" className="me-1" />
    </div>
  );
};

export default Print;
