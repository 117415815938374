import React from "react";
import { useSelector } from "react-redux";
import { languages } from "../languages";
import { BsInfo } from "react-icons/bs";

const NoBrowserData = () => {
  const currentLang = useSelector((state) => state.settings.language);

  return (
    <div className="d-flex w-100 h-100 flex-column justify-content-center align-items-center">
      <span style={{ fontSize: "1.8rem", fontWeight: "bold" }}> {languages.generalText[currentLang].info}</span>
      <BsInfo size="128px" />
      <span style={{ fontSize: "1.3rem" }}> {languages.generalText[currentLang].noDataToShow}</span>
      <span> {languages.generalText[currentLang].checkFilters}</span>
    </div>
  );
};

export default NoBrowserData;
