import React from "react";
import { BsChevronDown, BsSearch } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getFilters, getObjectState } from "../../../redux/selectors";
import { Button, ButtonGroup, FloatingLabel, Form, InputGroup, Modal } from "react-bootstrap";
import { HiX, HiCheck } from "react-icons/hi";
import { languages } from "../../languages";

const S1StringsMulti = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => getFilters(state, tabID));
  const currentLang = useSelector((state) => state.settings.language);

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const [editorData, setEditorData] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState("");

  const objectState = useSelector((state) => getObjectState(state, tabID));

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const tableRef = React.useRef(null);

  const [isFocused, setIsFocused] = React.useState(false);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    let stringDataObj = {};
    element.editor.split("|").forEach((i) => (stringDataObj[i.split("=")[0]] = i.split("=")[1]));
    setEditorData(stringDataObj);
  }, []);

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(filters?.data?.[table]?.[key] ?? "");
      setSelectedRows(filters?.data?.[table]?.[key] ?? "");
    }
  }, [filters?.data?.[table]?.[key]]);

  React.useEffect(() => {
    if (openPicker) {
      setSelectedRows(value != "" ? value.split(",") : []);
    }
  }, [openPicker]);

  const handlePicker = (e) => {
    e.stopPropagation();
    setOpenPicker((x) => !x);
    setIsFocused((x) => !x);
  };

  const handleClear = () => {
    setOpenPicker(false);
    setIsFocused(false);
    dispatch(setFilter({ tabID, table, key, value: "" }));
  };

  const handleClickOnRow = (index) => {
    const SelectedArray = [...selectedRows];
    if (SelectedArray.indexOf(index) >= 0) {
      SelectedArray.splice(SelectedArray.indexOf(index), 1);
    } else {
      SelectedArray.push(index);
    }
    setSelectedRows(SelectedArray);
  };

  const handleAccept = () => {
    dispatch(setFilter({ tabID, table, key, value: selectedRows.join(",") }));
    setOpenPicker(false);
  };
  return (
    <div
      className="s1stringmulti w-100"
      style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}
      hidden={!element.visible}
    >
      <InputGroup className={`crm-floating-with-icon${element.required && value == "" ? " input-required" : ""}`}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            role="button"
            className={`w-100 ps-1 border-0${
              element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
            }`}
            disabled={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            readOnly={true}
            value={value}
            name={element.name}
            placeholder={element.caption}
            onFocus={(e) => e.target.blur()}
            onClick={(e) =>
              !(
                element.readOnly ||
                (screenState == "form" && commandParams.readonly) ||
                (screenState == "form" && objectState == "view")
              ) && handlePicker(e)
            }
          />
        </FloatingLabel>
        {value != "" &&
          !element.readOnly &&
          ((screenState == "form" && objectState != "view") || screenState == "dialog") && (
            <div className="d-flex align-items-center justify-content-center" onClick={handleClear}>
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
      </InputGroup>
      {openPicker && (
        <Modal
          show={openPicker}
          fullscreen
          animation={false}
          className="d-flex align-items-end"
          dialogClassName="p-3"
          contentClassName="rounded overflow-hidden shadow"
        >
          <div className="input-picker  w-100 h-100" style={{ overflow: "auto" }}>
            <div className="input-picker-table w-100" ref={tableRef}>
              {Object.keys(editorData).map((el, index) => {
                return (
                  <div
                    key={index}
                    className={`input-picker-table-row${
                      selectedRows.indexOf(el) > -1 ? " input-picker-table-row-selected" : ""
                    }`}
                    onClick={() => handleClickOnRow(el)}
                  >
                    <span className="searchable" key={index}>
                      {editorData[el]}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <Modal.Footer className="picker-buttons">
            <ButtonGroup>
              <Button
                variant="outline-secondary"
                className="rounded-0"
                onClick={() => {
                  setOpenPicker(false);
                }}
              >
                <HiX size={25} color="var(--bs-danger)" className="me-2" />
                <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                  {languages.generalText[currentLang].closing}
                </div>
              </Button>
              <Button className="rounded-0" variant="outline-secondary" onClick={handleAccept}>
                <HiCheck size={25} color="var(--bs-success)" className="me-2" />
                <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                  {languages.generalText[currentLang].apply}
                </div>
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default S1StringsMulti;
