import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import { calculate } from "../../../services/calculate";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";

const S1Email = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getRecData(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID)?.data);
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const currentGridLine = grid?.currentLine;

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model = screenState == "form" && formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0];
  const vf = screenState == "form" && model.editor.split("|")[2];

  const [value, setValue] = React.useState("");

  const handleCalculate = async (value) => {
    if (grid) {
      dispatch(setGridChange({ tabID, name: grid.model, key, value }));
    } else {
      dispatch(setChange({ tabID, table, key, value }));
      model?.updates && (await calculate(tabID, objectState));
    }
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value);
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    }
  }, [screenState]);

  React.useEffect(() => {
    screenState == "form" && !grid && setValue(data?.[table]?.[0]?.[key] ?? "");
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    screenState == "form" && grid && setValue(currentGridLine?.line?.[key] ?? "");
  }, [currentGridLine?.line?.[key]]);

  const handleChange = (value) => {
    if (screenState == "dialog") {
      if (filters?.[table]?.[key] != value) {
        dispatch(setFilter({ tabID, table, key, value: value }));
      }
    } else if (screenState == "form") {
      if ((data?.[table]?.[0]?.[key] ?? "") != value) {
        handleCalculate(value);
      }
    }
  };

  const handleClear = () => {
    setValue("");

    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else if (screenState == "form") {
      if (objectState == "view") {
        dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ value: "edit", tabID }));
      }
      handleCalculate("");
    }
  };

  return (
    <div className="s1email w-100" style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className={`crm-floating-with-icon${element.required && value == "" ? " input-required" : ""}`}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className={`w-100 ps-1 border-0${
              element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
            }`}
            disabled={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            readOnly={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            value={value}
            name={element.name}
            placeholder={element.caption}
            onChange={(e) => setValue(e.target.value)}
            onBlur={(e) => handleChange(e.target.value)}
          />
        </FloatingLabel>
        {objectState != "view" && value != "" && !element.readOnly && (
          <div className={`d-flex align-items-center justify-content-center`} onClick={handleClear}>
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

export default S1Email;
