import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { newRecord, setLoading, setObjectState, setPrepareNew } from "../../../../../redux/features/ui/uiSlice";
import { TiPlus } from "react-icons/ti";
import { setSaveModal } from "../../../../../redux/features/modals/modalsSlice";
import { getAccesRights, getCurrentTab, getObjectState } from "../../../../../redux/selectors";
import preparNew from "../../../../../services/prepareNew";
import ModalSaveRecord from "./ModalSaveRecord";
import { Dropdown } from "react-bootstrap";
import { languages } from "../../../../languages";

const NewRecord = ({ tabID }) => {
  const dispatch = useDispatch();
  const tab = useSelector((state) => getCurrentTab(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const currentLang = useSelector((state) => state.settings.language);
  const accessRights = useSelector((state) => getAccesRights(state, tabID));

  const [show, setShow] = React.useState(false);

  const handleNew = async () => {
    if (objectState == "view") {
      const FormDesign = tab.formDesign;
      var locateinfo = "";
      // if (!tab.prepareNew) {
      dispatch(setLoading({ show: true, tabID }));
      Object.entries(FormDesign.model).forEach(([key, value], index) => {
        locateinfo += index == 0 ? `${key}:` : `;${key}:`;
        if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
        else
          value.fields.map((item) => {
            locateinfo += `${item.name},`;
          });
      });
      const PrepareNew = await preparNew({ locateinfo, tabID });
      PrepareNew.success && dispatch(setPrepareNew({ value: PrepareNew.data, idx: tab.idx, tabID }));
      dispatch(setLoading({ show: false, tabID }));
      // }
      dispatch(newRecord({ tabID }));
      dispatch(setObjectState({ value: "new", tabID }));
    } else {
      setShow(true);
    }
  };
  return (
    accessRights?.create == true && (
      <>
        <Dropdown.Item
          onClick={handleNew}
          disabled={objectState != "view"}
          className="d-flex align-items-center justify-content-start"
        >
          <div className="me-1 d-flex justify-content-start align-items-center">
            <TiPlus size="1.2rem" />
          </div>
          <span> {languages.generalText[currentLang].newRecord}</span>
        </Dropdown.Item>
        {show && (
          <ModalSaveRecord
            show={show}
            setShow={setShow}
            tabID={tabID}
            functionNo={() => {
              dispatch(newRecord({ tabID }));
              setShow(false);
            }}
          />
        )}
      </>
    )
  );
};

export default NewRecord;
