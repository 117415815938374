import React from "react";
import { BsSearch } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { MdArrowOutward } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteGridSelectorFields,
  deleteSelectorFields,
  setChange,
  setFilter,
  setGridChange,
  setGridSelectorFields,
  setLoading,
  setNewTab,
  setSelectorFields,
} from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import PickerSpinner from "../PickerSpinner";
import getSelectorData from "../../../services/getSelectorData";
import { calculate } from "../../../services/calculate";
import cleanEditor from "../../functions/cleanEditor";
import { getRootsByKey } from "../../../redux/selectors/roots";
import { setScreenFromJob } from "../../../redux/features/screens/screensSlice";
import { Button, ButtonGroup, FloatingLabel, Form, InputGroup, Modal } from "react-bootstrap";
import { HiX, HiCheck } from "react-icons/hi";
import { BsChevronDown } from "react-icons/bs";
import { languages } from "../../languages";

const S1Selector = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getRecData(state, tabID));
  const root = useSelector((state) => getRootsByKey(state, element.redirector));
  const currentGridLine = grid?.currentLine;
  const filters = useSelector((state) => getFilters(state, tabID));
  const currentLang = useSelector((state) => state.settings.language);

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const objectState = useSelector((state) => getObjectState(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const [pickerLoading, setPickerLoading] = React.useState(false);
  const [selectorData, setSelectorData] = React.useState({});
  const [error, setError] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState("");
  const [selectedRecord, setSelectedRecord] = React.useState("");

  const [returnIndex, setReturnIndex] = React.useState(-1);
  const [keyIndex, setKeyIndex] = React.useState(-1);

  const [editor, setEditor] = React.useState(element.editor);

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const keyNameFromLine = key.split("_")[0];
  //Εάν έχει _ στο όνομα πχ ITELINES.MTRL_ITEM_NAME
  const keyname = editor.split("|")[2];
  const tablename = editor.split("|")[1];
  const underScore1 = key.split("_")[0];
  const underScore2 = tablename;
  const underScore3 = key.split("_")[key.split("_").length - 1];

  const model =
    screenState == "form"
      ? formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0]
      : { editor: element.editor };

  const searchRef = React.useRef(null);
  const tableRef = React.useRef(null);

  const [searchText, setSearchText] = React.useState("");
  const [timeoutId, setTimeoutId] = React.useState(null);

  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState("");

  const handleCalculate = async (table, key, val) => {
    if (grid) {
      if (val == "") {
        currentGridLine.line &&
          Object.keys(currentGridLine.line).forEach((x) => {
            if (x.indexOf("_") > -1) {
              if (x.split("_")[0] == underScore1 && x.split("_")[1] == underScore2)
                dispatch(setGridChange({ tabID, name: grid.model, key: x, value: "" }));
            }
          });
      }
      dispatch(setGridChange({ tabID, name: grid.model, key: keyname, value: val }));
    } else {
      if (val != value) {
        // Αν αλλαξει η τιμή τοτε όσα πεδια της προβολης έχουν στον editor αυτο το πεδιο τα μηδενίζω
        const relativeFields = formDesign.relativeFields?.[table]?.[key] ?? [];
        relativeFields.forEach((field) => {
          dispatch(setChange({ tabID, table: field.table, key: field.key, value: "" }));
        });
      }
      element.maskEnabled && dispatch(setChange({ tabID, table, key, value: val }));
      model?.updates && (await calculate(tabID, objectState));
    }
  };

  const handleRedirect = () => {
    var id = "";
    const commandParams = {
      list: root?.list,
      form: root?.form,
      rj: root?.rj,
      AppId: process.env.REACT_APP_SOFTONE_APPID,
      autoLocate: true,
    };

    if (grid) {
      id = currentGridLine?.line?.[underScore1];
    } else {
      if (screenState == "dialog") {
        id = filters?.data?.[table]?.[underScore1];
      } else {
        id = data?.[table]?.[0]?.[underScore1];
      }
    }

    const value = {
      cmd: "EditMaster",
      text: root?.text,
      idx: `redirector_${element.redirector}`,
      autoLocateID: id,
      command: root?.command ?? element.redirector,
      commandParams,
      screenState: {},
      isWindow: false,
      objectState: "view",
      wasInTab: tabID,
    };
    dispatch(
      setScreenFromJob({
        key: `redirector_${element.redirector}`,
        value,
      })
    );
    dispatch(setNewTab(value));
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setSelectedRow(filters?.data?.[table]?.[underScore1] ?? "");
      if (filters?.data?.[table]?.[key]) {
        var value = "";
        if (element.selectorVF !== "") {
          const selectorArray = element.selectorVF.split("|");
          const splitted = selectorArray[selectorArray.length - 1].split(".");
          const tableName = splitted[0];
          const fieldName = splitted[1];
          value = filters?.data?.[tableName]?.[fieldName] ?? "";
        } else {
          value = filters?.data?.[table]?.[key] ?? "";
        }
        setValue(value);
      } else {
        setValue("");
      }
    }
  }, [filters?.data?.[table]?.[key]]);

  React.useEffect(() => {
    if (screenState == "form" && !grid) {
      if (data?.[table]?.[0]?.[key]) {
        var value = "";
        if (element.selectorVF !== "") {
          const selectorArray = element.selectorVF.split("|");
          const splitted = selectorArray[selectorArray.length - 1].split(".");
          const tableName = splitted[0];
          const fieldName = splitted[1];
          value = data?.[tableName]?.[0]?.[fieldName] ?? "";
        } else {
          value = data?.[table]?.[0]?.[key] ?? "";
        }
        setValue(value);
      } else {
        setValue("");
      }
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if ((currentGridLine?.line?.[keyNameFromLine] || currentGridLine?.line?.[keyNameFromLine] == "") && grid) {
      var value = "";
      if (element.selectorVF !== "") {
        const selectorArray = element.selectorVF.split("|");
        const splitted = selectorArray[selectorArray.length - 1].split(".");
        const fieldName = splitted[1];
        value = currentGridLine?.line?.[fieldName] ?? "";
      } else {
        value = currentGridLine?.line?.[key] ?? "";
      }
      setValue(value);
    }
  }, [currentGridLine?.line?.[keyNameFromLine]]);

  React.useEffect(() => {
    setSearchText("");
    if (openPicker) {
      if (screenState == "dialog") {
        setSelectedRow(filters?.data?.[table]?.[underScore1] ?? "");
      } else {
        if (!grid) {
          setSelectedRow(data?.[table]?.[0]?.[underScore1] ?? "");
        } else {
          setSelectedRow(currentGridLine?.line?.[underScore1] ?? "");
        }
      }
    }
  }, [openPicker]);

  const handlePicker = async (e) => {
    e.stopPropagation();
    if (!openPicker) {
      setOpenPicker(true);
      setPickerLoading(true);
      const editor = cleanEditor(tabID, model, element, grid);
      setEditor(editor);
      const GetSelectorData = await getSelectorData({ editor, value: "", tabID });
      if (GetSelectorData.success) {
        console.log(element);
        console.log(GetSelectorData);
        if (element.selectorVF != "") {
          var vf = element.selectorVF.split("|")[element.selectorVF.split("|").length - 1].split(".")[1];
          setReturnIndex(
            GetSelectorData.model.findIndex((object) => object.name == vf.split("_")[vf.split("_").length - 1])
          );
        } else {
          if (key.indexOf("_") > 0) {
            setReturnIndex(
              GetSelectorData.model.findIndex((object) => object.name == key.split("_")[key.split("_").length - 1])
            );
          } else {
            if (GetSelectorData.model.findIndex((object) => object.name == element.editor.split("|")[2]) == -1) {
              setReturnIndex(0);
            } else {
              setReturnIndex(GetSelectorData.model.findIndex((object) => object.name == element.editor.split("|")[2]));
            }
          }
        }
        if (GetSelectorData.model.findIndex((object) => object.name == element.editor.split("|")[2]) == -1) {
          setKeyIndex(0);
        } else {
          setKeyIndex(GetSelectorData.model.findIndex((object) => object.name == element.editor.split("|")[2]));
        }
        setSelectorData(GetSelectorData);
      } else {
        setSelectorData({ success: false });
        setError(GetSelectorData.error);
      }
      setPickerLoading(false);
    } else {
      setOpenPicker(false);
    }
  };

  const handleClear = () => {
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else if (screenState == "form") {
      if (!grid) {
        dispatch(deleteSelectorFields({ tabID, table, key: underScore1 }));
        handleCalculate(table, key, "");
      } else {
        dispatch(deleteGridSelectorFields({ tabID, name: grid.model, key: underScore1 }));
      }
    }
    setSelectedRecord("");
    setSelectedRow("");
  };

  const handleClickOnRow = async (row) => {
    if (underScore3 == "ZIP") {
      setSelectedRow(row);
      setSelectedRecord(selectorData.data[row]);
    } else {
      setSelectedRow(row[keyIndex]);
      setSelectedRecord(row);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    if (selectorData?.success) {
      clearTimeout(timeoutId);
      setTimeoutId(
        setTimeout(
          async () => {
            if (value.length >= 3 || value == "") {
              setPickerLoading(true);
              try {
                const GetSelectorData = await getSelectorData({ value, editor, tabID });
                if (GetSelectorData.success) {
                  setSelectorData(GetSelectorData);
                } else {
                  setSelectorData({ success: false });
                  setError(GetSelectorData.error);
                }
              } catch (error) {
                console.log(error);
              } finally {
                setPickerLoading(false);
              }
            }
          },
          value == "" ? 0 : 1000
        )
      );
    }
  };

  const handleAccept = () => {
    if (selectedRecord != "") {
      if (screenState == "dialog") {
        if (element.selectorVF != "") {
          const selectorArray = element.selectorVF.split("|");
          const splitted = selectorArray[selectorArray.length - 1].split(".");
          const tableName = splitted[0];
          const fieldName = splitted[1];
          dispatch(setFilter({ tabID, table: tableName, key: fieldName, value: selectedRecord[returnIndex] }));
        }
        dispatch(setFilter({ tabID, table, key, value: selectedRecord[keyIndex] }));
      } else if (screenState == "form") {
        var fields = {};
        selectorData.model.map((item, index) => {
          fields[`${underScore1}_${underScore2}_${item.name}`] = selectedRecord[index];
        });
        if (!grid) {
          dispatch(deleteSelectorFields({ tabID, table, key: underScore1 }));
          dispatch(setSelectorFields({ tabID, table, key: underScore1, value: selectedRecord[keyIndex], fields }));
          handleCalculate();
        } else {
          dispatch(deleteGridSelectorFields({ tabID, name: grid.model, key: underScore1 }));
          dispatch(
            setGridSelectorFields({
              tabID,
              name: grid.model,
              key: underScore1,
              value: selectedRecord[keyIndex],
              fields,
            })
          );

          // dispatch(
          //   setGridChange({
          //     tabID,
          //     name: grid.model,
          //     key,
          //     value: selectedRecord[returnIndex],
          //   })
          // );
          // dispatch(
          //   setGridChange({
          //     tabID,
          //     name: grid.model,
          //     key: keyNameFromLine,
          //     value: selectedRecord[keyIndex],
          //   })
          // );
          // selectorData.model.map((item, index) => {
          //   dispatch(
          //     setGridChange({
          //       tabID,
          //       name: grid.model,
          //       key: `${underScore1}_${underScore2}_${item.name}`,
          //       value: selectedRecord[index],
          //     })
          //   );
          // });
        }
      }
    }
    setOpenPicker(false);
  };

  const handleChange = (value) => {
    if (screenState == "dialog") {
      if (filters?.[table]?.[key] != value) {
        dispatch(setFilter({ tabID, table, key, value: value }));
      }
    } else if (screenState == "form") {
      if (!grid) {
        // if (element.selectorVF != "") {
        //   const selectorArray = element.selectorVF.split("|");
        //   const splitted = selectorArray[selectorArray.length - 1].split(".");
        //   const tableName = splitted[0];
        //   const fieldName = splitted[1];
        //   dispatch(setChange({ tabID, table: tableName, key: fieldName, value: selectedRecord[returnIndex] }));
        // }
        handleCalculate(table, key, value);
      } else {
        dispatch(
          setGridChange({
            tabID,
            name: grid.model,
            key,
            value: selectedRecord[returnIndex],
          })
        );
        dispatch(
          setGridChange({
            tabID,
            name: grid.model,
            key: keyNameFromLine,
            value: selectedRecord[keyIndex],
          })
        );
        selectorData.model.map((item, index) => {
          dispatch(
            setGridChange({
              tabID,
              name: grid.model,
              key: `${underScore1}_${underScore2}_${item.name}`,
              value: selectedRecord[index],
            })
          );
        });
      }
    }
  };

  return (
    <div
      className="s1selector w-100"
      style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}
      hidden={!element.visible}
    >
      <InputGroup className={`crm-floating-with-icon${element.required && value == "" ? " input-required" : ""}`}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{
            whiteSpace: "nowrap",
          }}
          className={`w-100${
            value != "" && root && element.redirector && !element.maskEnabled && element.redirector != ""
              ? " can-redirect"
              : ""
          }`}
        >
          <Form.Control
            role="button"
            className={`w-100 ps-1 border-0${
              element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
            }`}
            disabled={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            readOnly={!element.maskEnabled}
            value={value}
            name={element.name}
            placeholder={element.caption}
            onChange={(e) => (element.maskEnabled ? setValue(e.target.value) : undefined)}
            onBlur={(e) => (element.maskEnabled ? handleChange(e.target.value) : undefined)}
            onFocus={(e) => (element.maskEnabled ? undefined : e.target.blur())}
            onClick={(e) =>
              !(
                element.readOnly ||
                (screenState == "form" && commandParams.readonly) ||
                (screenState == "form" && objectState == "view")
              ) &&
              !element.maskEnabled &&
              handlePicker(e)
            }
          />
        </FloatingLabel>
        {value != "" &&
          !element.readOnly &&
          ((screenState == "form" && objectState != "view") || screenState == "dialog") && (
            <div className="d-flex align-items-center justify-content-center" onClick={handleClear}>
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
        {value != "" && root && element.redirector && !element.maskEnabled && element.redirector != "" && (
          <div className="d-flex align-items-center justify-content-center" onClick={handleRedirect}>
            <MdArrowOutward size="1.75rem" color="var(--bs-primary)" />
          </div>
        )}
        {element?.maskEnabled && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={(e) =>
              !(
                element.readOnly ||
                (screenState == "form" && commandParams.readonly) ||
                (screenState == "form" && objectState == "view")
              ) && handlePicker(e)
            }
          >
            <BsChevronDown
              className={
                element.readOnly ||
                (screenState == "form" && commandParams.readonly) ||
                (screenState == "form" && objectState == "view")
                  ? "disabled"
                  : ""
              }
              color="var(--bs-primary)"
              size="20px"
            />
          </div>
        )}
      </InputGroup>
      {openPicker && (
        <Modal
          show={openPicker}
          fullscreen
          animation={false}
          className="d-flex align-items-end"
          dialogClassName="p-3"
          contentClassName="rounded overflow-hidden shadow"
        >
          {pickerLoading && <PickerSpinner />}
          <div className="picker-search">
            <div className="ms-1 d-flex justify-content-center align-items-center">
              <BsSearch color="var(--bs-primary)" />
            </div>
            <input
              ref={searchRef}
              style={{ height: "40px" }}
              placeholder="Αναζήτηση"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {searchText != "" && (
              <div
                onClick={() => {
                  handleSearch("");
                }}
              >
                <TiDeleteOutline size="30px" role="button" />
              </div>
            )}
          </div>
          <div
            className="input-picker w-100 h-100"
            style={{
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {selectorData.success ? (
              <div className="input-picker-table w-100" ref={tableRef}>
                {Object.keys(selectorData.data).map((key, index) => {
                  return (
                    <div
                      key={index}
                      className={`input-picker-table-row${
                        underScore3 != "ZIP"
                          ? selectedRow == selectorData.data[key][keyIndex]
                            ? " input-picker-table-row-selected hovered"
                            : ""
                          : selectedRow == index
                          ? " input-picker-table-row-selected hovered"
                          : ""
                      }`}
                      onClick={() => handleClickOnRow(underScore3 != "ZIP" ? selectorData.data[key] : index)}
                    >
                      {selectorData.model.slice(1).map((item, i) => {
                        return (
                          <span className="" key={i}>
                            {selectorData.data[key][i + 1]}
                          </span>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100">{error}</div>
            )}
          </div>
          <Modal.Footer className="picker-buttons">
            <ButtonGroup>
              <Button
                variant="outline-secondary"
                className="rounded-0"
                onClick={() => {
                  setOpenPicker(false);
                }}
              >
                <HiX size={25} color="var(--bs-danger)" className="me-2" />
                <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                  {languages.generalText[currentLang].closing}
                </div>
              </Button>
              <Button className="rounded-0" variant="outline-secondary" onClick={handleAccept}>
                <HiCheck size={25} color="var(--bs-success)" className="me-2" />
                <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                  {languages.generalText[currentLang].apply}
                </div>
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default S1Selector;
