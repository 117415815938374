import React from "react";
import { BsChevronDown, BsSearch } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/features/ui/uiSlice";
import getSelectorData from "../../../services/getSelectorData";
import { getCommandParams, getFilters, getObjectState } from "../../../redux/selectors";
import PickerSpinner from "../PickerSpinner";
import { Button, ButtonGroup, FloatingLabel, Form, InputGroup, Modal } from "react-bootstrap";
import { HiX, HiCheck } from "react-icons/hi";
import { languages } from "../../languages";

const S1MultiSelect = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => getFilters(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const currentLang = useSelector((state) => state.settings.language);

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const [pickerLoading, setPickerLoading] = React.useState(false);
  const [selectorData, setSelectorData] = React.useState({});
  const [error, setError] = React.useState("");
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [returnIndex, setReturnIndex] = React.useState(-1);

  const searchRef = React.useRef(null);
  const tableRef = React.useRef(null);

  const [searchText, setSearchText] = React.useState("");
  const [hoverIndex, setHoverIndex] = React.useState(-1);

  const [isFocused, setIsFocused] = React.useState(false);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(filters?.data?.[table]?.[key] ?? "");
      // setSelectedRows((filters?.data?.[table]?.[key] ?? "").join(","));
    }
  }, [filters?.data?.[table]?.[key]]);

  React.useEffect(() => {
    setSelectedRows(value != "" ? value.split(",") : []);
    setHoverIndex(-1);
    setSearchText("");
  }, [openPicker]);

  const handlePicker = async (e) => {
    e.stopPropagation();
    setOpenPicker((x) => !x);
    if (!openPicker) {
      setIsFocused(true);
      setPickerLoading(true);
      const EditorArguments = Array.from(element.editor.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
      var editor = element.editor;
      EditorArguments.forEach((y) => {
        var splitted = y.split(".");
        var tableName = splitted[0];
        var fieldName = splitted[1];
        var replaceValue =
          fieldName == "COMPANY" ? ":X.SYS.MAINCOMPANY" : filters?.data?.[tableName]?.[fieldName].split("|")[0] ?? "";
        editor = editor.replaceAll(`[${y}]`, replaceValue);
      });
      const GetSelectorData = await getSelectorData({ editor, value: "", tabID });
      if (GetSelectorData.success) {
        if (element.selectorVF != "") {
          setReturnIndex(
            GetSelectorData.model.findIndex((object) => object.name == element.selectorVF.split("|")[0].split(".")[1])
          );
        } else {
          setReturnIndex(
            GetSelectorData.model.findIndex(
              (object) => object.name == element.editor.split("|")[2] || object.name == element.editor.split("|")[1]
            )
          );
        }
        setSelectorData(GetSelectorData);
      } else {
        setSelectorData({ success: false });
        setError(GetSelectorData.error);
      }
      setPickerLoading(false);
    } else {
      setIsFocused(false);
    }
  };

  const handleClear = () => {
    setSelectedRows([]);
    setValue("");
    setOpenPicker(false);
    setIsFocused(false);
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else {
      console.log("dispatch se " + screenState);
    }
  };

  const handleClickOnRow = (el) => {
    const SelectedArray = [...selectedRows];
    if (SelectedArray.indexOf(el) >= 0) {
      SelectedArray.splice(SelectedArray.indexOf(el), 1);
    } else {
      SelectedArray.push(el);
    }
    setSelectedRows(SelectedArray);
  };

  const handleAccept = () => {
    setValue(selectedRows.join(","));
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: selectedRows.join(",") }));
    } else {
      console.log("dispatch se " + screenState);
    }
    setOpenPicker(false);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    if (selectorData?.success) {
      var filter, td, i, txtValue;
      var is = [];
      filter = value.toUpperCase();
      td = tableRef.current.querySelectorAll(".searchable");
      for (i = 0; i < td.length; i++) {
        td[i].parentElement.style.display = "none";
        txtValue = td[i].textContent || td[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          is.push(td[i].parentElement);
        } else {
          td[i].parentElement.style.display = "none";
        }
        is.forEach((t) => (t.style.display = ""));
      }
    }
  };

  return (
    <div
      className="s1multiselect w-100"
      style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}
      hidden={!element.visible}
    >
      <InputGroup className={`crm-floating-with-icon${element.required && value == "" ? " input-required" : ""}`}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            role="button"
            className={`w-100 ps-1 border-0${
              element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
            }`}
            disabled={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            readOnly={true}
            value={value}
            name={element.name}
            placeholder={element.caption}
            onFocus={(e) => e.target.blur()}
            onClick={(e) =>
              !(
                element.readOnly ||
                (screenState == "form" && commandParams.readonly) ||
                (screenState == "form" && objectState == "view")
              ) && handlePicker(e)
            }
          />
        </FloatingLabel>
        {value != "" &&
          !element.readOnly &&
          ((screenState == "form" && objectState != "view") || screenState == "dialog") && (
            <div className="d-flex align-items-center justify-content-center" onClick={handleClear}>
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
      </InputGroup>
      {openPicker && (
        <Modal
          show={openPicker}
          fullscreen
          animation={false}
          className="d-flex align-items-end"
          dialogClassName="p-3"
          contentClassName="rounded overflow-hidden shadow"
        >
          {pickerLoading && <PickerSpinner />}
          <div className="picker-search">
            <div className="ms-1 d-flex justify-content-center align-items-center">
              <BsSearch color="var(--bs-primary)" />
            </div>
            <input
              ref={searchRef}
              style={{ height: "40px" }}
              placeholder="Αναζήτηση"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {searchText != "" && (
              <div
                onClick={() => {
                  handleSearch("");
                }}
              >
                <TiDeleteOutline size="30px" role="button" />
              </div>
            )}
          </div>
          <div className="input-picker w-100 h-100" style={{ overflow: "auto" }}>
            {selectorData?.success ? (
              <div className="input-picker-table w-100" ref={tableRef}>
                {selectorData.data.map((el, index) => {
                  return (
                    <div
                      key={index}
                      className={`input-picker-table-row${
                        selectedRows.indexOf(el?.[0]) > -1 ? " input-picker-table-row-selected" : ""
                      }`}
                      onClick={() => handleClickOnRow(el[0])}
                    >
                      {el.map((item, index) => {
                        return (
                          <span className="searchable" key={index}>
                            {item}
                          </span>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100">{selectorData.error}</div>
            )}
          </div>
          <Modal.Footer className="picker-buttons">
            <ButtonGroup>
              <Button
                variant="outline-secondary"
                className="rounded-0"
                onClick={() => {
                  setOpenPicker(false);
                }}
              >
                <HiX size={25} color="var(--bs-danger)" className="me-2" />
                <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                  {languages.generalText[currentLang].closing}
                </div>
              </Button>
              <Button className="rounded-0" variant="outline-secondary" onClick={handleAccept}>
                <HiCheck size={25} color="var(--bs-success)" className="me-2" />
                <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                  {languages.generalText[currentLang].apply}
                </div>
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default S1MultiSelect;
