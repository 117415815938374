import React from "react";
import { useSelector } from "react-redux";
import { getTabIdx } from "../../../../redux/selectors";
import { getItemData, getItemQty } from "../../../../redux/selectors/showRooms";
import { useDispatch } from "react-redux";
import { setItemQty } from "../../../../redux/features/showRooms/showRoomsSlice";

const Qty = ({ tabID, mtrl, row }) => {
  const dispatch = useDispatch();
  const idx = useSelector((state) => getTabIdx(state, tabID));
  const itemQty = useSelector((state) => getItemQty(state, idx, mtrl));
  const itemData = useSelector((state) => getItemData(state, idx, tabID));
  const [value, setValue] = React.useState(0);

  React.useEffect(() => setValue(itemQty), [itemQty]);

  const handleBlur = (val) => {
    var data = {};
    if (!itemData) {
      Object.keys(row).map((key) => {
        if (key != "FLD-1" && key.startsWith("ITEM_") && !key.startsWith("ITEM_MTRL_")) {
          const newKey = `MTRL_${key}`;
          data[newKey] = row[key];
        } else if (key.startsWith("ITEM_MTRL_")) {
          data[key.slice(5)] = row[key];
        }
      });
      data.MTRL = mtrl;
    } else {
      data = itemData;
    }

    dispatch(setItemQty({ idx, mtrl, value: val, data, row }));
  };

  return (
    <div style={{ width: "25%", overflow: "hidden", borderBottom: "1px solid var(--bs-border-color)" }}>
      <input
        value={value}
        inputMode="decimal"
        onChange={(e) => setValue(e.target.value)}
        onBlur={(e) => handleBlur(e.target.value)}
        style={{ border: "none", outline: "none", width: "-webkit-fill-available", textAlign: "center" }}
      />
    </div>
  );
};

export default Qty;
