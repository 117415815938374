export const _addDetailGrid = (state, action) => {
  if (state.tabs[action.payload.tabID])
    state.tabs[action.payload.tabID].grids = {
      ...state.tabs[action.payload.tabID]?.grids,
      [action.payload.value.model]: action.payload.value,
    };
};

export const _setActiveDetailLine = (state, action) => {
  if (state.tabs[action.payload.tabID])
    state.tabs[action.payload.tabID].grids[action.payload.name] = {
      ...state.tabs[action.payload.tabID].grids[action.payload.name],
      activeLine: action.payload.line,
    };
};

export const _setCurrentDetailLine = (state, action) => {
  if (state.tabs[action.payload.tabID])
    state.tabs[action.payload.tabID].grids[action.payload.name] = {
      ...state.tabs[action.payload.tabID].grids[action.payload.name],
      currentLine: action.payload.value,
    };
};

export const _setGridChange = (state, action) => {
  if (state.tabs[action.payload.tabID])
    state.tabs[action.payload.tabID].grids[action.payload.name].currentLine = {
      ...state.tabs[action.payload.tabID].grids[action.payload.name]?.currentLine,
      line: {
        ...state.tabs[action.payload.tabID].grids[action.payload.name]?.currentLine?.line,
        [action.payload.key]: action.payload.value,
      },
    };
};

export const _deleteGridSelectorFields = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].grids[action.payload.name].currentLine = {
      ...state.tabs[action.payload.tabID].grids[action.payload.name]?.currentLine,
      line: {
        ...state.tabs[action.payload.tabID].grids[action.payload.name]?.currentLine?.line,
        [action.payload.key]: "",
      },
    };
    Object.keys(state.tabs[action.payload.tabID].grids[action.payload.name]?.currentLine?.line).map((currentKey) => {
      if (currentKey.startsWith(`${action.payload.key}_`)) {
        state.tabs[action.payload.tabID].grids[action.payload.name].currentLine.line[currentKey] = "";
      }
    });
  }
};

export const _setGridSelectorFields = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].grids[action.payload.name].currentLine = {
      ...state.tabs[action.payload.tabID].grids[action.payload.name]?.currentLine,
      line: {
        ...state.tabs[action.payload.tabID].grids[action.payload.name]?.currentLine?.line,
        [action.payload.key]: action.payload.value,
      },
    };
    Object.keys(action.payload.fields).map((key) => {
      state.tabs[action.payload.tabID].grids[action.payload.name].currentLine.line[key] = action.payload.fields[key];
    });
  }
};

export const _triggerGridChange = (state, action) => {
  var gridLine = [...state.tabs[action.payload.tabID]?.buffData?.[action.payload.name]] ?? [];
  var newArry = gridLine.reduce((acum, cur) => [...acum, { LINENUM: cur.LINENUM }], []);

  state.tabs[action.payload.tabID].newData = {
    ...state.tabs[action.payload.tabID]?.newData,
    [action.payload.name]: newArry,
  };
};

export const _triggerGridChangeFromView = (state, action) => {
  var gridLine = [...state.tabs[action.payload.tabID]?.getData?.data?.[action.payload.name]] ?? [];
  var newArry = gridLine.reduce((acum, cur) => [...acum, { LINENUM: cur.LINENUM }], []);

  state.tabs[action.payload.tabID].newData = {
    [action.payload.name]: newArry,
  };
};

export const _updateLineOfGrid = (state, action) => {
  const copyBuff = [...state.tabs[action.payload.tabID]?.buffData?.[action.payload.name]] ?? [];
  const copyNew = [...state.tabs[action.payload.tabID]?.newData?.[action.payload.name]] ?? [];

  copyBuff[action.payload.gridIndex] = action.payload.value;
  copyNew[action.payload.gridIndex] = action.payload.value;

  state.tabs[action.payload.tabID] = {
    ...state.tabs[action.payload.tabID],
    newData: {
      ...state.tabs[action.payload.tabID]?.newData,
      [action.payload.name]: copyNew,
    },
    buffData: {
      ...state.tabs[action.payload.tabID]?.buffData,
      [action.payload.name]: copyBuff,
    },
    grids: {
      ...state.tabs[action.payload.tabID]?.grids,
      [action.payload.name]: {
        ...state.tabs[action.payload.tabID]?.grids?.[action.payload.name],
        currentLine: undefined,
      },
    },
  };
};

export const _addLineToGrid = (state, action) => {
  var NEXTLINENUM = 0;
  var maxLine = 0;
  if (state.tabs[action.payload.tabID]?.buffData?.[action.payload.name]) {
    maxLine = state.tabs[action.payload.tabID]?.buffData?.[action.payload.name].reduce((max, item) => {
      return parseInt(item.LINENUM) > max ? parseInt(item.LINENUM) : max;
    }, 0);
  } else {
    maxLine = 9000000;
  }

  NEXTLINENUM = maxLine < 9000001 ? 9000000 + maxLine : maxLine;
  const copyBuff = [...state.tabs[action.payload.tabID]?.buffData?.[action.payload.name]] ?? [];
  const copyNew = [...state.tabs[action.payload.tabID]?.newData?.[action.payload.name]] ?? [];

  NEXTLINENUM++;
  copyBuff.push({ ...action.payload.value, LINENUM: NEXTLINENUM });
  copyNew.push({ ...action.payload.value, LINENUM: NEXTLINENUM });

  state.tabs[action.payload.tabID] = {
    ...state.tabs[action.payload.tabID],
    newData: {
      ...state.tabs[action.payload.tabID]?.newData,
      [action.payload.name]: copyNew,
    },
    buffData: {
      ...state.tabs[action.payload.tabID]?.buffData,
      [action.payload.name]: copyBuff,
    },
    grids: {
      ...state.tabs[action.payload.tabID]?.grids,
      [action.payload.name]: {
        ...state.tabs[action.payload.tabID]?.grids?.[action.payload.name],
        currentLine: undefined,
      },
    },
  };
};

export const _addMultipleLinesToGrid = (state, action) => {
  var NEXTLINENUM = 0;
  var maxLine = 0;
  if (state.tabs[action.payload.tabID]?.buffData?.[action.payload.name]) {
    maxLine = state.tabs[action.payload.tabID]?.buffData?.[action.payload.name].reduce((max, item) => {
      return parseInt(item.LINENUM) > max ? parseInt(item.LINENUM) : max;
    }, 0);
  } else {
    maxLine = 9000000;
  }

  NEXTLINENUM = maxLine < 9000001 ? 9000000 + maxLine : maxLine;
  const copyBuff = [...state.tabs[action.payload.tabID]?.buffData?.[action.payload.name]] ?? [];
  const copyNew = [...state.tabs[action.payload.tabID]?.newData?.[action.payload.name]] ?? [];

  const lines = [...action.payload.value];
  for (const line of lines) {
    NEXTLINENUM++;
    line.LINENUM = NEXTLINENUM;
  }

  copyBuff.push(...lines);
  copyNew.push(...lines);

  state.tabs[action.payload.tabID] = {
    ...state.tabs[action.payload.tabID],
    newData: {
      ...state.tabs[action.payload.tabID]?.newData,
      [action.payload.name]: copyNew,
    },
    buffData: {
      ...state.tabs[action.payload.tabID]?.buffData,
      [action.payload.name]: copyBuff,
    },
    grids: {
      ...state.tabs[action.payload.tabID]?.grids,
      [action.payload.name]: {
        ...state.tabs[action.payload.tabID]?.grids?.[action.payload.name],
        currentLine: undefined,
      },
    },
  };
};

export const _deleteLineFromGrid = (state, action) => {
  const copyBuff = [...state.tabs[action.payload.tabID]?.buffData?.[action.payload.name]] ?? [];
  const copyNew = [...state.tabs[action.payload.tabID]?.newData?.[action.payload.name]] ?? [];

  copyBuff.splice(action.payload.lineIndex, 1);
  copyNew.splice(action.payload.lineIndex, 1);

  state.tabs[action.payload.tabID] = {
    ...state.tabs[action.payload.tabID],
    newData: {
      ...state.tabs[action.payload.tabID]?.newData,
      [action.payload.name]: copyNew,
    },
    buffData: {
      ...state.tabs[action.payload.tabID]?.buffData,
      [action.payload.name]: copyBuff,
    },
  };
};
