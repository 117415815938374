export const _addItemQty = (state, action) => {
  var qty = state[action.payload.idx]?.cart?.[action.payload.mtrl]?.QTY ?? 0;
  const data = state[action.payload.idx]?.cart?.[action.payload.mtrl]?.data ?? action.payload.data;
  const row = state[action.payload.idx]?.cart?.[action.payload.mtrl]?.row ?? action.payload.row;

  state[action.payload.idx] = {
    ...state[action.payload.idx],
    cart: {
      ...state[action.payload.idx]?.cart,
      [action.payload.mtrl]: {
        ...state[action.payload.idx]?.cart?.[action.payload.mtrl],
        QTY: qty + 1,
        data,
        row,
      },
    },
  };
};

export const _substractItemQty = (state, action) => {
  var qty = state[action.payload.idx]?.cart?.[action.payload.mtrl]?.QTY ?? 0;
  const data = state[action.payload.idx]?.cart?.[action.payload.mtrl]?.data ?? action.payload.data;
  const row = state[action.payload.idx]?.cart?.[action.payload.mtrl]?.row ?? action.payload.row;

  if (qty > 0) {
    qty--;
  }
  state[action.payload.idx] = {
    ...state[action.payload.idx],
    cart: {
      ...state[action.payload.idx]?.cart,
      [action.payload.mtrl]: {
        ...state[action.payload.idx]?.cart?.[action.payload.mtrl],
        QTY: qty,
        data,
        row,
      },
    },
  };
};

export const _setItemQty = (state, action) => {
  var qty = action.payload.value == "" ? 0 : parseFloat(action.payload.value);
  const data = state[action.payload.idx]?.cart?.[action.payload.mtrl]?.data ?? action.payload.data;
  const row = state[action.payload.idx]?.cart?.[action.payload.mtrl]?.row ?? action.payload.row;

  state[action.payload.idx] = {
    ...state[action.payload.idx],
    cart: {
      ...state[action.payload.idx]?.cart,
      [action.payload.mtrl]: {
        ...state[action.payload.idx]?.cart?.[action.payload.mtrl],
        QTY: qty,
        data,
        row,
      },
    },
  };
};

export const _deleteItemFromCart = (state, action) => {
  delete state[action.payload.idx].cart[action.payload.mtrl];
};
