import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setTab } from "../../../redux/features/ui/uiSlice";
import { toogleMenu } from "../../../redux/features/modals/modalsSlice";

const HomeAction = () => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        dispatch(toogleMenu());
        dispatch(setTab(-1));
      }}
    >
      <AiOutlineHome size="1.5rem" />
    </div>
  );
};

export default HomeAction;
