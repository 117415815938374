import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { set, setDarkMode } from "../../../redux/features/settings/settingsSlice";
import { getCurrentTabIndex } from "../../../redux/selectors";
import { languages } from "../../languages";
import CheckBox from "../custom inputs/CheckBox";
import IntegerInput from "../custom inputs/IntegerInput";

const App = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.app);
  const darkMode = useSelector((state) => state.settings.darkMode);
  const tabID = useSelector((state) => getCurrentTabIndex(state));
  const curLang = useSelector((state) => state.settings.language);

  const [confirmExit, setConfirmExit] = React.useState(settings.confirmExit);
  const [browserSearchActive, setBrowserSearchActive] = React.useState(settings.browserSearchActive);
  const [askForDeleteInGrid, setAskForDeleteInGrid] = React.useState(settings.askForDeleteInGrid);
  const [giveNameToUploads, setGiveNameToUploads] = React.useState(settings.giveNameToUploads);
  const [enablePortal, setEnablePortal] = React.useState(settings.enablePortal);
  const [dark, setDark] = React.useState(settings.darkMode);
  const inputs = [
    {
      label: languages.settings[curLang].browserLimit,
      type: "int",
      name: "browserLimit",
    },
    {
      label: languages.settings[curLang].gridVisibleLines,
      type: "int",
      name: "gridVisibleLines",
    },
    {
      label: languages.settings[curLang].confirmExit,
      type: "checkBox",
      name: "confirmExit",
      value: confirmExit,
      change: (value) => {
        setConfirmExit(value);
        dispatch(set({ key: "confirmExit", value }));
      },
    },
    {
      label: languages.settings[curLang].browserSearchActive,
      type: "checkBox",
      name: "browserSearchActive",
      value: browserSearchActive,
      change: (value) => {
        setBrowserSearchActive(value);
        dispatch(set({ key: "browserSearchActive", value }));
      },
    },
    {
      label: languages.settings[curLang].askForDeleteInGrid,
      type: "checkBox",
      name: "askForDeleteInGrid",
      value: askForDeleteInGrid,
      change: (value) => {
        setAskForDeleteInGrid(value);
        dispatch(set({ key: "askForDeleteInGrid", value }));
      },
    },
    {
      label: languages.settings[curLang].giveNameToUploads,
      type: "checkBox",
      name: "giveNameToUploads",
      value: giveNameToUploads,
      change: (value) => {
        setGiveNameToUploads(value);
        dispatch(set({ key: "giveNameToUploads", value }));
      },
    },
    {
      label: languages.settings[curLang].darkMode,
      type: "checkBox",
      name: "dark",
      value: dark,
      change: (value) => {
        setDark(value);
        dispatch(setDarkMode(value));
      },
    },
    {
      label: languages.settings[curLang].enablePortal,
      type: "checkBox",
      name: "enablePortal",
      value: enablePortal,
      change: (value) => {
        setEnablePortal(value);
        dispatch(set({ key: "enablePortal", value }));
      },
    },
  ];
  React.useEffect(() => {
    setConfirmExit(settings.confirmExit);
    setBrowserSearchActive(settings.browserSearchActive);
    setAskForDeleteInGrid(settings.askForDeleteInGrid);
    setGiveNameToUploads(settings.giveNameToUploads);
  }, [tabID]);

  React.useEffect(() => {
    setDark(darkMode);
  }, [darkMode]);
  return (
    <div style={{ flex: 1 }} className="d-flex flex-column align-items-center">
      {inputs.map((element, index) => {
        switch (element.type) {
          case "int":
            return (
              <IntegerInput
                key={index}
                change={element.change}
                label={element.label}
                name={element.name}
                value={element.value}
              />
            );
          case "checkBox":
            return (
              <CheckBox
                key={index}
                change={element.change}
                label={element.label}
                name={element.name}
                value={element.value}
              />
            );

          default:
            break;
        }
      })}
    </div>
  );
};

export default App;
