import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

const Record = ({ event, handleClick }) => {
  const calendarColor = useSelector((state) => state.settings.app?.calendarColor);
  return (
    <Card className="w-100 mt-2 p-2" onClick={() => handleClick(event.id)}>
      <span className="d-flex align-items-center">
        <span
          className="calendar-record-color"
          style={{ backgroundColor: event.color ?? calendarColor ?? "var(--bs-primary)" }}
        />
        {displayStart(event.start)}
        {displayEnd(event.end)}
      </span>
      <span className="text-truncate">{event.title}</span>
    </Card>
  );
};

export default Record;

const displayStart = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":");
  } else {
    return value;
  }
};

const displayEnd = (value) => {
  if (value != "") {
    return "-" + displayStart(value);
  } else {
    return value;
  }
};
