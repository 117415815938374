import React from "react";
import Logo from "./logo.svg";
const PowererBy = () => {
  return (
    <div className="position-absolute" style={{ right: "1rem", bottom: "1rem" }}>
      <img src={Logo} alt="Day-One" className="pe-none p-1" />
      <p className="font-weight-light text-center w-100 mb-0" style={{ fontSize: "0.6rem" }}>
        powered by
      </p>
    </div>
  );
};

export default PowererBy;
