import React from "react";
import { useDispatch, useSelector } from "react-redux";
import noImage from "../../../../assets/noimage.svg";
import { getSelectedRow, getTableData } from "../../../../redux/selectors";

const TBody = React.forwardRef(({ getTableBodyProps, prepareRow, page, tabID, HeadRef }, BodyRef) => {
  const dispatch = useDispatch();
  const selectedRow = useSelector((state) => getSelectedRow(state, tabID));
  const tableData = useSelector((state) => getTableData(state, tabID));

  const settings = useSelector((state) => state.settings);

  const handleRowClick = (index) => {
    // dispatch(setSelectedRow({ tabID, value: index }));
  };

  const handleRowDoubleClick = async (index) => {
    // var locateinfo = "";
    // var FormDesign = JSON.parse(JSON.stringify(formDesign ?? {}));
    // var noError = true;
    // const id = tableData[index].ZOOMINFO.substring(tableData[index].ZOOMINFO.indexOf(";") + 1);
    // dispatch(setSelectedRow({ value: index, tabID }));
    // if (browserInfo.browserOnly != true && commandParams.browseronly != 1) {
    //   if (accessRights.browserOnly != true) {
    //     dispatch(setLoading({ show: true, tabID }));
    //     if (!FormDesign?.success) {
    //       const fetchFormDesign = await servicesGetFormDesign(tabID);
    //       if (fetchFormDesign.success) {
    //         dispatch(setFormDesign({ value: fetchFormDesign, idx, tabID }));
    //         dispatch(setScreenState({ value: "form", tabID }));
    //         FormDesign = fetchFormDesign;
    //       } else {
    //         noError = false;
    //         dispatch(setLoading({ show: false, tabID }));
    //         console.log(fetchFormDesign);
    //       }
    //     } else {
    //       dispatch(setScreenState({ value: "form", tabID }));
    //     }
    //     if (noError) {
    //       Object.entries(FormDesign.model).forEach(([key, value], index) => {
    //         locateinfo += index == 0 ? `${key}:` : `;${key}:`;
    //         if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
    //         else
    //           value.fields.map((item) => {
    //             locateinfo += `${item.name},`;
    //           });
    //       });
    //       const data = await getData({ key: id, locateinfo, tabID });
    //       if (data.success) {
    //         dispatch(setGetData({ value: data, tabID }));
    //         dispatch(setLoading({ show: false, tabID }));
    //       } else {
    //         dispatch(setLoading({ show: false, tabID }));
    //       }
    //     }
    //   }
    // }
  };

  return (
    <div
      className="browser-table-tbody"
      {...getTableBodyProps()}
      ref={BodyRef}
      onScroll={() => {
        const { scrollLeft } = BodyRef.current;
        HeadRef.current.scrollLeft = scrollLeft;
      }}
    >
      {page.map((row, index) => {
        prepareRow(row);
        return (
          <React.Fragment key={index}>
            <div
              {...row.getRowProps()}
              onDoubleClick={() => settings.app.singleClickOnRecord != 1 && handleRowDoubleClick(row.index)}
              onClick={() => {
                handleRowClick(row.index);
                settings.app.singleClickOnRecord == 1 && handleRowDoubleClick(row.index);
              }}
              className={`browser-table-tr ${row.index == selectedRow ? "row-selected" : ""}`}
            >
              {row.cells.map((cell) => {
                return (
                  <div
                    className="browser-table-td text-truncate"
                    {...cell.getCellProps()}
                    style={{
                      ...cell.getCellProps().style,
                      textAlign: cell.column?.align ?? "left",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {convertCells(cell.value, cell.column)}
                  </div>
                );
              })}
            </div>
            {row.index == tableData.length - 1 && (
              <div {...row.getRowProps()} key={index + 1} className="sums">
                {row.cells.map((cell, i) => (
                  <div
                    className="browser-table-td text-truncate p-2"
                    {...cell.getCellProps()}
                    style={{
                      ...cell.getCellProps().style,
                      display: cell.column.hidden ? "none" : cell.getCellProps().style.display,
                      width: cell.column.width,
                      textAlign: cell.column?.align ?? "left",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {cell.column.Footer != "" && convertCells(cell.column.Footer, cell.column)}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
});

export default TBody;

const convertCells = (value, column) => {
  if (value == "") {
    return "";
  } else {
    switch (column.decs) {
      case 0:
      case -1: //STRINGLIST
        var index = value.indexOf("|");
        if (index > -1) {
          return value.substring(index + 1, value.length);
        } else {
          return value;
        }
      case 2: //FLOAT
        return parseFloat(value)
          .toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .replace("€", "");
      case 3: //FLOAT
        return parseFloat(value)
          .toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })
          .replace("€", "");
      case 10: //DATETIME
        var d = new Date(value);
        return (
          [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/") +
          " " +
          [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":")
        );
      case 11: //DATE
        var d = new Date(value);
        return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
      default:
        return value;
    }
  }
};
