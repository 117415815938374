import React from "react";
import { Alert, Button, FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TiDeleteOutline } from "react-icons/ti";
import login from "../../../services/login";
import { useDispatch } from "react-redux";
import { set as setLogin } from "../../../redux/features/login/loginSlice";
import PowererBy from "../../../assets/PowererBy";
import { setLoading } from "../../../redux/features/modals/modalsSlice";
import { useSelector } from "react-redux";
import { languages } from "../../languages";
import Language from "../../ui/custom inputs/Language";
import LoginError from "./LoginError";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLan = useSelector((state) => state.settings.language) ?? "ENG";

  const [connection, setConnection] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [hasError, setHasError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [wrongConnection, setWrongConnection] = React.useState(false);
  const [wrongPassUser, setWrongPassUser] = React.useState(false);
  const inputs = [
    {
      controlId: "connection",
      label: languages.login[userLan].server,
      type: "text",
      value: connection,
      inputMode: "",
      condition: wrongConnection,
      autoComplete: "off",
      function: setConnection,
    },
    {
      controlId: "username",
      label: languages.login[userLan].username,
      type: "text",
      value: username,
      inputMode: "",
      name: "username",
      condition: wrongPassUser,
      autoComplete: "username",
      function: setUsername,
    },
    {
      controlId: "password",
      label: languages.login[userLan].password,
      type: "password",
      value: password,
      inputMode: "",
      name: "password",
      condition: wrongPassUser,
      autoComplete: "current-password",
      function: setPassword,
    },
  ];

  const handleLogin = async (e) => {
    e.preventDefault();
    setHasError(false);
    dispatch(setLoading(true));
    setWrongConnection(false);
    setWrongPassUser(false);

    try {
      const Login = await login({
        url: `https://${connection}.oncloud.gr/s1services`,
        username,
        password,
        language: userLan,
      });
      if (Login.success) {
        dispatch(
          setLogin({
            username,
            password,
            sn: Login.sn,
            objs: Login.objs,
            clientID: Login.clientID,
          })
        );
        localStorage.setItem("lastLogin", JSON.stringify({ connection, username, password }));
        dispatch(setLoading(false));
        navigate("/authenticate");
      } else {
        setHasError(true);
        setErrorMessage(Login.error);
        if (Login.message === "Request failed with status code 404") {
          setWrongConnection(true);
          setErrorMessage(Login.message);
        } else if (Login.error == "Login fails due to invalid login credentials.") {
          setWrongPassUser(true);
        }
        dispatch(setLoading(false));
      }
    } catch (error) {
      console.log(error);
      setHasError(true);
      setErrorMessage(error);
      dispatch(setLoading(false));
    }
  };

  React.useEffect(() => {
    const previous = JSON.parse(localStorage.getItem("lastLogin"));
    setConnection(previous?.connection ?? "");
    setUsername(previous?.username ?? "");
    setPassword(previous?.password ?? "");
  }, []);

  return (
    <div className="login-screen">
      <div className="card">
        <Form onSubmit={handleLogin}>
          <Language />
          {inputs.map((item, index) => {
            return (
              <InputGroup
                className="crm-floating-with-icon my-1"
                style={{
                  borderBottom: item.condition ? "2px solid var(--bs-danger)" : "1px solid var(--bs-gray)",
                }}
                key={index}
              >
                <FloatingLabel
                  controlId={item.controlId}
                  label={item.label}
                  style={{ whiteSpace: "nowrap" }}
                  className="w-100"
                >
                  <Form.Control
                    className="crm-input ps-1 mt-1"
                    autoCapitalize="off"
                    placeholder={item.label}
                    type={item.type}
                    name={item.controlId}
                    inputMode={item.inputMode}
                    required
                    value={item.value}
                    style={{
                      border: "0",
                    }}
                    onChange={(e) => {
                      item.inputMode == "none" && e.target.blur();
                      item.function(e.target.value);
                    }}
                  />
                </FloatingLabel>
                {item.value != "" && (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => {
                      item.function("");
                    }}
                  >
                    <TiDeleteOutline size="1.75rem" />
                  </div>
                )}
              </InputGroup>
            );
          })}
          <Button className="w-100 mt-1" type="submit">
            {languages.login[userLan].nextButton}
          </Button>
        </Form>
      </div>
      <LoginError show={hasError} setShow={setHasError} message={errorMessage} />
      <PowererBy />
    </div>
  );
};

export default Login;
