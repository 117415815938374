import React from "react";
import { TiDeleteOutline } from "react-icons/ti";
import { BsCalendar3 } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setDateRanges, setFilter } from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getFilters, getObjectState, getScreenState } from "../../../redux/selectors";
import getValueFromFilterArray from "../../functions/getValueFromFilterArray";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";

const S1DateRange = ({ element, tabID, parentIndex }) => {
  const isDateTime = element.dataType == "DateTime";
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const dispatch = useDispatch();
  const filters = useSelector((state) => getFilters(state, tabID));
  const dateranges = useSelector((state) => state.systemParams.dateranges);

  const objectState = useSelector((state) => getObjectState(state, tabID));
  const screenState = useSelector((state) => getScreenState(state, tabID));

  const dateRef = React.useRef(null);
  const contRef = React.useRef(null);

  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [viewValue, setViewValue] = React.useState("");

  const handleClear = () => {
    setValue("");
    setViewValue("");
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
      dispatch(setDateRanges({ key: parentIndex, value: undefined, tabID }));
    } else {
      console.log("dispatch se " + screenState);
    }
  };

  React.useEffect(() => {
    var value = "";
    const getDtValue = () => {
      if (element.value != "") {
        const dtIndex = element.value.split(":")[1].slice(-2);
        const fromOrTo = element.value.split(":")[1].substring(0, 1) == "1" ? "from" : "to";
        value = dateranges?.[`1${dtIndex}`]?.[fromOrTo] ?? "";
      } else {
        value = element.value;
      }
    };
    getDtValue();

    value = filters?.text ? getValueFromFilterArray(table, key) : value;
    value = value != "" ? dateConverter(value) : "";
    setValue(value);
    setViewValue(displayConverter(value));
    dispatch(setFilter({ tabID, table, key, value: isDateTime ? dateTimeConverter(value) : dateConverter(value) }));
  }, []);

  React.useEffect(() => {
    if (filters?.daterange?.[parentIndex]) {
      const dtIndex = filters?.daterange?.[parentIndex].slice(-2);
      var fromOrTo = "";
      if (element.name.indexOf("_TO") > -1) {
        fromOrTo = "to";
      } else {
        if (element.value.split(":").length > 1) {
          fromOrTo = element.value.split(":")[1].substring(0, 1) == "1" ? "from" : "to";
        } else {
          fromOrTo = "from";
        }
      }
      var value = dateranges?.[`1${dtIndex}`]?.[fromOrTo];
      handleChange(value == "1899-12-30" ? "" : value);
      setValue(value == "1899-12-30" ? "" : dateConverter(value));
      setViewValue(value == "1899-12-30" ? "" : displayConverter(value));
    }
  }, [filters?.daterange?.[parentIndex]]);

  const handleChange = (value) => {
    setValue(value);
    setViewValue(displayConverter(value));

    dispatch(setFilter({ tabID, table, key, value: isDateTime ? dateTimeConverter(value) : dateConverter(value) }));
  };

  React.useEffect(() => {
    if (isFocused) {
      dateRef.current.showPicker();
      dateRef.current.click();
      dateRef.current.focus();
    }
  }, [isFocused]);

  return (
    <div
      className="s1daterange w-100"
      ref={contRef}
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <InputGroup
        style={{ border: 0 }}
        className={`crm-floating-with-icon${element.required && value == "" ? " input-required" : ""}`}
      >
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            onClick={() => setIsFocused(true)}
            className={`d-flex w-100 ps-1 border-0${viewValue != "" ? " date-input-has-value" : ""}${
              element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
            }`}
            disabled={element.readOnly || (screenState == "form" && commandParams.readonly)}
            readOnly={true}
            value={viewValue}
            name={element.name}
            placeholder={element.caption}
          />
        </FloatingLabel>
        {value != "" &&
          !element.readOnly &&
          ((screenState == "form" && objectState != "view") || screenState == "dialog") && (
            <div className="d-flex align-items-center justify-content-center me-1" onClick={handleClear}>
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}

        {/* {!(element.readOnly || (screenState == "form" && commandParams.readonly)) && (
          <div
            className="d-flex align-items-center justify-content-center"
            role="button"
            onClick={() => setIsFocused(true)}
          >
            <BsCalendar3
              className={element.readOnly || (screenState == "form" && commandParams.readonly) ? "disabled" : ""}
              color="var(--bs-primary)"
              size="20px"
            />
          </div>
        )} */}
      </InputGroup>
      <input
        type="date"
        ref={dateRef}
        value={value}
        className="text-truncate"
        onBlur={() => setIsFocused(false)}
        onChange={(e) => !element.readOnly && handleChange(e.target.value)}
        style={{
          pointerEvents: "none",
          position: "fixed",
          top: contRef?.current?.getBoundingClientRect()?.bottom,
          maxWidth: 0,
          maxHeight: 0,
          backgroundColor: "transparent",
          border: 0,
          color: "transparent",
        }}
      />
    </div>
  );
};

export default S1DateRange;

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

const dateConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return value;
  }
};

const dateTimeConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return `${[d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-")} 00:00:00`;
  } else {
    return value;
  }
};

const displayConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
  } else {
    return value;
  }
};
