import React from "react";
import { Button, Container } from "react-bootstrap";
import { HiFilter } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { setScreenState } from "../../../../redux/features/ui/uiSlice";
import { getBrowserView } from "../../../../redux/selectors";
import Search from "./browser/Search";
import MenuBrowser from "./browser/MenuBrowser";
import Refresh from "./browser/Refresh";
const TopBarOnBrowser = ({ tabID }) => {
  const dispatch = useDispatch();
  const browserView = useSelector((state) => getBrowserView(state, tabID));

  const handleGoToDialog = () => {
    dispatch(setScreenState({ value: "dialog", tabID }));
  };

  return (
    <Container fluid className="object-top-bar">
      <Button onClick={handleGoToDialog} variant="secondary">
        <HiFilter />
      </Button>
      {browserView == "browser" && <Search tabID={tabID} />}
      <div className="d-flex">
        {browserView == "calendar" && <Refresh tabID={tabID} />}
        <MenuBrowser tabID={tabID} />
      </div>
    </Container>
  );
};

export default TopBarOnBrowser;
