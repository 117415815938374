import React from "react";
import { AiFillWarning } from "react-icons/ai";

const GadgetError = ({ error }) => {
  return (
    <div className="d-flex justify-content-center flex-column align-items-center" style={{ flexGrow: 1 }}>
      <span style={{ fontSize: "1.4rem", fontWeight: "bold", color: "#4A4A4A" }}>Σφάλμα!</span>
      <AiFillWarning size="128px" color="var(--bs-secondary)" />
      <span style={{ fontSize: "1.1rem", color: "#4A4A4A" }}>{error}</span>
    </div>
  );
};

export default GadgetError;
