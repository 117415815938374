export const daysOfWeek = {
  GRE: {
    1: "Δευτέρα",
    2: "Τρίτη",
    3: "Τετάρτη",
    4: "Πέμπτη",
    5: "Παρασκευή",
    6: "Σάββατο",
    0: "Κυριακή",
  },
  ENG: {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    0: "Sunday",
  },
};

export const slots = {
  GRE: [
    ["5 λεπτά", "00:05:00"],
    ["10 λεπτά", "00:10:00"],
    ["15 λεπτά", "00:15:00"],
    ["30 λεπτά", "00:30:00"],
    ["1 ώρα", "01:00:00"],
  ],
  ENG: [
    ["5 minutes", "00:05:00"],
    ["10 minutes", "00:10:00"],
    ["15 minutes", "00:15:00"],
    ["30 minutes", "00:30:00"],
    ["1 hour", "01:00:00"],
  ],
};

export const times = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];
