import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAccesRights,
  getBrowserInfo,
  getCurrentTab,
  getFormDesign,
  getSN,
  getSelectedRow,
  getTableColumns,
} from "../../../../redux/selectors";
import {
  setFormDesign,
  setGetData,
  setLoading,
  setScreenState,
  setSelectedRow,
} from "../../../../redux/features/ui/uiSlice";
import servicesGetFormDesign from "../../../../services/getFormDesign";
import getData from "../../../../services/getData";
import { FiCameraOff } from "react-icons/fi";
import { setError } from "../../../../redux/features/modals/modalsSlice";

const Row = React.forwardRef(({ row, AAIndex, onClick, tabID, hasImage, imageIndex }, ref) => {
  const dispatch = useDispatch();
  const tableColumns = useSelector((state) => getTableColumns(state, tabID));
  const selectedRow = useSelector((state) => getSelectedRow(state, tabID));
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));
  const accessRights = useSelector((state) => getAccesRights(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const idx = useSelector((state) => getCurrentTab(state, tabID)?.idx);
  const serialnumber = useSelector((state) => getSN(state));

  const handleClickOnRow = async () => {
    var locateinfo = "";
    var FormDesign = JSON.parse(JSON.stringify(formDesign ?? {}));
    var noError = true;

    const id = row.ZOOMINFO.substring(row.ZOOMINFO.indexOf(";") + 1);
    dispatch(setSelectedRow({ tabID, value: id }));
    if (browserInfo?.browserOnly != true) {
      if (accessRights?.browserOnly != true) {
        dispatch(setLoading({ show: true, tabID }));
        if (!FormDesign?.success) {
          const fetchFormDesign = await servicesGetFormDesign(tabID);
          if (fetchFormDesign.success) {
            dispatch(setFormDesign({ value: fetchFormDesign, idx, tabID }));
            dispatch(setScreenState({ value: "form", tabID }));

            FormDesign = fetchFormDesign;
          } else {
            noError = false;
            dispatch(setLoading({ show: false, tabID }));
            console.log(fetchFormDesign);
          }
        } else {
          dispatch(setScreenState({ value: "form", tabID }));
        }

        if (noError) {
          Object.entries(FormDesign.model).forEach(([key, value], index) => {
            locateinfo += index == 0 ? `${key}:` : `;${key}:`;
            if (key == "XTRDOCDATA" || key == "SALDODATA") locateinfo += "NAME,SOFNAME,LINENUM";
            else
              value.fields.map((item) => {
                locateinfo += `${item.name},`;
              });
          });
          const data = await getData({ key: id, locateinfo, tabID });
          if (data.success) {
            dispatch(setGetData({ value: data, tabID }));
            dispatch(setLoading({ show: false, tabID }));
          } else {
            dispatch(setScreenState({ value: "browser", tabID }));
            dispatch(setError({ show: true, message: data.error }));
            dispatch(setLoading({ show: false, tabID }));
          }
        }
      }
    }
  };

  return (
    <div
      className={`browser-list-row${
        selectedRow == row.ZOOMINFO.substring(row.ZOOMINFO.indexOf(";") + 1) ? " selected" : ""
      }${hasImage ? " with-image" : ""}`}
      ref={ref}
      onClick={handleClickOnRow}
    >
      {hasImage && (
        <div className="image-div">
          {row[tableColumns[imageIndex]?.accessor] != "" ? (
            <div
              className="image-on-list"
              style={{
                backgroundImage: `url("https://${serialnumber}.oncloud.gr/s1services/?filename=${
                  row[tableColumns[imageIndex].accessor]
                }")`,
              }}
            />
          ) : (
            <div className="no-image-on-list">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <FiCameraOff size={50} />
                <span>NO IMAGE</span>
              </div>
            </div>
          )}
        </div>
      )}
      {!hasImage && (
        <div className="browser-list-row-header">
          <span>{listEditor(tableColumns[AAIndex + 1], row[tableColumns[AAIndex + 1].accessor])}</span>
          <span>{listEditor(tableColumns[AAIndex + 2], row[tableColumns[AAIndex + 2].accessor])}</span>
        </div>
      )}

      {tableColumns
        .filter((col) => !col.hidden && col.dataIndex != "FLD-1")
        .map((column, y) => {
          if (hasImage) {
            if (column.accessor != "FLD-1" && column.accessor != tableColumns[imageIndex]?.accessor) {
              return (
                row[column.accessor] != "" && (
                  <div key={`${y + 1}`} className="browser-list-row-detail">
                    <span>{`${column.header}: `}</span>
                    <span>{listEditor(column, row[column.accessor])}</span>
                  </div>
                )
              );
            }
          } else {
            if (
              column.accessor != tableColumns[AAIndex + 1].accessor &&
              column.accessor != tableColumns[AAIndex + 2].accessor
            ) {
              return (
                row[column.accessor] != "" && (
                  <div key={`${y + 1}`} className="browser-list-row-detail">
                    <span>{`${column.header}: `}</span>
                    <span>{listEditor(column, row[column.accessor])}</span>
                  </div>
                )
              );
            }
          }
        })}
    </div>
  );
});

export default Row;

export const listEditor = (column, value) => {
  if (value != "") {
    switch (column.decs) {
      case 0: //STRINGLIST
        var index = value.indexOf("|");
        if (index > -1) {
          return value.substring(index + 1, value.length);
        } else {
          return value;
        }
      case 10: //DATETIME
        var d = new Date(value);
        return (
          [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/") +
          " " +
          [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":")
        );
      case 11: //DATE
        var d = new Date(value);
        return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
      case 2: //Float
        return parseFloat(value)
          .toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .replace("€", "");
      case 3: //Float
        return parseFloat(value)
          .toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })
          .replace("€", "");
      default:
        return value;
    }
  } else {
    return value;
  }
};
