import React from "react";
import { Button } from "react-bootstrap";
import { TiInfoLarge } from "react-icons/ti";

const Info = () => {
  return (
    <Button variant="secondary">
      <TiInfoLarge />
    </Button>
  );
};

export default Info;
