import React from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getCard } from "../../../redux/selectors/showRooms";
import { languages } from "../../languages";
import { HiX, HiCheck } from "react-icons/hi";
import { getSN, getTableColumns } from "../../../redux/selectors";
import { FiCameraOff } from "react-icons/fi";
import { ImBin } from "react-icons/im";
import { useDispatch } from "react-redux";
import { deleteItemFromCart } from "../../../redux/features/showRooms/showRoomsSlice";
import QtyInput from "./cart/QtyInput";

const ModalCart = ({ show, setShow, tabID, idx }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => getCard(state, idx));
  const sn = useSelector((state) => getSN(state));
  const currentLang = useSelector((state) => state.settings.language);
  const tableColumns = useSelector((state) => getTableColumns(state, tabID));
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      fullscreen
      className="d-flex align-items-end"
      dialogClassName="p-3"
      contentClassName="rounded overflow-hidden shadow"
    >
      <Modal.Body className="s1-cart-body">
        {cart &&
          Object.keys(cart).map((item) => {
            if (cart[item].QTY > 0) {
              return (
                <div className="s1-cart-list-row">
                  <div className="image-div">
                    {cart[item].row?.ITEM_MTRL_ITEDOCDATA_SODATA &&
                    cart[item].row?.ITEM_MTRL_ITEDOCDATA_SODATA != "" ? (
                      <div
                        className="image-on-list"
                        style={{
                          backgroundImage: `url("https://${sn}.oncloud.gr/s1services/?filename=${cart[item].data?.MTRL_ITEDOCDATA_SODATA}")`,
                        }}
                      />
                    ) : (
                      <div className="no-image-on-list">
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <FiCameraOff size={40} />
                          <span>NO IMAGE</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="s1-cart-list-row-info">
                    {tableColumns &&
                      tableColumns.map((column) => {
                        if (
                          (column.accessor == "ITEM_CODE" || column.accessor == "ITEM_NAME") &&
                          cart[item].row?.[column.accessor] &&
                          cart[item].row?.[column.accessor] != ""
                        ) {
                          return (
                            <div className="s1-cart-list-row-detail">
                              <span>{cart[item].row?.[column.accessor]}</span>
                            </div>
                          );
                        }
                      })}
                  </div>
                  <div className="s1-cart-list-row-buttons">
                    <QtyInput idx={idx} mtrl={item} />
                    <div
                      className="d-flex justify-content-center"
                      onClick={() => dispatch(deleteItemFromCart({ idx, mtrl: item }))}
                    >
                      <ImBin role="button" size="1.5rem" color="var(--bs-danger)" />
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </Modal.Body>
      <Modal.Footer className="picker-buttons">
        <ButtonGroup>
          <Button variant="outline-secondary" className="rounded-0" onClick={() => setShow(false)}>
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].closing}
            </div>
          </Button>
          <Button className="rounded-0" variant="outline-secondary">
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].proceed}
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCart;
