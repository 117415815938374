import React from "react";
import { useSelector } from "react-redux";
import { getTableColumns, getURL } from "../../../redux/selectors";
import NoImage from "../../../assets/noimage.svg";
import Minus from "./card/Minus";
import Plus from "./card/Plus";
import Qty from "./card/Qty";
import Info from "./card/Info";

const ItemCard = React.forwardRef(({ tabID, row, hasImage, imageAccessor, columns }, ref) => {
  const url = useSelector((state) => getURL(state));
  const mtrl = row.ZOOMINFO.split(";")[1];
  let headerCount = 0;
  let extraHeaderIndex = 0;

  return (
    <div className="s1-showroom-card" ref={ref}>
      {hasImage ? (
        row[imageAccessor] != "" ? (
          <div
            className="s1-showroom-card-image"
            style={{ backgroundImage: `url(${url}/?filename=${row[imageAccessor]})` }}
          />
        ) : (
          <div className="s1-showroom-card-image no-image" style={{ backgroundImage: `url(${NoImage})` }} />
        )
      ) : (
        <div className="s1-showroom-card-image no-image" style={{ backgroundImage: `url(${NoImage})` }} />
      )}
      <div className="s1-showroom-card-info">
        {columns.map((column, index) => {
          if (column.accessor != imageAccessor && headerCount < 2) {
            headerCount++;
            var header = row?.[column.accessor];
            if (headerCount == 1) {
              if (
                columns?.[index + 1] &&
                columns?.[index + 1]?.accessor &&
                columns?.[index + 1]?.accessor != imageAccessor
              ) {
                headerCount++;
                extraHeaderIndex = index + 1;
                header += " - " + row?.[columns[index + 1].accessor];
              } else if (
                columns?.[index + 2] &&
                columns?.[index + 2]?.accessor &&
                columns?.[index + 2]?.accessor != imageAccessor
              ) {
                headerCount++;
                extraHeaderIndex = index + 2;
                header += " - " + row?.[columns[index + 2].accessor];
              } else if (
                columns?.[index + 3] &&
                columns?.[index + 3]?.accessor &&
                columns?.[index + 3]?.accessor != imageAccessor
              ) {
                headerCount++;
                extraHeaderIndex = index + 3;
                header += " - " + row?.[columns[index + 3].accessor];
              }
            }
            return (
              <div className="s1-showroom-card-info-header" key={index}>
                {header}
              </div>
            );
          } else if (
            extraHeaderIndex != index &&
            headerCount >= 2 &&
            headerCount < 4 &&
            column.accessor != imageAccessor
          ) {
            headerCount++;
            return (
              row?.[column.accessor] != "" && (
                <div className="s1-showroom-card-info-detail" key={index}>
                  {`${column.Header}: ${listEditor(column, row?.[column.accessor])}`}
                </div>
              )
            );
          }
        })}
        <div className="s1-showroom-card-actions">
          <Qty tabID={tabID} mtrl={mtrl} row={row} />
          <Minus tabID={tabID} mtrl={mtrl} row={row} />
          <Plus tabID={tabID} mtrl={mtrl} row={row} />
          <Info tabID={tabID} />
        </div>
      </div>
    </div>
  );
});

export default ItemCard;

export const listEditor = (column, value) => {
  if (value != "") {
    switch (column.decs) {
      case 0: //STRINGLIST
        var index = value.indexOf("|");
        if (index > -1) {
          return value.substring(index + 1, value.length);
        } else {
          return value;
        }
      case 10: //DATETIME
        var d = new Date(value);
        return (
          [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/") +
          " " +
          [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":")
        );
      case 11: //DATE
        var d = new Date(value);
        return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
      case 2: //Float
        return parseFloat(value)
          .toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .replace("€", "");
      case 3: //Float
        return parseFloat(value)
          .toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })
          .replace("€", "");
      default:
        return value;
    }
  } else {
    return value;
  }
};
