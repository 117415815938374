import React from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setSettingsModal, toogleMenu } from "../../../redux/features/modals/modalsSlice";
import { setTab } from "../../../redux/features/ui/uiSlice";

const Settings = () => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        // dispatch(toogleMenu());
        // dispatch(setSettingsModal(true));
        dispatch(toogleMenu());
        dispatch(setTab(-2));
      }}
    >
      <AiOutlineSetting size="1.5rem" />
    </div>
  );
};

export default Settings;
