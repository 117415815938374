import React from "react";
import { Container, Button } from "react-bootstrap";
import { HiRefresh, HiFilter } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { setLoading, setReportData, setReportInfo, setScreenState } from "../../../../redux/features/ui/uiSlice";
import serviceGetReportInfo from "../../../../services/getReportInfo";
import getReportData from "../../../../services/getReportData";
import PrintReport from "./browser/PrintReport";
const TopBarOnBrowser = ({ tabID }) => {
  const dispatch = useDispatch();
  const handleReload = async () => {
    dispatch(setLoading({ show: true, tabID }));
    const reportInfo = await serviceGetReportInfo(tabID);
    if (reportInfo.success) {
      dispatch(setReportInfo({ value: reportInfo, tabID }));
      if (reportInfo.npages > 0) {
        const reportData = await getReportData({ reqID: reportInfo.reqID, pagenum: 1 });
        reportData.pageNum = 1;
        dispatch(setReportData({ value: reportData, tabID }));
      } else {
        console.log("no data");
      }
    } else {
      dispatch(setReportInfo({ value: reportInfo, tabID }));
    }
    dispatch(setLoading({ show: false, tabID }));
  };

  const handleGoToDialog = () => {
    dispatch(setScreenState({ value: "dialog", tabID }));
  };

  return (
    <Container fluid className="object-top-bar">
      <Button variant="secondary" onClick={handleGoToDialog}>
        <HiFilter />
      </Button>
      <div className="d-flex align-items-center">
        <Button variant="secondary" onClick={handleReload}>
          <HiRefresh />
        </Button>
        <PrintReport tabID={tabID} />
      </div>
    </Container>
  );
};

export default TopBarOnBrowser;
