import React from "react";
import { MdOutlineKeyboardDoubleArrowRight, MdOutlineKeyboardArrowRight } from "react-icons/md";

const ArrowsNext = ({ canNextPage, gotoPage, nextPage, pageCount }) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() => canNextPage && nextPage()}
        style={{
          userSelect: "none",
          cursor: canNextPage ? "pointer" : "default",
          opacity: canNextPage ? 1 : 0.5,
        }}
      >
        <MdOutlineKeyboardArrowRight size="1.3rem" />
      </div>
      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() => canNextPage && gotoPage(pageCount - 1)}
        style={{
          userSelect: "none",
          cursor: canNextPage ? "pointer" : "default",
          opacity: canNextPage ? 1 : 0.5,
        }}
      >
        <MdOutlineKeyboardDoubleArrowRight size="1.3rem" />
      </div>
    </div>
  );
};

export default ArrowsNext;
