import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom/dist";
import { useDispatch } from "react-redux";
import { setDropBox, setFromAuth } from "../../../redux/features/settings/settingsSlice";
import { SiDropbox } from "react-icons/si";
import Spinner from "../../ui/Spinner";
import { Alert, Button, Card } from "react-bootstrap";
import { setTab } from "../../../redux/features/ui/uiSlice";

const Auth = () => {
  const dispatch = useDispatch();
  const { provider, type } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");
  const [loading, setLoading] = React.useState(true);
  const [done, setDone] = React.useState(false);
  const [dropBoxAccount, setDropBoxAccount] = React.useState({});
  const [error, setError] = React.useState(undefined);

  React.useEffect(() => {
    if (code) {
      const config = {
        clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
        clientSecret: process.env.REACT_APP_DROPBOX_CLIENT_SECRET,
      };
      var Dropbox = require("dropbox").Dropbox;
      var dbx = new Dropbox(config);
      dbx.auth
        .getAccessTokenFromCode(process.env.REACT_APP_DROPBOX_REDIRECT_URL, code)
        .then((token) => {
          dbx.auth.setRefreshToken(token.result.refresh_token);
          dbx
            .usersGetCurrentAccount()
            .then((response) => {
              setDropBoxAccount(response.result);
              setDone(true);
              setLoading(false);
              dispatch(
                setDropBox({
                  token: token.result.refresh_token,
                  account: response.result,
                })
              );
              dispatch(setTab(-2));
              dispatch(setFromAuth());
              setTimeout(() => navigate(`/`), 1000);
            })
            .catch((error) => {
              setLoading(false);
              setError(error.message);
              console.error(error.message);
            });
        })
        .catch((error) => {
          setLoading(false);
          setError(error.message);

          console.error(error.message);
        });
    } else {
      navigate(`/`);
    }
  }, []);

  return (
    <div className="auth-screen">
      {/* {loading && <Spinner />} */}
      <Card className="shadow p-4 d-flex justify-content-center align-items-center">
        {provider == "dropbox" && <SiDropbox size="7rem" color="#0061fe" />}
      </Card>
      {done && (
        <Alert className="mt-2" variant="success">
          Επιτυχής Σύνδεση
          <hr />
          <p className="d-flex flex-column">
            {/* {dropBoxAccount?.name?.given_name && <span>{dropBoxAccount?.name?.given_name}</span>}
            {dropBoxAccount?.name?.surname && <span>{dropBoxAccount?.name?.surname}</span>} */}
            {dropBoxAccount?.email && <span>{dropBoxAccount?.email}</span>}
          </p>
        </Alert>
      )}
      {error && (
        <Alert className="mt-2" variant="danger">
          Error
          <hr />
          <p>{error}</p>
          <Button onClick={() => navigate("/")}>Back</Button>
        </Alert>
      )}
    </div>
  );
};

export default Auth;
