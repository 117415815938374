import React from "react";
import { Button, Container } from "react-bootstrap";
import { BsArrowsCollapse, BsArrowsExpand, BsMenuApp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getGadgets } from "../../../../redux/selectors";
import {
  collapseAll,
  expandAll,
  hideAll,
  showAll,
  toogleVisibility,
} from "../../../../redux/features/portal/portalSlice";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

const TopBarOnDashBoard = () => {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const gadgetOrder = useSelector((state) => state.portal.gadgetsOrder);
  const gadgets = useSelector((state) => getGadgets(state));
  const menuRef = React.useRef(null);
  const buttonRef = React.useRef(null);

  React.useEffect(() => {
    if (show) {
      const clickListener = (e) => {
        if (!buttonRef.current.contains(e.target)) setShow(false);
      };
      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [show]);

  return (
    <div className="object-top-bar">
      <Button
        variant="secondary"
        ref={buttonRef}
        style={{
          position: "relative",
        }}
      >
        <BsMenuApp
          onClick={(e) => {
            e.stopPropagation();
            setShow((x) => !x);
          }}
        />
        <div
          ref={menuRef}
          className="tab-list-context-menu text-truncate dropdown-menu select-none"
          style={{
            position: "absolute",
            display: show ? "flex" : "none",
            top: buttonRef?.current?.offsetHeight,
            color: "black",
            maxWidth: "55vw",
          }}
        >
          <div
            className="tab-list-context-menu-action dropdown-item"
            role="button"
            onClick={() => {
              dispatch(expandAll());
            }}
          >
            <div className="d-flex align-items-center gap-2">
              <BsArrowsExpand />
              <span>Expand All</span>
            </div>
          </div>
          <div
            className="tab-list-context-menu-action dropdown-item"
            role="button"
            onClick={() => {
              dispatch(collapseAll());
            }}
          >
            <div className="d-flex align-items-center gap-2">
              <BsArrowsCollapse />
              <span>Collapse All</span>
            </div>
          </div>
          <hr className="my-1" style={{ color: "var(--bs-dropdown-link-color)" }} />
          <div
            className="tab-list-context-menu-action dropdown-item"
            role="button"
            onClick={() => {
              dispatch(showAll());
            }}
          >
            <div className="d-flex align-items-center gap-2">
              <AiOutlineEye />
              <span>Show All</span>
            </div>
          </div>
          <div
            className="tab-list-context-menu-action dropdown-item"
            role="button"
            onClick={() => {
              dispatch(hideAll());
            }}
          >
            <div className="d-flex align-items-center gap-2">
              <AiOutlineEyeInvisible />
              <span>Hide All</span>
            </div>
          </div>
          <hr className="my-1" style={{ color: "var(--bs-dropdown-link-color)" }} />

          {gadgetOrder.map((key) => (
            <div
              className="tab-list-context-menu-action dropdown-item"
              role="button"
              key={key}
              onClick={() => dispatch(toogleVisibility(key))}
            >
              <div className="d-flex align-items-center gap-2">
                <input type="checkbox" checked={gadgets[key].show} readOnly style={{ height: "1rem" }} />
                <span className="text-truncate">{gadgets[key].data?.title ?? gadgets[key].config?.title}</span>
              </div>
            </div>
          ))}
        </div>
      </Button>
    </div>
  );
};

export default TopBarOnDashBoard;
