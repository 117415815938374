import React from "react";
import { Button } from "react-bootstrap";
import { AiFillLock } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setPasswordModal } from "../../../../redux/features/modals/modalsSlice";
import { useSelector } from "react-redux";
import { languages } from "../../../languages";

const Password = () => {
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.settings.language);
  return (
    <Button variant="secondary" onClick={() => dispatch(setPasswordModal(true))}>
      <div className="d-flex justify-content-center align-items-center gap-2">
        <AiFillLock />
        <span>{languages.user[currentLang].changePassword}</span>
      </div>
    </Button>
  );
};

export default Password;
