import React from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getAccesRights, getObjectState, getSignature } from "../../../../../redux/selectors";
import { useSelector } from "react-redux";
import { languages } from "../../../../languages";
import { TbSignature } from "react-icons/tb";
import ModalSignature from "../../../../ui/rel docs/signature/ModalSignature";
import { setError } from "../../../../../redux/features/modals/modalsSlice";

const Signature = ({ tabID }) => {
  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const accessRights = useSelector((state) => getAccesRights(state, tabID));
  const currentLang = useSelector((state) => state.settings.language);
  const signature = useSelector((state) => getSignature(state, tabID));

  const dropbox = useSelector((state) => state.settings?.cloudStorage?.dropbox);

  const [show, setShow] = React.useState(false);

  const handleClick = () => {
    if (!dropbox) {
      dispatch(setError({ show: true, message: "Δεν έχετε συνδεδεμένο λογαριασμό dropbox." }));
    } else {
      setShow(true);
    }
  };

  return (
    signature?.canProceed && (
      <>
        <Dropdown.Item
          disabled={accessRights?.update == false || objectState != "view"}
          onClick={handleClick}
          className="d-flex align-items-center justify-content-start"
        >
          <div className="me-1 d-flex justify-content-start align-items-center">
            <TbSignature size="1.2rem" />
          </div>

          <span className="text-truncate"> {languages.generalText[currentLang].signature}</span>
        </Dropdown.Item>
        <ModalSignature show={show} setShow={setShow} tabID={tabID} />
      </>
    )
  );
};

export default Signature;
