import React from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import S1Selector from "../s1 inputs/S1Selector";
import S1NumberField from "../s1 inputs/S1NumberField";
import S1MemoryTable from "../s1 inputs/S1MemoryTable";
import S1TextField from "../s1 inputs/S1TextField";
import S1CalendarDate from "../s1 inputs/S1CalendarDate";
import S1IntField from "../s1 inputs/S1IntField";
import S1Strings from "../s1 inputs/S1Strings";
import { Alert } from "bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCommandParams, getGridByName, getNewData, getObjectState, getTabsOrder } from "../../../redux/selectors";
import {
  addLineToGrid,
  changeToBuffer,
  setCurrentDetailLine,
  setObjectState,
  triggerGridChange,
  updateLineOfGrid,
} from "../../../redux/features/ui/uiSlice";
import { calculate } from "../../../services/calculate";
import S1WebPage from "../s1 inputs/S1WebPage";
import S1CheckBox from "../s1 inputs/S1CheckBox";
import { languages } from "../../languages";
import { HiCheck, HiX } from "react-icons/hi";
const ModalDetailLine = ({ show, setShow, element, tabID }) => {
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.settings.language);

  const commandParams = useSelector((state) => getCommandParams(state, tabID));
  const orderTabsLength = useSelector((state) => getTabsOrder(state).length);

  const [error, setError] = React.useState({ show: false, message: "" });
  const grid = useSelector((state) => getGridByName(state, tabID, element.model));
  const newData = useSelector((state) => getNewData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const render = (element) => {
    switch (element.xtype) {
      case "s1selector":
        return <S1Selector element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1numberfield":
        return <S1NumberField element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1intfield":
        return <S1IntField element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1strings":
        return <S1Strings element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1memorytable":
        return <S1MemoryTable element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1textfield":
        return <S1TextField element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1calendardate":
        return <S1CalendarDate element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1webpage":
        return <S1WebPage element={element} tabID={tabID} screenState="form" grid={grid} />;
      case "s1checkbox":
        return <S1CheckBox element={element} tabID={tabID} screenState="form" grid={grid} />;
      default:
        return (
          <div style={{ backgroundColor: "red" }} key={element.index}>
            {element.xtype}
          </div>
        );
    }
  };

  React.useEffect(() => setShow(false), [orderTabsLength]);

  const handleAccept = async () => {
    setShow(false);
    var line = { ...grid?.currentLine?.line };
    var gridLines = newData?.[grid.model] ?? [];

    gridLines.length == 0 && dispatch(triggerGridChange({ tabID, name: grid.model }));

    if (line?.LINENUM) {
      //Αν έχει LINENUM τοτε ΔΕΝ είναι καινουργια γραμμή
      //και θα κάνω locate για να την κάνω update
      dispatch(updateLineOfGrid({ tabID, value: line, name: grid.model, gridIndex: grid?.currentLine?.index }));
    } else {
      //Αλλιώς πρέπει να την προσθέσω στον reducer
      dispatch(addLineToGrid({ tabID, value: line, name: grid.model }));
    }
    await calculate(tabID);
    dispatch(setCurrentDetailLine({ tabID, name: element.model, value: undefined }));
  };
  return (
    <Modal
      fullscreen
      // animation={false}
      className="d-flex align-items-end"
      dialogClassName="p-3"
      contentClassName="rounded overflow-hidden shadow"
      backdrop="static"
      show={show}
      centered
      onHide={() => setShow(false)}
    >
      <Modal.Body className="d-flex flex-column " style={{ userSelect: "none", overflowY: "auto" }}>
        {grid?.columns &&
          grid.columns.map((item, i) => (
            <div key={i} className="my-1">
              {render(item)}
            </div>
          ))}
      </Modal.Body>
      {!commandParams.readonly && (
        <Modal.Footer className="picker-buttons">
          <ButtonGroup>
            <Button variant="outline-secondary" className="rounded-0" onClick={() => setShow(false)}>
              <HiX size={25} color="var(--bs-danger)" className="me-2" />
              <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                {languages.generalText[currentLang].cancel}
              </div>
            </Button>
            <Button
              disabled={objectState == "view"}
              className="rounded-0"
              variant="outline-secondary"
              type="submit"
              onClick={handleAccept}
            >
              <HiCheck size={25} color="var(--bs-success)" className="me-2" />
              <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                {languages.generalText[currentLang].confirm}
              </div>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalDetailLine;
