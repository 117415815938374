import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import { calculate } from "../../../services/calculate";
import { BsChevronDown, BsPinMapFill } from "react-icons/bs";
import getSelectorData from "../../../services/getSelectorData";
import PickerSpinner from "../PickerSpinner";
import { Button, ButtonGroup, FloatingLabel, Form, InputGroup, Modal } from "react-bootstrap";
import { HiX, HiCheck } from "react-icons/hi";
import { languages } from "../../languages";
import { phoneFields } from "../../../logic/phoneFields";
import { AiOutlinePhone } from "react-icons/ai";

const S1TextField = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getRecData(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID)?.data);
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const currentLang = useSelector((state) => state.settings.language);
  const [isMap, setIsMap] = React.useState(false);
  const [isPhone, setIsPhone] = React.useState(false);

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const currentGridLine = grid?.currentLine;

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model = screenState == "form" && formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0];
  const vf = screenState == "form" && model.editor.split("|")[2];

  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState("");

  const [pickerLoading, setPickerLoading] = React.useState(false);
  const [selectorData, setSelectorData] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [returnIndex, setReturnIndex] = React.useState(-1);
  const tableRef = React.useRef(null);

  const handleCalculate = async (value) => {
    if (grid) {
      dispatch(setGridChange({ tabID, name: grid.model, key, value }));
    } else {
      dispatch(setChange({ tabID, table, key, value }));
      model?.updates && (await calculate(tabID, objectState));
    }
  };

  React.useEffect(() => {
    if (screenState == "form") {
      setIsPhone(phoneFields.indexOf(key.split("_")[key.split("_").length - 1]) > -1);
      setIsMap(formDesign?.extrainfo?.map?.ADDRESS == element.name);
    }
  }, []);

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value);
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    }
  }, [screenState]);

  React.useEffect(() => {
    screenState == "form" && !grid && setValue(data?.[table]?.[0]?.[key] ?? "");
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    grid && setValue(currentGridLine?.line?.[key] ?? "");
  }, [currentGridLine?.line?.[key]]);

  const handleChange = (value) => {
    if (screenState == "dialog") {
      if (filters?.[table]?.[key] != value) {
        dispatch(setFilter({ tabID, table, key, value: value }));
      }
    } else if (screenState == "form") {
      if ((data?.[table]?.[0]?.[key] ?? "") != value) {
        handleCalculate(value);
      }
    }
  };

  const handleClear = () => {
    setValue("");
    setOpenPicker(false);

    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else if (screenState == "form") {
      if (objectState == "view") {
        dispatch(changeToBuffer({ tabID }));
        dispatch(setObjectState({ value: "edit", tabID }));
      }
      handleCalculate("");
    }
  };

  const handlePicker = async (e) => {
    e.stopPropagation();
    if (openPicker) {
      setOpenPicker(false);
    } else {
      setPickerLoading(true);
      setOpenPicker(true);
      const EditorArguments = Array.from(model.editor.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
      var editor = model.editor;
      EditorArguments.forEach((y) => {
        const splitted = y.split(".");
        const tableName = splitted[0];
        const fieldName = splitted[1];
        const replaceValue =
          screenState == "form"
            ? (data?.[tableName]?.[0]?.[fieldName] ?? "").split("|")[0]
            : (filters?.[tableName]?.[0]?.[fieldName] ?? "").split("|")[0];
        editor = editor.replaceAll(`[${y}]`, replaceValue);
      });
      const GetSelectorData = await getSelectorData({ value: "", editor, tabID });
      setSelectorData(GetSelectorData);
      if (GetSelectorData.success) setReturnIndex(GetSelectorData.model.findIndex((el) => el.name === vf));
      setPickerLoading(false);
    }
  };

  const handleAccept = () => {
    setOpenPicker(false);
    handleChange(selectedRow[returnIndex]);
  };

  const callNumber = () => {
    window.location.href = `tel:${value}`;
  };

  const openMap = () => {
    var text = "";
    Object.entries(formDesign.extrainfo.map).forEach(([key, value], index) => {
      const field = value;
      const splitted = field.split(".");
      const tableName = splitted[0];
      const fieldName = splitted[1];
      var valuee = data[tableName][0][fieldName] ?? "";

      text += valuee != "" ? (index == 0 ? `${valuee}` : `,${valuee}`) : 0;
    });
    const url = `http://maps.google.com/?q=${text} `;
    window.open(url);
  };

  return (
    <div className="s1textfield w-100" style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className={`crm-floating-with-icon${element.required && value == "" ? " input-required" : ""}`}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className={`w-100 ps-1 border-0${
              element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
            }`}
            disabled={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            readOnly={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            value={value}
            name={element.name}
            placeholder={element.caption}
            onChange={(e) => setValue(e.target.value)}
            onBlur={(e) => handleChange(e.target.value)}
          />
        </FloatingLabel>
        {((screenState == "form" && objectState != "view") || screenState == "dialog") &&
          value != "" &&
          !element.readOnly && (
            <div
              className={`d-flex align-items-center justify-content-center${element?.maskEnabled ? " me-1" : ""}`}
              onClick={handleClear}
            >
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
        {element?.maskEnabled && (
          <div
            className="d-flex align-items-center justify-content-center"
            role="button"
            onClick={(e) =>
              !(
                element.readOnly ||
                (screenState == "form" && commandParams.readonly) ||
                (screenState == "form" && objectState == "view")
              ) && handlePicker(e)
            }
          >
            <BsChevronDown
              className={
                element.readOnly ||
                (screenState == "form" && commandParams.readonly) ||
                (screenState == "form" && objectState == "view")
                  ? "disabled"
                  : ""
              }
              color="var(--bs-primary)"
              size="20px"
            />
          </div>
        )}
        {isPhone && (
          <div
            className="d-flex align-items-center justify-content-center ms-1"
            onClick={value != "" ? callNumber : undefined}
          >
            <AiOutlinePhone className={value != "" ? "" : "disabled"} size="1.5rem" color="var(--bs-primary)" />
          </div>
        )}
        {isMap && value != "" && (
          <div className="d-flex align-items-center justify-content-center ms-1" onClick={openMap}>
            <BsPinMapFill size="1.5rem" color="var(--bs-primary)" />
          </div>
        )}
      </InputGroup>
      {openPicker && (
        <Modal
          show={openPicker}
          fullscreen
          animation={false}
          className="d-flex align-items-end"
          dialogClassName="p-3"
          contentClassName="rounded overflow-hidden shadow"
        >
          {pickerLoading && <PickerSpinner />}

          <div className="input-picker  w-100 h-100" style={{ overflow: "auto" }}>
            {selectorData?.success ? (
              <div className="input-picker-table w-100" ref={tableRef}>
                {selectorData.data.map((el, index) => (
                  <div
                    key={index}
                    className={`input-picker-table-row${selectedRow == el ? " input-picker-table-row-selected" : ""}`}
                    onClick={() => setSelectedRow(el)}
                  >
                    <span className="searchable" key={index}>
                      {el[returnIndex]}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center h-100">{selectorData.error}</div>
            )}
          </div>
          <Modal.Footer className="picker-buttons">
            <ButtonGroup>
              <Button
                variant="outline-secondary"
                className="rounded-0"
                onClick={() => {
                  setOpenPicker(false);
                }}
              >
                <HiX size={25} color="var(--bs-danger)" className="me-2" />
                <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                  {languages.generalText[currentLang].closing}
                </div>
              </Button>
              <Button className="rounded-0" variant="outline-secondary" onClick={handleAccept}>
                <HiCheck size={25} color="var(--bs-success)" className="me-2" />
                <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                  {languages.generalText[currentLang].apply}
                </div>
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default S1TextField;
