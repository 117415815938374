import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommandParams, getFilters, getObjectState, getRecData } from "../../../redux/selectors";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";

const S1CheckBox = ({ element, tabID, screenState, grid }) => {
  const [value, setValue] = React.useState(false);

  const dispatch = useDispatch();
  const filters = useSelector((state) => getFilters(state, tabID));
  const data = useSelector((state) => getRecData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));

  const currentGridLine = grid?.currentLine;

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const handleChangeField = (e) => {
    if (screenState == "dialog") {
      setValue(e.target.checked);
      dispatch(setFilter({ tabID, table, key, value: e.target.checked ? 1 : 0 }));
    } else if (screenState == "form") {
      if (grid) {
        dispatch(
          setGridChange({
            tabID,
            name: grid.model,
            key,
            value: e.target.checked ? 1 : 0,
          })
        );
      } else {
        dispatch(setChange({ tabID, table, key, value: e.target.checked ? 1 : 0 }));
      }
    }
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value == 1);
    } else if (screenState == "form") {
      setValue(data?.[table]?.[0]?.[key] == 1);
    }
  }, [screenState]);

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(filters?.data?.[table]?.[key] == 1);
    }
  }, [filters?.data?.[table]?.[key]]);

  React.useEffect(() => {
    if (screenState == "form" && !grid) {
      setValue(data?.[table]?.[0]?.[key] == 1);
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if ((currentGridLine?.line?.[key] || currentGridLine?.line?.[key] == "") && grid) {
      setValue(currentGridLine?.line?.[key] == 1);
    }
  }, [currentGridLine?.line?.[key]]);

  return (
    <div
      className="s1checkbox w-100 form-check d-flex flex-column flex-wrap p-0 m-0"
      style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}
      hidden={!element.visible}
    >
      <label
        className="form-check-label py-1"
        htmlFor={element.index}
        style={{
          whiteSpace: "nowrap",
          opacity: "0.65",
          fontSize: "0.85rem",
        }}
      >
        {element.caption}
      </label>
      <input
        id={element.index}
        className="form-check-input m-0 ps-1"
        type="checkbox"
        name={element.name}
        disabled={element.readOnly || (screenState == "form" && (objectState == "view" || commandParams.readonly))}
        readOnly={element.readOnly || (screenState == "form" && (objectState == "view" || commandParams.readonly))}
        checked={value}
        onChange={handleChangeField}
        style={{ minHeight: "2rem", minWidth: "2rem" }}
      />
    </div>
  );
};

export default S1CheckBox;
