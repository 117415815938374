import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { BsCalendar3 } from "react-icons/bs";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { getBrowserView } from "../../../../../redux/selectors";
import { setBrowserInfo, setBrowserView, setLoading } from "../../../../../redux/features/ui/uiSlice";
import getBrowserInfo from "../../../../../services/getBrowserInfo";
import { languages } from "../../../../languages";

const CalendarListView = ({ tabID }) => {
  const dispatch = useDispatch();
  const browserView = useSelector((state) => getBrowserView(state, tabID));
  const currentLang = useSelector((state) => state.settings.language);

  const handleClick = async () => {
    if (browserView == "browser") {
      dispatch(setBrowserView({ tabID, value: "calendar" }));
    } else {
      //   dispatch(setLoading({ show: true, tabID }));
      //   const response = await getBrowserInfo(tabID);
      //   dispatch(setBrowserInfo({ value: response, tabID }));
      dispatch(setBrowserView({ tabID, value: "browser" }));
      //   dispatch(setLoading({ show: false, tabID }));
    }
  };
  return (
    <Dropdown.Item className="d-flex align-items-center justify-content-start" onClick={handleClick}>
      {browserView == "browser" ? (
        <>
          <BsCalendar3 size="1.2rem" className="me-1" />
          <span>{languages.generalText[currentLang].calendar}</span>
        </>
      ) : (
        <>
          <AiOutlineUnorderedList size="1.2rem" className="me-1" />
          <span>{languages.generalText[currentLang].list}</span>
        </>
      )}
    </Dropdown.Item>
  );
};

export default CalendarListView;
