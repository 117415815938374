import React from "react";
import { useDispatch, useSelector } from "react-redux/es";
import {
  newRecord,
  setCalendarScroll,
  setChange,
  setFormDesign,
  setLoading,
  setObjectState,
  setPrepareNew,
  setScreenState,
} from "../../../../redux/features/ui/uiSlice";
import getFormDesign from "../../../../services/getFormDesign";
import preparNew from "../../../../services/prepareNew";
import { calendarObjectsFields } from "../../../../logic/calendarObjectFields";
import {
  getBrowserInfo,
  getCalendarProps,
  getCommandByIndex,
  getCommandParams,
  getCurrentTab,
} from "../../../../redux/selectors";
import { BsPlus } from "react-icons/bs";

const NewOnCalendar = ({ tabID, calendarApi }) => {
  const dispatch = useDispatch();
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));
  const commandParams = useSelector((state) => getCommandParams(state, tabID));
  const tab = useSelector((state) => getCurrentTab(state, tabID));
  const calendarProps = useSelector((state) => getCalendarProps(state, tabID));
  const object = useSelector((state) => getCommandByIndex(state, tabID).split("&")[0]);
  const fromDateField = calendarObjectsFields[object].tableData.fromDate;
  const toDateField = calendarObjectsFields[object].tableData.toDate;

  const handleNew = async () => {
    const scroller = calendarApi.el.querySelector(".fc-timeGridDay-view .fc-scrollgrid-section-body .fc-scroller");
    dispatch(setCalendarScroll({ tabID, scroll: scroller?.scrollTop }));

    dispatch(setLoading({ show: true, tabID }));
    const FormDesign = tab.formDesign ?? (await getFormDesign(tabID));
    if (FormDesign.success) {
      !tab.formDesign && dispatch(setFormDesign({ value: FormDesign, idx: tab.idx, tabID }));
      var locateinfo = "";
      // if (!tab.prepareNew) {
      Object.entries(FormDesign.model).forEach(([key, value], index) => {
        locateinfo += index == 0 ? `${key}:` : `;${key}:`;
        if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
        else
          value.fields.map((item) => {
            locateinfo += `${item.name},`;
          });
      });
      const PrepareNew = await preparNew({ locateinfo, tabID });
      if (PrepareNew.success) {
        dispatch(
          setPrepareNew({
            value: PrepareNew.data,
            idx: tab.idx,
            tabID,
          })
        );
        // dispatch(setPrepareNew({ value: PrepareNew.data, idx: tab.idx, tabID }));
      }

      dispatch(newRecord({ tabID }));
      dispatch(
        setChange({
          tabID,
          table: calendarObjectsFields[object].setDataTable,
          key: fromDateField.split("_")[1],
          value: calendarProps.selectedDates.start,
        })
      );
      dispatch(
        setChange({
          tabID,
          table: calendarObjectsFields[object].setDataTable,
          key: toDateField.split("_")[1],
          value: calendarProps.selectedDates.end,
        })
      );
    } else {
      console.log(FormDesign);
    }
    dispatch(setScreenState({ value: "form", tabID }));
    dispatch(setObjectState({ value: "new", tabID }));
    dispatch(setLoading({ show: false, tabID }));
  };
  return browserInfo?.browserOnly != "true" && !commandParams.noinsert && calendarProps?.showInsert ? (
    <nav className="menu" onClick={handleNew} hidden={tab.screenState === "form"}>
      <label className="menu-open-button shadow ">
        <BsPlus className="pe-none" />
      </label>
    </nav>
  ) : (
    <></>
  );
};

export default NewOnCalendar;
