import { createSlice } from "@reduxjs/toolkit";
import { clear } from "../session/sessionSlice";
import { _setSelectedRow } from "./browser";

const initialState = {
  loading: false,
  gadgetParams: {},
  gadgets: null,
  gadgetsOrder: [],
  scroll: undefined,
};

const portalSlice = createSlice({
  name: "portal",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      if (state.gadgets?.[action.payload.id]) state.gadgets[action.payload.id].loading = action.payload.value;
    },
    gadgetParams: (state, action) => {
      state.gadgetParams = action.payload;
    },
    loadGadgets: (state, action) => {
      const array = [];
      Object.keys(action.payload).map((key, index) => {
        action.payload[key].timeOut = index * 500;
        action.payload[key].collapsed = true;
        action.payload[key].show = true;
        action.payload[key].loading = true;
        array.push(key);
      });
      state.gadgets = action.payload;
      state.gadgetsOrder = array.sort();
    },
    setFullWindow: (state, action) => {
      state.fullWindow = action.payload.id;
      state.scroll = action.payload.y;
    },
    setPortalRef: (state, action) => {
      state.portalRef = action.payload;
    },
    hideAll: (state) => {
      if (Object.keys(state.gadgets).length > 0) {
        state.fullWindow = undefined;
        Object.keys(state.gadgets).forEach((key) => {
          state.gadgets[key].show = false;
        });
      }
    },
    showAll: (state) => {
      if (Object.keys(state.gadgets).length > 0) {
        state.fullWindow = undefined;
        Object.keys(state.gadgets).forEach((key) => {
          state.gadgets[key].show = true;
        });
      }
    },
    expandAll: (state) => {
      if (Object.keys(state.gadgets).length > 0) {
        state.fullWindow = undefined;
        Object.keys(state.gadgets).forEach((key) => {
          state.gadgets[key].collapsed = false;
        });
      }
    },
    collapseAll: (state) => {
      if (Object.keys(state.gadgets).length > 0) {
        state.fullWindow = undefined;
        Object.keys(state.gadgets).forEach((key) => {
          state.gadgets[key].collapsed = true;
        });
      }
    },
    collapse: (state, action) => {
      if (state.gadgets[action.payload.id]) {
        state.fullWindow = undefined;
        state.gadgets[action.payload.id].collapsed = action.payload.value;
      }
    },
    toogleVisibility: (state, action) => {
      if (state.gadgets[action.payload]) {
        state.gadgets[action.payload].show = !state.gadgets[action.payload].show;
        state.fullWindow = undefined;
      }
    },
    updateGadgetOrder: (state, action) => {
      const updatedComponents = Array.from(state.gadgetsOrder);
      const [removedComponent] = updatedComponents.splice(action.payload.source, 1);
      updatedComponents.splice(action.payload.destination, 0, removedComponent);

      return {
        ...state,
        gadgetsOrder: updatedComponents,
      };
    },
    setBrowserinfo: (state, action) => {
      if (state.gadgets?.[action.payload.id]) {
        state.gadgets[action.payload.id].browserInfo = action.payload.value;
        if (!state.gadgets[action.payload.id].tableColumns) {
          const columns = action.payload.value.columns
            ? action.payload.value.columns.map((column, index) => {
                return {
                  ...column,
                  Header: column?.header,
                  Footer: action.payload.value.sums[index],
                  accessor: (column?.dataIndex ?? "").replace(".", "_"),
                };
              })
            : [];
          columns.unshift({ Header: "ZOOMINFO", accessor: "ZOOMINFO", hidden: true });
          state.gadgets[action.payload.id].tableColumns = columns;
          if (!state.gadgets[action.payload.id].hiddenColumns)
            state.gadgets[action.payload.id].hiddenColumns = ["ZOOMINFO"];
        }
        const data = action.payload.value.rows
          ? action.payload.value.rows.map((row) => {
              const obj = {};
              state.gadgets[action.payload.id].browserInfo.fields.forEach((field) => {
                obj[field.name.replace(".", "_")] =
                  row[state.gadgets[action.payload.id].browserInfo.fields.indexOf(field)];
              });
              return obj;
            })
          : [];
        state.gadgets[action.payload.id].tableData = data;
      }
    },
    setTableColumns: (state, action) => {
      if (state.gadgets?.[action.payload.id]) {
        const columns = action.payload.value
          ? action.payload.value.map((column) => ({
              ...column,
              Header: column?.caption,
              accessor: column?.name,
            }))
          : [];
        state.gadgets[action.payload.id].tableColumns = columns;
      }
    },
    setTableData: (state, action) => {
      if (state.gadgets?.[action.payload.id]) {
        state.gadgets[action.payload.id].response = action.payload.value;
        state.gadgets[action.payload.id].tableData = action.payload.value.rows;
      }
    },
    setSortField: (state, action) => {
      state.gadgets[action.payload.id].sort = action.payload.value;
    },
    //BROWSER
    setSelectedRow: (state, action) => _setSelectedRow(state, action),
  },
  extraReducers: (builder) => {
    builder.addCase(clear, () => {
      localStorage.setItem("login", null);
      return initialState;
    });
  },
});

export const {
  setLoading,
  gadgetParams,
  loadGadgets,
  updateGadgetOrder,
  setFullWindow,
  setPortalRef,
  expandAll,
  collapseAll,
  collapse,
  hideAll,
  showAll,
  toogleVisibility,
  setBrowserinfo,
  setTableColumns,
  setTableData,
  setSortField,
  setSelectedRow,
} = portalSlice.actions;

export default portalSlice.reducer;
