import React from "react";
import authenticate from "../../../services/authenticate";
import getSystemParams from "../../../services/getSystemParams";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BiLogIn } from "react-icons/bi";
import { set as setParams } from "../../../redux/features/systemParams/systemParamsSlice";
import { set as setSession } from "../../../redux/features/session/sessionSlice";
import CrmDropDown from "../../ui/CrmDropDown";
import PowererBy from "../../../assets/PowererBy";
import { setLoading } from "../../../redux/features/modals/modalsSlice";
import { languages } from "../../languages";
import LoginError from "./LoginError";
import { getToken } from "firebase/messaging";
import { messaging } from "../../../firebase";

const Authenticate = () => {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const userLan = useSelector((state) => state.settings.language);
  const [uniqueAuth, setUniqueAuth] = React.useState(false);
  const currentLang = useSelector((state) => state.settings.language) ?? "ENG";

  const [company, setCompany] = React.useState({ code: "", name: "" });
  const [branch, setBranch] = React.useState({ code: "", name: "" });
  const [user, setUser] = React.useState({ code: "", name: "" });

  const [companies, setCompanies] = React.useState([]);
  const [branches, setBranches] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  const [error, setError] = React.useState({ show: false, message: "" });

  const onCompanyChange = (company) => {
    let branchesArray = [];
    for (let i = 0; i < login.objs.length; i++) {
      if (login.objs[i].COMPANY == company.code) {
        branchesArray.push(login.objs[i]);
      }
    }
    branchesArray = [...new Map(branchesArray.map((item) => [item.BRANCH, item])).values()];
    setBranches(branchesArray);
    setBranch({
      code: branchesArray[0].BRANCH,
      name: branchesArray[0].BRANCHNAME,
    });

    let userArray = [];
    for (let i = 0; i < login.objs.length; i++) {
      if (login.objs[i].COMPANY == company.code && login.objs[i].BRANCH == branch.code) {
        userArray.push(login.objs[i]);
      }
    }
    userArray = [...new Map(userArray.map((item) => [item.REFID, item])).values()];
    setUsers(userArray);
    setUser({
      code: userArray[0].REFID,
      name: userArray[0].REFIDNAME,
    });
  };

  const onBranchChange = (branch) => {
    let userArray = [];
    for (let i = 0; i < login.objs.length; i++) {
      if ((login.objs[i].COMPANY = company.code) && (login.objs[i].BRANCH = branch.code)) {
        userArray.push(login.objs[i]);
      }
    }
    setUsers([...new Map(userArray.map((item) => [item.REFID, item])).values()]);
  };

  const handleAuthenticate = async (e) => {
    e && e.preventDefault();
    setError({ show: false, message: "" });
    dispatch(setLoading(true));
    try {
      const notification = await initMessaging();
      const Company = company.code === "" ? login.objs[0].COMPANY : company.code;
      const CompanyName = company.name === "" ? login.objs[0].COMPANYNAME : company.name;
      const Branch = branch.code === "" ? login.objs[0].BRANCH : branch.code;
      const BranchName = branch.name === "" ? login.objs[0].BRANCHNAME : branch.name;
      const RefID = user.code === "" ? login.objs[0].REFID : user.code;
      const RefIDName = user.name === "" ? login.objs[0].REFIDNAME : user.name;
      const Authenticate = await authenticate({
        log: {
          username: RefIDName,
          company: CompanyName,
          branch: BranchName,
          notificationToken: notification.token,
        },
        username: login.username,
        company: Company,
        branch: Branch,
        refID: RefID,
        module: 0,
        sn: login.sn,
        clientID: login.clientID,
        language: currentLang,
      });
      if (Authenticate.success) {
        const systhemParams = await getSystemParams({
          url: `http://${login.sn}.oncloud.gr/s1services`,
          clientID: Authenticate.clientID,
        });
        if (systhemParams.success) {
          const today = new Date();
          systhemParams.paramsDate = today.toLocaleDateString();
          dispatch(setParams(systhemParams));
          Authenticate.refIDName = RefIDName;
          dispatch(setSession({ ...Authenticate, notification }));
        } else {
          setError({ show: true, message: systhemParams.error });
          dispatch(setLoading(false));
        }
      } else {
        setError({ show: true, message: Authenticate.error });
        dispatch(setLoading(false));
      }
    } catch (error) {
      setError({ show: true, message: error.message });
      dispatch(setLoading(false));
    }
  };

  const inputs = [
    {
      controlId: "company",
      label: languages.login[userLan].company,
      type: "text",
      value: company.name,
      keyValue: company.code,
      inputMode: "none",
      data: companies,
      function: setCompany,
      functionOnChange: onCompanyChange,
      selected: company,
    },
    {
      controlId: "branch",
      label: languages.login[userLan].branch,
      type: "text",
      value: branch.name,
      keyValue: branch.code,
      inputMode: "none",
      data: branches,
      function: setBranch,
      functionOnChange: onBranchChange,
      selected: branch,
    },
    {
      controlId: "user",
      label: languages.login[userLan].user,
      type: "text",
      value: user.name,
      keyValue: user.code,
      inputMode: "none",
      data: users,
      function: setUser,
      functionOnChange: () => {},
      selected: user,
    },
  ];

  React.useEffect(() => {
    var coomm = login?.objs ? [...new Map(login.objs.map((item) => [item.COMPANY, item])).values()] : [];
    setCompanies(coomm);
    setCompany({
      code: coomm[0].COMPANY,
      name: coomm[0].COMPANYNAME,
    });
    let objs = login.objs;
    let branchesArray = [];
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].COMPANY == coomm[0].COMPANY) {
        branchesArray.push(objs[i]);
      }
    }
    branchesArray = [...new Map(branchesArray.map((item) => [item.BRANCH, item])).values()];
    setBranches(branchesArray);
    setBranch({
      code: branchesArray[0].BRANCH,
      name: branchesArray[0].BRANCHNAME,
    });

    let userArray = [];
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].COMPANY == coomm[0].COMPANY && objs[i].BRANCH == branchesArray[0].BRANCH) {
        userArray.push(objs[i]);
      }
    }
    userArray = [...new Map(userArray.map((item) => [item.REFID, item])).values()];
    setUsers(userArray);
    setUser({
      code: userArray[0].REFID,
      name: userArray[0].REFIDNAME,
    });
    if (coomm.length === 1) {
      setUniqueAuth(true);
      setTimeout(handleAuthenticate, 200);
    } else {
      dispatch(setLoading(false));
    }
  }, []);

  return (
    <div className="login-screen">
      <Card>
        <Form onSubmit={handleAuthenticate}>
          {inputs.map((item, index) => (
            <CrmDropDown
              label={item.label}
              type={item.type}
              name={item.controlId}
              inputMode={item.inputMode}
              required
              value={item.value}
              key={index}
              data={item.data}
              functionOnChange={item.functionOnChange}
              onChange={item.function}
              selected={item.selected}
              controlId={item.controlId}
            />
          ))}
          <Button
            disabled={uniqueAuth && !error.show}
            className="w-100 hoverIconAnimation-s mt-1"
            type="submit"
            variant={`${uniqueAuth ? "secondary" : "primary"}`}
          >
            <b>{languages.login[userLan].connectButton}</b> <BiLogIn />
          </Button>
        </Form>
      </Card>
      <LoginError show={error.show} setShow={setError} message={error.message} />
      <PowererBy />
    </div>
  );
};

export default Authenticate;

const initMessaging = async () => {
  if ("serviceWorker" in navigator) {
    const response = await navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then(async (registration) => {
        console.log("Service Worker registered with scope:", registration.scope);
        console.log("Requesting Notification permission...");
        const permision = await Notification.requestPermission()
          .then(async (permission) => {
            if (permission === "granted") {
              console.log("Notification permission granted.");
              return await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
                .then((res) => {
                  return { success: true, token: res };
                })
                .catch((er) => {
                  console.log(er);
                  return { success: false, error: er };
                });
            } else {
              console.log("Notification permission declined.");
              return { success: false, error: "Notification permission declined." };
            }
          })
          .catch((er) => {
            console.log(er);
            return { success: false, error: er };
          });
        return permision;
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
        return { success: false, error };
      });
    return response;
  }
};
