import React from "react";

const CheckBox = (props) => {
  return (
    <div
      className="custom-checkbox w-100 form-check d-flex flex-column p-0 m-0"
      style={{ flex: `1 1 100%`, overflow: "hidden" }}
    >
      <label
        className="form-check-label py-1"
        htmlFor={props.name}
        style={{
          whiteSpace: "nowrap",
          opacity: "0.65",
          fontSize: "0.85rem",
        }}
      >
        {props.label}
      </label>
      <input
        id={props.name}
        className="form-check-input m-0 ps-1"
        type="checkbox"
        name={props.name}
        checked={props.value == 1}
        onChange={(e) => props.change(e.target.checked ? 1 : 0)}
        style={{ minHeight: "2rem", minWidth: "2rem" }}
      />
    </div>
  );
};

export default CheckBox;
