import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getCalendarSelectedDates } from "../../../../redux/selectors";
import { languages } from "../../../languages";

const Buttons = ({ tabID, calendarApi, setShowDetails }) => {
  const selectedDates = useSelector((state) => getCalendarSelectedDates(state, tabID));
  const currentLang = useSelector((state) => state.settings.language);

  return (
    <div className="mb-2 d-flex justify-content-between">
      <ButtonGroup>
        <Button
          variant="secondary"
          onClick={() => {
            setShowDetails(true);
            calendarApi.changeView("dayGridMonth", selectedDates?.start);
            selectedDates?.start && calendarApi.select(selectedDates?.start);
          }}
        >
          {languages.calendar[currentLang].month}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setShowDetails(true);
            calendarApi.changeView("dayGridWeek", selectedDates?.start);
            selectedDates?.start && calendarApi.select(selectedDates?.start);
          }}
        >
          {languages.calendar[currentLang].week}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setShowDetails(false);
            calendarApi.changeView("timeGridDay", selectedDates?.start);
            // selectedDates?.start && calendarApi.select(selectedDates?.start);
          }}
        >
          {languages.calendar[currentLang].day}
        </Button>
      </ButtonGroup>
      <Button
        variant="secondary"
        onClick={() => {
          calendarApi.today();
          const today = new Date();
          console.log(calendarApi.view);
          if (calendarApi.view.type != "timeGridDay") calendarApi.select(today);
        }}
      >
        {languages.calendar[currentLang].today}
      </Button>
    </div>
  );
};

export default Buttons;
