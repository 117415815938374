import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../../redux/features/settings/settingsSlice";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import { languages } from "../../languages";

const Language = () => {
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.settings.language);
  const boundRef = React.useRef(null);
  const tableRef = React.useRef(null);
  const pickerRef = React.useRef(null);

  const [openPicker, setOpenPicker] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);

  const data = { ENG: "English", GRE: "Ελληνικά" };

  React.useEffect(() => {
    if (openPicker) {
      const clickListener = (e) => {
        const cliii = pickerRef.current.contains(e.target) || boundRef.current.contains(e.target);
        if (!cliii) {
          setOpenPicker(false);
          setIsFocused(false);
        }
      };

      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [openPicker]);

  return (
    <div className="s1language w-100" style={{ flex: `100%`, overflow: "hidden" }}>
      <InputGroup ref={boundRef} className={`crm-floating-with-icon${isFocused ? " underline-focus" : ""}`}>
        <FloatingLabel
          controlId="language"
          label={languages.login[currentLang].language}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className="d-flex w-100 ps-1 border-0"
            readOnly={true}
            value={data[currentLang]}
            name="language"
            placeholder={languages.login[currentLang].language}
            onClick={() => {
              setOpenPicker((x) => !x);
              setIsFocused((x) => !x);
            }}
            onFocus={(e) => e.target.blur()}
          />
        </FloatingLabel>
        <div
          className="d-flex align-items-center justify-content-center me-1"
          onClick={() => {
            setOpenPicker((x) => !x);
            setIsFocused((x) => !x);
          }}
        >
          <BsChevronDown color="var(--bs-primary)" size="1.5rem" />
        </div>
      </InputGroup>
      {openPicker && (
        <div
          className="input-picker-div custom"
          ref={pickerRef}
          style={{
            left: boundRef.current.getBoundingClientRect().left,
            width: boundRef.current.getBoundingClientRect().width,
            top:
              boundRef.current.getBoundingClientRect().bottom -
              (boundRef.current.getBoundingClientRect().bottom + 200 > window.innerHeight
                ? 200 + boundRef.current.getBoundingClientRect().height
                : 0),
            height: "200px",
            overflow: "hidden",
          }}
        >
          <div
            className="input-picker w-100 h-100"
            style={{
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div className="input-picker-table w-100" ref={tableRef}>
              <div>
                {Object.keys(data).map((key, index) => {
                  return (
                    <div
                      key={index}
                      className={`input-picker-table-row${
                        currentLang == key ? " input-picker-table-row-selected hovered" : ""
                      }`}
                      onClick={() => {
                        setOpenPicker(false);
                        setIsFocused(false);
                        dispatch(setLanguage(key));
                      }}
                    >
                      <span className="">{data[key]}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Language;
