import React from "react";
import { useDispatch, useSelector } from "react-redux/es";
import { set } from "../../../redux/features/settings/settingsSlice";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { languages } from "../../languages";
const CalendarColour = ({ tabID }) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.app);
  const currentLang = useSelector((state) => state.settings.language);

  const colorRef = React.useRef();

  const [value, setValue] = React.useState(settings.calendarColor ?? "#0d6efd");

  React.useEffect(() => setValue(settings.calendarColor ?? "#0d6efd"), [tabID]);

  const handleChange = (e) => {
    setValue(e.target.value);
    dispatch(set({ key: "calendarColor", value: e.target.value }));
  };

  return (
    <div className="s1-calendarColor w-100" style={{ flex: `100%` }}>
      <InputGroup className={`crm-floating-with-icon`}>
        <FloatingLabel
          controlId="calendarColor"
          label={languages.settings[currentLang].calendarColor}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            ref={colorRef}
            className="d-flex w-100 ps-1 border-0"
            value={value}
            name="calendarColor"
            placeholder={languages.settings[currentLang].calendarColor}
            type="color"
            onChange={handleChange}
            style={{ paddingTop: "2rem" }}
          />
        </FloatingLabel>
      </InputGroup>
    </div>
  );
};

export default CalendarColour;
