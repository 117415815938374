import axios from "axios";
import { getClientID, getURL } from "../redux/selectors";
import store from "../redux/store";

const getBrowserData = async ({ reqID, start }) => {
  const state = store.getState();
  const url = getURL(state);
  const clientID = getClientID(state);
  const limit = state.settings.app.browserLimit ?? process.env.REACT_APP_GETBROWSERDATA_LIMIT;

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "getBrowserData",
        clientID,
        reqID,
        start,
        limit,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default getBrowserData;
