import { createAction, createSlice } from "@reduxjs/toolkit";
import { clear } from "../session/sessionSlice";
import { _addItemQty, _deleteItemFromCart, _setItemQty, _substractItemQty } from "./cart";

const initialState = {};

const showRoomsSlice = createSlice({
  name: "showRooms",
  initialState,
  reducers: {
    set: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clear, () => {
      return null;
    });
    builder.addCase(addItemQty, (state, action) => _addItemQty(state, action));
    builder.addCase(substractItemQty, (state, action) => _substractItemQty(state, action));
    builder.addCase(setItemQty, (state, action) => _setItemQty(state, action));
    builder.addCase(deleteItemFromCart, (state, action) => _deleteItemFromCart(state, action));
  },
});

export const addItemQty = createAction("showRooms/cart/add");
export const substractItemQty = createAction("showRooms/cart/substract");
export const setItemQty = createAction("showRooms/cart/setQty");
export const deleteItemFromCart = createAction("showRooms/cart/deleteItem");

export const { set } = showRoomsSlice.actions;

export default showRoomsSlice.reducer;
