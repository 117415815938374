import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommandParams, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import { TiDeleteOutline } from "react-icons/ti";
import { BsCalendar3 } from "react-icons/bs";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";
import { calculate } from "../../../services/calculate";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";

const S1CalendarDate = ({ element, screenState, tabID, grid }) => {
  const isDateTime = element.dataType == "DateTime";
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const currentGridLine = grid?.currentLine;

  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const model = screenState == "form" && formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0];
  const data = useSelector((state) => getRecData(state, tabID));

  const dateRef = React.useRef(null);
  const contRef = React.useRef(null);

  const [isFocused, setIsFocused] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [viewValue, setViewValue] = React.useState("");

  const handleCalculate = async (value) => {
    if (grid) {
      dispatch(setGridChange({ tabID, name: grid.model, key, value }));
    } else {
      dispatch(setChange({ tabID, table, key, value }));
      model?.updates && (await calculate(tabID));
    }
  };

  const handleClear = () => {
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else {
      handleCalculate("");
    }
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value);
      setViewValue(element.value);
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    }
  }, [screenState]);

  React.useEffect(() => {
    if (screenState == "form" && !grid) {
      if (data?.[table]?.[0]?.[key] && data?.[table]?.[0]?.[key] != "") {
        setValue(dateConverter(data?.[table]?.[0]?.[key]));
        setViewValue(displayConverter(data?.[table]?.[0]?.[key]));
      } else {
        setValue("");
        setViewValue("");
      }
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if (filters?.data?.[table]?.[key] && filters?.data?.[table]?.[key] != "") {
      setValue(dateConverter(filters?.data?.[table]?.[key]));
      setViewValue(displayConverter(filters?.data?.[table]?.[key]));
    } else {
      setValue("");
      setViewValue("");
    }
  }, [filters?.data?.[table]?.[key]]);

  React.useEffect(() => {
    if (grid) {
      if (currentGridLine?.line?.[key] && currentGridLine?.line?.[key] != "") {
        setValue(dateConverter(currentGridLine?.line?.[key]));
        setViewValue(displayConverter(currentGridLine?.line?.[key]));
      } else {
        setValue("");
        setViewValue("");
      }
    }
  }, [currentGridLine?.line?.[key]]);

  const handleChange = (value) => {
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: isDateTime ? dateTimeConverter(value) : dateConverter(value) }));
    } else if (screenState == "form") {
      handleCalculate(isDateTime ? dateTimeConverter(value) : dateConverter(value));
    }
  };

  React.useEffect(() => {
    if (isFocused) {
      dateRef.current.showPicker();
      dateRef.current.click();
      dateRef.current.focus();
    }
  }, [isFocused]);
  return (
    <div
      className="s1calendardate w-100"
      ref={contRef}
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <InputGroup className={`crm-floating-with-icon${element.required && value == "" ? " input-required" : ""}`}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            onClick={() => {
              if (screenState != "form" || (screenState == "form" && objectState != "view")) {
                setIsFocused((x) => !x);
              }
            }}
            className={`d-flex w-100 ps-1 border-0${value != "" ? " date-input-has-value" : ""}${
              element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
            }`}
            disabled={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            readOnly={true}
            value={viewValue}
            name={element.name}
            placeholder={element.caption}
          />
        </FloatingLabel>
        {(screenState == "dialog" || (screenState == "form" && objectState != "view")) &&
          value != "" &&
          !element.readOnly && (
            <div className="d-flex align-items-center justify-content-center me-1" onClick={handleClear}>
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
        {!(element.readOnly || (screenState == "form" && commandParams.readonly)) && (
          <div
            className="d-flex align-items-center justify-content-center"
            role="button"
            onClick={() => {
              if (screenState != "form" || (screenState == "form" && objectState != "view")) {
                setIsFocused((x) => !x);
              }
            }}
          >
            <BsCalendar3
              className={
                element.readOnly ||
                (screenState == "form" && commandParams.readonly) ||
                (screenState == "form" && objectState == "view")
                  ? "disabled"
                  : ""
              }
              color="var(--bs-primary)"
              size="20px"
            />
          </div>
        )}
      </InputGroup>
      <input
        type="date"
        ref={dateRef}
        value={value}
        className="text-truncate"
        onBlur={() => setIsFocused(false)}
        disabled={
          element.readOnly ||
          (screenState == "form" && commandParams.readonly) ||
          (screenState == "form" && objectState == "view")
        }
        onChange={(e) => !element.readOnly && handleChange(e.target.value)}
        style={{
          pointerEvents: "none",
          position: "fixed",
          top: contRef?.current?.getBoundingClientRect()?.bottom,
          maxWidth: 0,
          maxHeight: 0,
          backgroundColor: "transparent",
          border: 0,
          color: "transparent",
        }}
      />
    </div>
  );
};

export default S1CalendarDate;

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

const dateConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return value;
  }
};

const dateTimeConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return `${[d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-")} 00:00:00`;
  } else {
    return value;
  }
};

const displayConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
  } else {
    return value;
  }
};
