import React from "react";
import { Button } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { killTab } from "../../../../redux/features/ui/uiSlice";

const Back = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(killTab(-2));
  };
  return (
    <Button variant="secondary" onClick={handleClose}>
      <AiOutlineArrowLeft />
    </Button>
  );
};

export default Back;
