import React from "react";
import { useDispatch } from "react-redux";
import { toogleMenu } from "../../../redux/features/modals/modalsSlice";
import { setTab } from "../../../redux/features/ui/uiSlice";
import { AiOutlineUser } from "react-icons/ai";

const User = () => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        // dispatch(toogleMenu());
        // dispatch(setSettingsModal(true));
        dispatch(toogleMenu());
        dispatch(setTab(-3));
      }}
    >
      <AiOutlineUser size="1.5rem" />
    </div>
  );
};

export default User;
