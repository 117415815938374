import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportData, getReportInfo } from "../../../../redux/selectors";
import { MdOutlineKeyboardDoubleArrowRight, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { setLoading, setReportData } from "../../../../redux/features/ui/uiSlice";
import serviceGetReportData from "../../../../services/getReportData";

const ArrowsNext = ({ tabID }) => {
  const dispatch = useDispatch();
  const reportInfo = useSelector((state) => getReportInfo(state, tabID));
  const reportData = useSelector((state) => getReportData(state, tabID));

  const goToPage = async (pagenum) => {
    dispatch(setLoading({ show: true, tabID }));
    const reportData = await serviceGetReportData({ reqID: reportInfo.reqID, pagenum });
    if (reportData.success) {
      reportData.pageNum = pagenum;
      dispatch(setReportData({ value: reportData, tabID }));
    }
    dispatch(setLoading({ show: false, tabID }));
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() => reportData.pageNum < reportInfo.npages && goToPage(reportData.pageNum + 1)}
        style={{
          userSelect: "none",
          cursor: reportData.pageNum < reportInfo.npages ? "pointer" : "default",
          opacity: reportData.pageNum < reportInfo.npages ? 1 : 0.5,
        }}
      >
        <MdOutlineKeyboardArrowRight size="1.3rem" />
      </div>
      <div
        className="d-flex align-items-center justify-content-start"
        onClick={() => reportData.pageNum < reportInfo.npages && goToPage(reportInfo.npages)}
        style={{
          userSelect: "none",
          cursor: reportData.pageNum < reportInfo.npages ? "pointer" : "default",
          opacity: reportData.pageNum < reportInfo.npages ? 1 : 0.5,
        }}
      >
        <MdOutlineKeyboardDoubleArrowRight size="1.3rem" />
      </div>
    </div>
  );
};

export default ArrowsNext;
