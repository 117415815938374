const ScreenSpinner = () => {
  return (
    <div className="screen-loader d-flex justify-content-center align-items-center">
      <div className="bouncing-loader ">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default ScreenSpinner;
