import React from "react";
import { Button, ButtonGroup, FloatingLabel, Form, InputGroup, Modal } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import { HiX, HiCheck } from "react-icons/hi";
import { languages } from "../languages";
import { useSelector } from "react-redux";

const CrmDropDown = (props) => {
  const [openPicker, setOpenPicker] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({ code: "", name: "" });
  const [locateObj, setLocateObj] = React.useState({ code: null, name: null });
  const currentLang = useSelector((state) => state.settings.language);

  const handleAccept = () => {
    setOpenPicker(false);
    if (props.controlId == "company") {
      props.onChange(selectedRow);
      props.functionOnChange(selectedRow);
    } else if (props.controlId == "branch") {
      props.onChange(selectedRow);
      props.functionOnChange(selectedRow);
    } else {
      props.onChange(selectedRow);
      props.functionOnChange(selectedRow);
    }
  };

  React.useEffect(() => {
    if (openPicker) {
      if (props.controlId == "company") setLocateObj({ code: "COMPANY", name: "COMPANYNAME" });
      if (props.controlId == "branch") setLocateObj({ code: "BRANCH", name: "BRANCHNAME" });
      if (props.controlId == "user") setLocateObj({ code: "REFID", name: "REFIDNAME" });
      setSelectedRow(props.selected);
    }
  }, [openPicker]);

  return (
    <div className="crm-input">
      <InputGroup
        className="crm-floating-with-icon my-1"
        style={{
          borderBottom: props.condition ? "2px solid var(--bs-danger)" : "1px solid var(--bs-gray)",
        }}
      >
        <FloatingLabel
          controlId={props.controlId}
          label={props.label}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className="crm-input ps-1 mt-1"
            autoCapitalize="off"
            placeholder={props.label}
            type={props.type}
            name={props.controlId}
            inputMode={props.inputMode}
            required
            readOnly
            onClick={() => setOpenPicker(true)}
            value={props.value}
            style={{
              border: "0",
            }}
            // onClick={(e) => {
            //   e.target.blur();
            // }}
          />
        </FloatingLabel>
        <div className="d-flex align-items-center justify-content-center" onClick={() => setOpenPicker(true)}>
          <BsChevronDown size="1.5rem" color={"var(--bs-primary)"} />
        </div>
      </InputGroup>
      <Modal
        show={openPicker}
        fullscreen
        animation={false}
        className="d-flex align-items-end"
        dialogClassName="p-3"
        contentClassName="rounded overflow-hidden shadow"
      >
        <div
          className="input-picker  w-100 h-100"
          style={{
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div className="input-picker-table w-100">
            <div>
              {props?.data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`input-picker-table-row${
                      selectedRow.code == item[locateObj.code] ? " input-picker-table-row-selected" : ""
                    }`}
                    onClick={() => setSelectedRow({ code: item[locateObj.code], name: item[locateObj.name] })}
                  >
                    <span className="">{item[locateObj.name]}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Modal.Footer className="picker-buttons">
          <ButtonGroup>
            <Button
              variant="outline-secondary"
              className="rounded-0"
              onClick={() => {
                setOpenPicker(false);
              }}
            >
              <HiX size={25} color="var(--bs-danger)" className="me-2" />
              <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                {languages.generalText[currentLang].closing}
              </div>
            </Button>
            <Button className="rounded-0" variant="outline-secondary" onClick={handleAccept}>
              <HiCheck size={25} color="var(--bs-success)" className="me-2" />
              <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                {languages.generalText[currentLang].apply}
              </div>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CrmDropDown;
