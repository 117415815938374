import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import { setChange, setFilter, setGridChange } from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import { InputGroup, FloatingLabel, Form } from "react-bootstrap";
import { calculate } from "../../../services/calculate";
const S1TextArea = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getRecData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model = screenState == "form" && formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0];

  const [value, setValue] = React.useState("");

  const handleCalculate = async (value) => {
    if (grid) {
      dispatch(setGridChange({ tabID, name: grid.model, key, value }));
    } else {
      dispatch(setChange({ tabID, table, key, value }));
      model?.updates && (await calculate(tabID, objectState));
    }
  };

  React.useEffect(() => {
    if (screenState == "dialog") {
      setValue(element.value);
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    }
  }, []);

  React.useEffect(() => {
    if (screenState == "form") {
      setValue(data?.[table]?.[0]?.[key] ?? "");
    }
  }, [data?.[table]?.[0]?.[key]]);

  const handleChange = (e) => {
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: e.target.value }));
    } else if (screenState == "form") {
      handleCalculate(e.target.value);
    }
  };

  const handleClear = () => {
    setValue("");
    if (screenState == "dialog") {
      dispatch(setFilter({ tabID, table, key, value: "" }));
    } else if (screenState == "form") {
      handleCalculate("");
    }
  };

  return (
    <div
      className={`s1textarea w-100${element.visible ? "" : " hidden"}`}
      style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}
    >
      <InputGroup className={`crm-floating-with-icon${element.required && value == "" ? " input-required" : ""}`}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            type="textarea"
            as="textarea"
            autoCapitalize="off"
            rows={8}
            className={`w-100 ps-1 border-0${
              element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
            }`}
            disabled={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            readOnly={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            style={{ border: "0", height: "auto", resize: "none" }}
            name={element.name}
            placeholder={element.caption}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={handleChange}
          />
        </FloatingLabel>
        {screenState == "form" && objectState != "view" && value != "" && !element.readOnly && (
          <div className={`d-flex align-items-center justify-content-center me-1`} onClick={handleClear}>
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

export default S1TextArea;
