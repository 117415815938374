import { setError } from "../../redux/features/modals/modalsSlice";
import { killTab, setBrowserInfo, setLoading, setScreenState } from "../../redux/features/ui/uiSlice";
import {
  getData,
  getFormDesign,
  getObjectState,
  getScreenState,
  getSelectedRow,
  getTableData,
  getBrowserInfo,
  getWasInTab,
  getJobForNew,
} from "../../redux/selectors";
import store from "../../redux/store";
import delData from "../../services/delData";

const deleteRecord = async (tabID) => {
  const state = store.getState();
  const dispatch = store.dispatch;
  dispatch(setLoading({ show: true, tabID }));
  const screenState = getScreenState(state, tabID);
  const browserInfo = getBrowserInfo(state, tabID);
  const wasInTab = getWasInTab(state, tabID);
  const jobForNew = getJobForNew(state, tabID);
  const objectState = getObjectState(state, tabID);
  let id2Delete;

  if (screenState == "browser") {
    const selectedRow = getSelectedRow(state, tabID);
    const tableData = getTableData(state, tabID);
    id2Delete = tableData[selectedRow].ZOOMINFO.substring(tableData[selectedRow].ZOOMINFO.indexOf(";") + 1);
  } else if (screenState == "form") {
    const data = getData(state, tabID);
    const key = getFormDesign(state, tabID).key;
    const tableName = key.split(".")[0];
    const fieldName = key.split(".")[1];
    id2Delete = data?.[tableName]?.[0]?.[fieldName] ?? "";
  }
  const response = await delData(id2Delete, tabID);
  if (response.success) {
    // const browserInfo = await getBrowserInfo(tabID);
    // dispatch(setBrowserInfo({ value: browserInfo, tabID }));
    // if (screenState == "form") {
    // }
    if (wasInTab > 0 && jobForNew) dispatch(killTab(tabID));
    else dispatch(setScreenState({ value: browserInfo ? "browser" : "dialog", tabID }));
  } else {
    console.log(response);
    dispatch(setError({ show: true, message: response.error }));
  }
  dispatch(setLoading({ show: false, tabID }));
};

export default deleteRecord;
