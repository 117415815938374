import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const deviceInfoSlice = createSlice({
  name: "deviceInfo",
  initialState,
  reducers: {
    setCoords: (state, action) => {
      state.longitude = action.payload.longitude;
      state.latitude = action.payload.latitude;
    },
    setInfo: (state, action) => {
      state.deviceName = action.payload.deviceName;
      state.deviceId = action.payload.deviceId;
    },
  },
});

export const { setInfo, setCoords } = deviceInfoSlice.actions;

export default deviceInfoSlice.reducer;
