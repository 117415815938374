import React from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setLoading, setLogOut } from "../../../redux/features/modals/modalsSlice";
import { clear as clearSession } from "../../../redux/features/session/sessionSlice";
import axios from "axios";
import { BiQuestionMark } from "react-icons/bi";
import { HiCheck, HiX } from "react-icons/hi";
import { languages } from "../../languages";

const ConfirmLogOut = () => {
  const show = useSelector((state) => state.modals.logout);
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const currentLang = useSelector((state) => state.settings.language);

  const handleLogOut = async () => {
    dispatch(setLoading(true));
    await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/logout`, {
      key: session?.loginid,
    });
    dispatch(clearSession());
    dispatch(setLoading(false));
  };
  return (
    <Modal
      // backdropClassName="above-all-backdrop print"
      // dialogClassName="above-all-dialog"
      show={show}
      animation={false}
      onHide={() => dispatch(setLogOut(false))}
      centered
      size="lg"
    >
      <span
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%,-50%)",
          backgroundColor: "var(--bs-warning)",
          color: "var(--bs-dark)",
          borderRadius: "50%",
        }}
        className="d-flex justify-content-center align-items-center shadow"
      >
        <BiQuestionMark size="3rem" className="m-1 p-1" />
      </span>

      <Modal.Header className="p-2 ps-3 pb-0 border-0" closeButton />
      <Modal.Body className="d-flex justify-content-start align-items-center m-0" style={{ userSelect: "none" }}>
        {languages.generalText[currentLang].sureAboutLogout}
      </Modal.Body>
      <Modal.Footer className="picker-buttons">
        <ButtonGroup>
          <Button variant="outline-secondary" className="rounded-0" onClick={() => dispatch(setLogOut(false))}>
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].cancel}
            </div>
          </Button>
          <Button
            className="rounded-0"
            variant="outline-secondary"
            onClick={async () => {
              handleLogOut();
            }}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].logout}
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmLogOut;
