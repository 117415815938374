import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommandParams, getFilters, getFormDesign, getObjectState, getRecData } from "../../../redux/selectors";
import { calculate } from "../../../services/calculate";
import {
  changeToBuffer,
  setChange,
  setFilter,
  setGridChange,
  setObjectState,
} from "../../../redux/features/ui/uiSlice";
import { autoCalcFields } from "../../../logic/autoCalcFields";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";

const S1NumberField = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getRecData(state, tabID));
  const filters = useSelector((state) => getFilters(state, tabID)?.data);
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const currentGridLine = grid?.currentLine;

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model = screenState == "form" && formDesign && formDesign.model[table].fields.filter((el) => el.name == key)[0];

  const [value, setValue] = React.useState("");

  const decimals = element?.decimals ?? 2;

  const handleCalculate = async (value) => {
    if (grid) {
      dispatch(setGridChange({ tabID, name: grid.model, key, value }));
    } else {
      dispatch(setChange({ tabID, table, key, value }));
      (autoCalcFields.indexOf(element.name) > -1 || model?.updates) && (await calculate(tabID, objectState));
    }
  };

  React.useEffect(() => {
    if (screenState == "dialog" && !grid) {
      setValue(element.value);
      dispatch(setFilter({ tabID, table, key, value: element.value }));
    }
  }, [screenState]);

  React.useEffect(() => {
    if (screenState == "form" && !grid && (data?.[table]?.[0]?.[key] || data?.[table]?.[0]?.[key] == "")) {
      if (data?.[table]?.[0]?.[key] == "") {
        setValue("");
      } else {
        setValue(formatNumber(data?.[table]?.[0]?.[key], decimals));
      }
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if ((currentGridLine?.line?.[key] || currentGridLine?.line?.[key] == "") && grid) {
      if (currentGridLine?.line?.[key] == "") {
        setValue("");
      } else {
        setValue(formatNumber(currentGridLine?.line?.[key], decimals));
      }
    }
  }, [currentGridLine?.line?.[key]]);

  const handleChange = (e) => {
    if (screenState == "dialog") {
      if (filters?.[table]?.[key] != e.target.value) {
        dispatch(setFilter({ tabID, table, key, value: e.target.value }));
      }
    } else if (screenState == "form" && !grid) {
      var preValue = data?.[table]?.[0]?.[key] ?? "";
      var curValue = e.target.value.replace(/\./g, "").replace(",", ".");

      parseFloat(preValue) != parseFloat(curValue) && handleCalculate(parseFloat(curValue));
    } else if (screenState == "form" && grid) {
      var curValue = e.target.value.replace(/\./g, "").replace(",", ".");
      handleCalculate(parseFloat(curValue));
    }
  };

  const handleInput = (e) => {
    const { value } = e.target;
    setValue(value.replace(/[^0-9.,]/g, ""));
  };

  return (
    <div
      className="s1numberfield w-100"
      style={{
        flex: `1 1 ${element.flex * 100}%`,
        overflow: "hidden",
        visibility: element.visible ? "visible" : "hidden",
      }}
    >
      <InputGroup className={`crm-floating-with-icon${element.required && value == "" ? " input-required" : ""}`}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            inputMode="decimal"
            className={`w-100 ps-1 border-0${
              element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
            }`}
            disabled={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            readOnly={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            name={element.name}
            placeholder={element.caption}
            value={value}
            onChange={(e) => handleInput(e)}
            onBlur={handleChange}
          />
        </FloatingLabel>
        {objectState != "view" && value != "" && !element.readOnly && (
          <div
            className={`d-flex align-items-center justify-content-center${element?.maskEnabled ? " me-1" : ""}`}
            onClick={() => handleCalculate("")}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

export default S1NumberField;

const formatNumber = (value, decimals) => {
  return parseFloat(value)
    .toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
    .replace(/€|\u20AC|\xA0/g, "");
};
