import React from "react";
import TopBarOnSettings from "../../bars/top bars/settings/TopBarOnSettings";
import App from "./App";
import Calendar from "./Calendar";
import CloudStorage from "./CloudStorage";
import { useSelector } from "react-redux";
import { languages } from "../../languages";
import { getCurrentTabIndex } from "../../../redux/selectors";

const Settings = () => {
  const curentLan = useSelector((state) => state.settings.language);
  const fromAuth = useSelector((state) => state.settings);
  const [activePanel, setActivePanel] = React.useState(fromAuth ? "cloud" : "app");
  const tabID = useSelector((state) => getCurrentTabIndex(state));

  React.useEffect(() => {
    if (tabID != -2) {
      setActivePanel("app");
    }
  }, [tabID]);

  return (
    <>
      <TopBarOnSettings />
      <div className="s1-settings">
        <nav className="overflow-auto d-flex">
          <div
            className="nav nav-tabs"
            role="tablist"
            style={{
              inlineSize: "max-content",
              flex: 1,
              borderBottom: "2px solid var(--bs-border-color)",
              flexWrap: "nowrap",
            }}
          >
            <div
              className={`form-tab text-truncate px-1 ${activePanel == "app" ? "active" : ""}`}
              onClick={() => setActivePanel("app")}
              style={{
                userSelect: "none",
                cursor: "pointer",
                display: "flex",
                borderBottom: activePanel == "app" ? "2px solid var(--bs-primary)" : "none",
                marginBottom: activePanel == "app" ? "-2px" : "0",
                fontSize: activePanel == "app" ? "1.3rem" : "1.1rem",
                fontWeight: activePanel == "app" ? 500 : 400,
                alignItems: "flex-end",
              }}
            >
              {languages.settings[curentLan].app}
            </div>
            <div
              className={`form-tab text-truncate px-1 ${activePanel == "calendar" ? "active" : ""}`}
              onClick={() => setActivePanel("calendar")}
              style={{
                userSelect: "none",
                cursor: "pointer",
                display: "flex",
                borderBottom: activePanel == "calendar" ? "2px solid var(--bs-primary)" : "none",
                marginBottom: activePanel == "calendar" ? "-2px" : "0",
                fontSize: activePanel == "calendar" ? "1.3rem" : "1.1rem",
                fontWeight: activePanel == "calendar" ? 500 : 400,
                alignItems: "flex-end",
              }}
            >
              {languages.settings[curentLan].calendar}
            </div>
            <div
              className={`form-tab text-truncate px-1 ${activePanel == "cloud" ? "active" : ""}`}
              onClick={() => setActivePanel("cloud")}
              style={{
                userSelect: "none",
                cursor: "pointer",
                display: "flex",
                borderBottom: activePanel == "cloud" ? "2px solid var(--bs-primary)" : "none",
                marginBottom: activePanel == "cloud" ? "-2px" : "0",
                fontSize: activePanel == "cloud" ? "1.3rem" : "1.1rem",
                fontWeight: activePanel == "cloud" ? 500 : 400,
                alignItems: "flex-end",
              }}
            >
              {languages.settings[curentLan].cloudStorage}
            </div>
          </div>
        </nav>
        <div className="tab-content">
          <div
            className={`mt-2 ${activePanel == "app" ? "active show" : ""}`}
            role="tabpanel"
            style={{ display: activePanel == "app" ? "flex" : "none" }}
          >
            <App />
          </div>
          <div
            className={`mt-2 ${activePanel == "calendar" ? "active show" : ""}`}
            role="tabpanel"
            style={{ display: activePanel == "calendar" ? "flex" : "none" }}
          >
            <Calendar />
          </div>
          <div
            className={`mt-2 ${activePanel == "cloud" ? "active show" : ""}`}
            role="tabpanel"
            style={{ display: activePanel == "cloud" ? "flex" : "none" }}
          >
            <CloudStorage />
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
