import React from "react";
import { useSelector } from "react-redux";
import { getDataCaption, getObjectState } from "../../../../../redux/selectors";
import { languages } from "../../../../languages";

const FormCaption = ({ tabID }) => {
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const dataCaption = useSelector((state) => getDataCaption(state, tabID));
  const currentLang = useSelector((state) => state.settings.language);

  return (
    <div className="caption text-truncate d-flex align-items-center justify-content-center">
      <span className="text-truncate" style={{ fontSize: "1.1rem" }}>
        {objectState == "new" ? languages.generalText[currentLang].newRecord : dataCaption ?? ""}
      </span>
    </div>
  );
};

export default FormCaption;
