import React from "react";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getFilters } from "../../../redux/selectors";
import { setDateRanges } from "../../../redux/features/ui/uiSlice";
import { Dropdown } from "react-bootstrap";
// import DateRangeModal from "../modals/DateRangeModal";

const S1DateRangeDropDown = ({ parentIndex, tabID }) => {
  const [selectedRow, setSelectedRow] = React.useState();

  const dispatch = useDispatch();
  const filters = useSelector((state) => getFilters(state, tabID));
  const dateranges = useSelector((state) => state.systemParams.dateranges);

  React.useEffect(() => {
    setSelectedRow(filters?.daterange?.[parentIndex] ?? "");
  }, [filters?.daterange?.[parentIndex]]);

  const handleClickOnRow = (key) => {
    dispatch(setDateRanges({ key: parentIndex, value: key, tabID }));
  };

  const DTDropDown = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      style={{ cursor: "pointer", textDecoration: "none", color: "var(--bs-dark)" }}
      className="d-flex align-items-center justify-cintent-center user-select-none"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  return (
    <React.Fragment>
      <Dropdown className="d-flex" align="end">
        <Dropdown.Toggle as={DTDropDown}>
          <div className="d-flex align-items-center justify-content-center">
            <BsChevronDown color="var(--bs-primary)" size="20px" role="button" />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ maxHeight: "50vh", overflowY: "auto" }}>
          {Object.keys(dateranges).map((key) => {
            return (
              <Dropdown.Item key={key} onClick={() => handleClickOnRow(key)}>
                {dateranges[key].name}
              </Dropdown.Item>
              // <div
              //   key={key}
              //   onClick={() => setSelectedRow(key)}
              //   className={`s1editor-row border px-1 ${selectedRow == key ? "rounded border-warning shadow" : ""}`}
              //   style={{
              //     backgroundColor: selectedRow == key ? "var(--bs-gray-400)" : "transparent",
              //     color: selectedRow == key ? "var(--bs-dark)" : "white",
              //   }}
              // >
              //   <div className="my-3 mx-4">{dateranges[key].name}</div>
              // </div>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default S1DateRangeDropDown;
