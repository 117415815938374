const Spinner = () => {
  return (
    <div className="loadModal d-flex justify-content-center align-items-center">
      <div className="bouncing-loader ">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default Spinner;
