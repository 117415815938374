import React from "react";
import { TiPlus } from "react-icons/ti";

const New = () => {
  return (
    <div className="d-flex align-items-center justify-content-start" style={{ cursor: "pointer" }}>
      <TiPlus size="1.3rem" className="me-1" />
      Νέα
    </div>
  );
};

export default New;
