import axios from "axios";
import store from "../redux/store";
import { getClientID, getCommandByIndex, getSN, getURL } from "../redux/selectors";

const getData = async ({ key, locateinfo, tabID, vObj }) => {
  const state = store.getState();
  const url = getURL(state);
  const clientID = getClientID(state);
  const serialnumber = getSN(state);
  const command = tabID ? getCommandByIndex(state, tabID) : undefined;
  const object = tabID ? `${serialnumber}/object=${command}` : vObj;

  try {
    const data = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "getData",
        clientID,
        object,
        key,
        locateinfo,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    });
    return data.data;
  } catch (error) {
    console.log(error);
    return { success: false, error: error.message };
  }
};

export default getData;
