import React from "react";
import { Button } from "react-bootstrap";
import { TbSignature } from "react-icons/tb";
import { useSelector } from "react-redux";
import { getObjectState } from "../../../../redux/selectors";
import ModalSignature from "../signature/ModalSignature";
import { setError } from "../../../../redux/features/modals/modalsSlice";
import { useDispatch } from "react-redux";
import { canSignature } from "../../../../redux/features/ui/uiSlice";

const Signature = ({ tabID, element }) => {
  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const dropbox = useSelector((state) => state.settings?.cloudStorage?.dropbox);
  const [show, setShow] = React.useState(false);

  const handleClick = () => {
    if (!dropbox) {
      dispatch(setError({ show: true, message: "Δεν έχετε συνδεδεμένο λογαριασμό dropbox." }));
    } else {
      setShow(true);
    }
  };

  React.useEffect(() => {
    dispatch(canSignature({ tabID, model: element.model }));
  }, []);

  return (
    <>
      <Button disabled={objectState == "view"} variant="secondary" onClick={handleClick}>
        <TbSignature size="1.7rem" />
      </Button>
      <ModalSignature tabID={tabID} element={element} show={show} setShow={setShow} />
    </>
  );
};

export default Signature;
