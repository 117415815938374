import { createSlice } from "@reduxjs/toolkit";
import {
  setAccessRights,
  setCustomRelJobMenuProperty,
  setDialog,
  setFormDesign,
  setPrepareNew,
  setRelJobMenu,
  setTableFields,
} from "../ui/uiSlice";
import { clear } from "../session/sessionSlice";

var data = {};
const recursive = (element) => {
  if (element.items ?? [].length > 0) {
    element.items.forEach((item) => recursive(item));
  } else {
    if (element.value) {
      data = {
        ...data,
        [element.name.split(".")[0]]: {
          ...data?.[element.name.split(".")[0]],
          [element.name.split(".")[1]]: element.value,
        },
      };
    }
  }
};

const initialState = {
  currentscreen: "dashboard",
  screens: {
    dashboard: "dashboard",
    user: "user",
  },
};

const screensSlice = createSlice({
  name: "screens",
  initialState,
  reducers: {
    setWebMenu: (state, action) => {
      state.screens = action.payload;
    },
    setScreenFromJob: (state, action) => {
      state.screens[action.payload.key] = {
        ...state.screens[action.payload.key],
        ...action.payload.value,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setAccessRights, (state, action) => {
        state.screens[action.payload.idx].accessRights = action.payload.value;
      })
      .addCase(setDialog, (state, action) => {
        data = {};
        if (action.payload.value.success) {
          action.payload.value.form.forEach((element) => recursive(element));
        }
        state.screens[action.payload.idx].dialog = {
          ...action.payload.value,
          filters: {
            data,
          },
        };
      })
      .addCase(setFormDesign, (state, action) => {
        const formDesign = { ...action.payload.value };
        const model = formDesign.model;
        Object.keys(model).map((tableKey) => {
          model[tableKey].fields.map((field) => {
            const EditorArguments = Array.from(field.editor.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
            EditorArguments.forEach((element) => {
              const splitted = element.split(".");
              const tableName = splitted[0];
              const fieldName = splitted[1];
              if (formDesign?.relativeFields?.[tableName]?.[fieldName]) {
                formDesign.relativeFields[tableName][fieldName].push({ table: tableKey, key: field.name });
              } else {
                formDesign.relativeFields = {
                  ...formDesign.relativeFields,
                  [tableName]: {
                    ...formDesign?.relativeFields?.[tableName],
                    [fieldName]: [{ table: tableKey, key: field.name }],
                  },
                };
              }
            });
          });
        });
        state.screens[action.payload.idx].formDesign = formDesign;
      })
      .addCase(setRelJobMenu, (state, action) => {
        state.screens[action.payload.idx].relJobMenu = action.payload.value;
      })
      .addCase(setCustomRelJobMenuProperty, (state, action) => {
        state.screens[action.payload.idx].customRelJobs = {
          ...state.screens[action.payload.idx].customRelJobs,
          [action.payload.key]: action.payload.value,
        };
      })
      .addCase(setTableFields, (state, action) => {
        state.screens[action.payload.idx].tableFields = action.payload.value;
      })
      .addCase(setPrepareNew, (state, action) => {
        state.screens[action.payload.idx].prepareNew = action.payload.value;
      })
      .addCase(clear, () => initialState);
  },
});

export const { setWebMenu, setScreenFromJob } = screensSlice.actions;

export default screensSlice.reducer;
