import React from "react";
import { TiDeleteOutline } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import { setChange, setFilter, setGridChange } from "../../../redux/features/ui/uiSlice";
import { getCommandParams, getFilters, getObjectState, getRecData } from "../../../redux/selectors";
import { Button, ButtonGroup, FloatingLabel, Form, InputGroup, Modal } from "react-bootstrap";
import { HiX, HiCheck } from "react-icons/hi";
import { languages } from "../../languages";

const S1Strings = ({ element, tabID, screenState, grid }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => getFilters(state, tabID));
  const currentLang = useSelector((state) => state.settings.language);

  const commandParams = useSelector((state) => getCommandParams(state, tabID));

  const objectState = useSelector((state) => getObjectState(state, tabID));

  const currentGridLine = grid?.currentLine;

  const editorData = [];
  element.editor.split("|").forEach((i) => (editorData[i.split("=")[0]] = i.split("=")[1]));

  const [selectedRows, setSelectedRows] = React.useState("");
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const data = useSelector((state) => getRecData(state, tabID));

  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [viewValue, setViewValue] = React.useState("");

  React.useEffect(() => {
    if (screenState == "form" && !grid) {
      if (data?.[table]?.[0]?.[key] && data?.[table]?.[0]?.[key] != "") {
        setValue(data?.[table]?.[0]?.[key]);
        setViewValue(editorData[data?.[table]?.[0]?.[key]]);
        setSelectedRows(data?.[table]?.[0]?.[key]);
      } else {
        setValue("");
        setViewValue("");
        setSelectedRows("");
      }
    }
  }, [data?.[table]?.[0]?.[key]]);

  React.useEffect(() => {
    if (screenState == "dialog" && !grid) {
      if (filters?.data?.[table]?.[key]) {
        setValue(filters?.data?.[table]?.[key]);
        setViewValue(editorData[filters?.data?.[table]?.[key]]);
        setSelectedRows(filters?.data?.[table]?.[key]);
      }
    }
  }, [filters?.data?.[table]?.[key]]);

  React.useEffect(() => {
    if (currentGridLine?.line?.[key] && grid) {
      setValue(editorData[currentGridLine?.line?.[key]]);
      setViewValue(editorData[currentGridLine?.line?.[key]]);

      setSelectedRows(currentGridLine?.line?.[key]);
    }
  }, [currentGridLine?.line?.[key]]);

  React.useEffect(() => {
    if (screenState == "dialog") {
      if (element.value != "") {
        setValue(element.value);
        setViewValue(editorData[element.value]);
        setSelectedRows(element.value);
        dispatch(setFilter({ tabID, table, key, value: element.value }));
      }
    }
  }, [screenState]);

  const handlePicker = (e) => {
    e.stopPropagation();
    setSelectedRows(value);
    setOpenPicker((x) => !x);
  };

  const handleClear = () => {
    setSelectedRows("");
    setValue("");
    setViewValue("");
    setOpenPicker(false);
    if (screenState == "dialog") dispatch(setFilter({ tabID, table, key, value: "" }));
    else if (screenState == "form") {
      if (grid) {
        dispatch(
          setGridChange({
            tabID,
            name: grid.model,
            key,
            value: "",
          })
        );
      } else dispatch(setChange({ tabID, table, key, value: "" }));
    }
  };

  const handleClickOnRow = (keyIndex) => {
    setSelectedRows(keyIndex);
  };

  const handleAccept = () => {
    if (screenState == "dialog") dispatch(setFilter({ tabID, table, key, value: selectedRows }));
    else if (screenState == "form") {
      if (grid) {
        dispatch(
          setGridChange({
            tabID,
            name: grid.model,
            key,
            value: selectedRows,
          })
        );
      } else dispatch(setChange({ tabID, table, key, value: selectedRows }));
    }
    setOpenPicker(false);
  };
  return (
    <div
      className="s1strings w-100"
      style={{ flex: `1 1 ${element.flex * 100}%`, overflow: "hidden" }}
      hidden={!element.visible}
    >
      <InputGroup className={`crm-floating-with-icon${element.required && value == "" ? " input-required" : ""}`}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            role="button"
            className={`w-100 ps-1 border-0${
              element.readOnly || (screenState == "form" && commandParams.readonly) ? " disabled" : ""
            }`}
            disabled={
              element.readOnly ||
              (screenState == "form" && commandParams.readonly) ||
              (screenState == "form" && objectState == "view")
            }
            readOnly={true}
            value={viewValue}
            name={element.name}
            placeholder={element.caption}
            onFocus={(e) => e.target.blur()}
            onClick={(e) =>
              !(
                element.readOnly ||
                (screenState == "form" && commandParams.readonly) ||
                (screenState == "form" && objectState == "view")
              ) && handlePicker(e)
            }
          />
        </FloatingLabel>
        {value != "" &&
          !element.readOnly &&
          ((screenState == "form" && objectState != "view") || screenState == "dialog") && (
            <div className="d-flex align-items-center justify-content-center" onClick={handleClear}>
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
      </InputGroup>
      {openPicker && (
        <Modal
          show={openPicker}
          fullscreen
          animation={false}
          className="d-flex align-items-end"
          dialogClassName="p-3"
          contentClassName="rounded overflow-hidden shadow"
        >
          <div
            className="input-picker w-100 h-100"
            style={{
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div className="input-picker-table w-100">
              <div>
                {Object.keys(editorData).map((element, index) => {
                  return (
                    <div
                      key={index}
                      className={`input-picker-table-row${
                        selectedRows.indexOf(element) > -1 ? " input-picker-table-row-selected hovered" : ""
                      }`}
                      onClick={() => handleClickOnRow(element)}
                    >
                      <span>{editorData[element]} </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <Modal.Footer className="picker-buttons">
            <ButtonGroup>
              <Button
                variant="outline-secondary"
                className="rounded-0"
                onClick={() => {
                  setOpenPicker(false);
                }}
              >
                <HiX size={25} color="var(--bs-danger)" className="me-2" />
                <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                  {languages.generalText[currentLang].closing}
                </div>
              </Button>
              <Button className="rounded-0" variant="outline-secondary" onClick={handleAccept}>
                <HiCheck size={25} color="var(--bs-success)" className="me-2" />
                <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                  {languages.generalText[currentLang].apply}
                </div>
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default S1Strings;
