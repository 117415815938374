import React from "react";
import { Button, Container } from "react-bootstrap";
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getGadgetCategory, getGadgetCollapsed, getGadgetConfig, getGadgetData } from "../../../redux/selectors";
import { collapse, toogleVisibility, setFullWindow } from "../../../redux/features/portal/portalSlice";
import { HiX } from "react-icons/hi";
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";
import Refresh from "./category5/gadget head/Refresh";
import Print from "./category5/gadget head/Print";
import { BsWindowFullscreen } from "react-icons/bs";

const Head = ({ id, dndProps }) => {
  const dispatch = useDispatch();
  const collapsed = useSelector((state) => getGadgetCollapsed(state, id));
  const category = useSelector((state) => getGadgetCategory(state, id));
  const data = useSelector((state) => getGadgetData(state, id));
  const config = useSelector((state) => getGadgetConfig(state, id));
  const fullScreen = useSelector((state) => state.portal.fullWindow);

  const handleSetFullScreen = (id) => {
    const portal = document.getElementById("d1-crm-portal");
    dispatch(setFullWindow({ id, y: portal.scrollTop }));
  };
  return (
    <div
      {...dndProps}
      style={{
        flexShrink: 0,
      }}
    >
      <Container
        fluid
        className={`gadget-top-bar category-${category} py-2 d-flex align-items-center justify-content-between`}
      >
        <div
          className="d-flex"
          style={{ overflowX: "hidden" }}
          onClick={() => dispatch(collapse({ value: !collapsed, id }))}
        >
          <Button>{collapsed ? <FaChevronCircleDown /> : <FaChevronCircleUp />}</Button>
          <div className="d-flex align-items-center gap-1 text-truncate">
            <div style={{ minWidth: "1rem" }}>
              <BsWindowFullscreen size="1rem" />
            </div>
            <span className="text-truncate">{data?.title ?? config?.title}</span>
          </div>
        </div>
        <div className="d-flex">
          {(!collapsed || fullScreen == id) && <Refresh id={id} />}
          {(!collapsed || fullScreen == id) && <Print id={id} />}
          {fullScreen == id ? (
            <Button onClick={() => dispatch(setFullWindow({ id: undefined }))}>
              <BiExitFullscreen />
            </Button>
          ) : (
            <Button onClick={() => handleSetFullScreen(id)}>
              <BiFullscreen />
            </Button>
          )}
          <Button onClick={() => dispatch(toogleVisibility(id))}>
            <HiX />
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Head;
