import React from "react";
import { Container, Button } from "react-bootstrap";
import { HiSearch } from "react-icons/hi";
import { FaFilter } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilterText,
  setLoading,
  setReportData,
  setReportInfo,
  setScreenState,
} from "../../../../redux/features/ui/uiSlice";
import { getFilters } from "../../../../redux/selectors";
import getReportInfo from "../../../../services/getReportInfo";
import getReportData from "../../../../services/getReportData";
const TopBarOnDialog = ({ tabID }) => {
  const filters = useSelector((state) => getFilters(state, tabID));
  const dispatch = useDispatch();

  const handleGoToBrowser = async () => {
    dispatch(setLoading({ show: true, tabID }));
    var text = "";
    if (filters?.data) {
      for (const [tablename] of Object.entries(filters.data)) {
        for (const [field, value] of Object.entries(filters.data[tablename])) {
          text +=
            value != "" ? (text != "" ? `&${tablename}.${field}=${value}` : `${tablename}.${field}=${value}`) : "";
        }
      }
    }
    dispatch(setFilterText({ value: text, tabID }));
    const reportInfo = await getReportInfo(tabID);
    dispatch(setReportInfo({ value: reportInfo, tabID }));

    if (reportInfo.success) {
      if (reportInfo.npages > 0) {
        const reportData = await getReportData({
          reqID: reportInfo.reqID,
          pagenum: 1,
        });
        if (reportData.success) {
          reportData.pageNum = 1;
          dispatch(setReportData({ value: reportData, tabID }));
        } else {
          console.log(reportData);
        }
      } else {
        console.log("no data");
      }
    } else {
      console.log(reportInfo);
    }
    dispatch(setLoading({ show: false, tabID }));
    dispatch(setScreenState({ value: "browser", tabID }));
  };

  return (
    <Container fluid className="object-top-bar">
      <Button variant="secondary" onClick={handleGoToBrowser}>
        <HiSearch size="1.1rem" />
      </Button>
    </Container>
  );
};

export default TopBarOnDialog;
