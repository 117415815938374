import { hasSettingsInDB, setSettings } from "../../redux/features/settings/settingsSlice";
import { set } from "../../redux/features/systemParams/systemParamsSlice";
import store from "../../redux/store";
import getData from "../../services/getData";
import getSystemParams from "../../services/getSystemParams";

export const fetchSystemParams = async () => {
  const state = store.getState();
  const url = state.session?.url;
  const paramsDate = state.systemParams?.paramsDate;
  const clientID = state.session?.clientID;
  const wa = state.systemParams?.wa;

  const today = new Date();
  if (today.toLocaleDateString() !== paramsDate) {
    const systemParams = await getSystemParams({ url, clientID });
    systemParams.paramsDate = today.toLocaleDateString();

    systemParams.success && store.dispatch(set(systemParams));
  }
  const settings = await getData({ vObj: "CCCD1WEBCRMSETTINGS", key: wa });
  if (settings.success) {
    store.dispatch(hasSettingsInDB());
    store.dispatch(
      setSettings({
        ...settings.data.CCCD1WEBCRMSETTINGS[0],
        calendarColorZones: settings.data.CCCD1WEBCRMCALENDARCOLORZONES,
      })
    );
  }
};
