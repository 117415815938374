import React from "react";
import { BsChevronDown } from "react-icons/bs";
import { slots } from "../../../logic/calendarSettings";
import { set } from "../../../redux/features/settings/settingsSlice";
import { useDispatch, useSelector } from "react-redux/es";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";

const Slots = ({ tabID }) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.app);
  const currentLang = useSelector((state) => state.settings.language);

  const boundRef = React.useRef(null);
  const pickerRef = React.useRef(null);

  const [isFocused, setIsFocused] = React.useState(false);
  const [openPicker, setOpenPicker] = React.useState(false);
  const [value, setValue] = React.useState(
    settings.calendarSlots
      ? slots[currentLang][slots[currentLang].findIndex((element) => element[1] == settings.calendarSlots)][0]
      : ""
  );
  const [selectedRow, setSelectedRow] = React.useState(
    settings.calendarSlots
      ? slots[currentLang][slots[currentLang].findIndex((element) => element[1] == settings.calendarSlots)][0]
      : ""
  );

  React.useEffect(() => {
    setValue(
      settings.calendarSlots
        ? slots[currentLang][slots[currentLang].findIndex((element) => element[1] == settings.calendarSlots)][0]
        : ""
    );
  }, [tabID]);

  React.useEffect(() => {
    if (openPicker) {
      const clickListener = (e) => {
        const click = pickerRef.current.contains(e.target) || boundRef.current.contains(e.target);
        if (!click) {
          setIsFocused(false);
          setOpenPicker(false);
        }
      };
      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [openPicker]);

  const handlePicker = (e) => {
    e.stopPropagation();
    setOpenPicker((x) => !x);
    setIsFocused((x) => !x);
  };

  const handleClickOnRow = (slot) => {
    setSelectedRow(slot);
    setValue(slot[0]);
    setOpenPicker(false);
    setIsFocused(false);
    dispatch(set({ key: "calendarSlots", value: slot[1] }));
  };
  return (
    <div className="s1-calendarSlots w-100" style={{ flex: `100%` }}>
      <InputGroup ref={boundRef} className={`crm-floating-with-icon${isFocused ? " underline-focus" : ""}`}>
        <FloatingLabel controlId="calendarSlots" label="Slots" style={{ whiteSpace: "nowrap" }} className="w-100">
          <Form.Control
            className="d-flex w-100 ps-1 border-0"
            readOnly={true}
            value={value}
            name="calendarSlots"
            placeholder="Slots"
            onClick={handlePicker}
            onFocus={(e) => e.target.blur()}
          />
        </FloatingLabel>
      </InputGroup>
      {openPicker && (
        <div
          className="input-picker-div custom"
          ref={pickerRef}
          style={{
            left: boundRef.current.getBoundingClientRect().left,
            width: boundRef.current.getBoundingClientRect().width,
            top:
              boundRef.current.getBoundingClientRect().bottom -
              (boundRef.current.getBoundingClientRect().bottom + 200 > window.innerHeight
                ? 200 + boundRef.current.getBoundingClientRect().height
                : 0),
            height: "200px",
            overflow: "auto",
          }}
        >
          <div className="input-picker-table w-100">
            {slots[currentLang].map((slot, index) => {
              return (
                <div
                  key={index}
                  className={`input-picker-table-row${
                    selectedRow == slot[0] ? " input-picker-table-row-selected" : ""
                  }`}
                  onClick={() => handleClickOnRow(slot)}
                >
                  <span>{slot[0]}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Slots;
