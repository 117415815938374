import React from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../../redux/features/ui/uiSlice";
import { MdPreview, MdSave } from "react-icons/md";
import { useSelector } from "react-redux";
import { languages } from "../../../../languages";

const ModalDownload = ({ show, setShow, url, fileName, format, tabID }) => {
  const dispatch = useDispatch();
  const [preview, setPreview] = React.useState(false);
  const currentLang = useSelector((state) => state.settings.language);

  const handleDownLoad = async () => {
    setShow(false);
    dispatch(setLoading({ show: true, tabID }));

    await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      method: "POST",
      body: JSON.stringify({ url, data: { service: "download", format } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      });
    dispatch(setLoading({ show: false, tabID }));
  };

  React.useEffect(() => {
    if (!show) setPreview(false);
  }, [show]);

  return (
    <Modal
      show={show}
      animation={false}
      onHide={() => setShow(false)}
      backdropClassName="above-all-backdrop print"
      dialogClassName={`above-all-dialog${preview ? " preview-open" : ""}`}
      contentClassName="modal-content-download"
      size={preview ? "xl" : "lg"}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton className="p-2 ps-3"></Modal.Header>
      <Modal.Body className={`d-flex justify-content-center align-items-center${preview ? " p-0" : ""}`}>
        {!preview && <div>{languages.generalText[currentLang].fileCreatedSuccess}</div>}
        {preview && <iframe title="preview" style={{ height: "100%", width: "100%" }} src={url}></iframe>}
      </Modal.Body>
      <Modal.Footer className="picker-buttons">
        <ButtonGroup>
          <Button
            variant="outline-secondary"
            className="rounded-0"
            onClick={() => {
              setShow(false);
              handleDownLoad();
            }}
          >
            <MdSave size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].download}
            </div>
          </Button>
          {!preview && (
            <Button className="rounded-0" variant="outline-secondary" onClick={() => setPreview((X) => !X)}>
              <MdPreview size={25} color="var(--bs-primary)" className="me-2" />
              <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
                {languages.generalText[currentLang].preview}
              </div>
            </Button>
          )}
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDownload;
