import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";

const CrmDropDownPrinter = (props) => {
  const boundRef = React.useRef(null);
  const tableRef = React.useRef(null);
  const pickerRef = React.useRef(null);

  const [openPicker, setOpenPicker] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);

  React.useEffect(() => {
    if (openPicker) {
      const clickListener = (e) => {
        const cliii = pickerRef.current.contains(e.target) || boundRef.current.contains(e.target);
        if (!cliii) {
          setOpenPicker(false);
          setIsFocused(false);
        }
      };

      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [openPicker]);

  return (
    <div className="s1printerselect w-100" style={{ flex: `100%`, overflow: "hidden" }}>
      <InputGroup ref={boundRef} className={`crm-floating-with-icon${isFocused ? " underline-focus" : ""}`}>
        <FloatingLabel controlId={props.name} label={props.label} style={{ whiteSpace: "nowrap" }} className="w-100">
          <Form.Control
            className="d-flex w-100 ps-1 border-0"
            readOnly={true}
            value={props.value}
            name={props.name}
            placeholder={props.label}
            onClick={() => {
              setOpenPicker((x) => !x);
              setIsFocused((x) => !x);
            }}
            onFocus={(e) => e.target.blur()}
          />
        </FloatingLabel>
        <div
          className="d-flex align-items-center justify-content-center me-1"
          onClick={() => {
            setOpenPicker((x) => !x);
            setIsFocused((x) => !x);
          }}
        >
          <BsChevronDown color="var(--bs-primary)" size="1.5rem" />
        </div>
      </InputGroup>
      {openPicker && (
        <div
          className="input-picker-div custom"
          ref={pickerRef}
          style={{
            left: boundRef.current.getBoundingClientRect().left,
            width: boundRef.current.getBoundingClientRect().width,
            top:
              boundRef.current.getBoundingClientRect().bottom -
              (boundRef.current.getBoundingClientRect().bottom + 200 > window.innerHeight
                ? 200 + boundRef.current.getBoundingClientRect().height
                : 0),
            height: "200px",
            overflow: "hidden",
          }}
        >
          <div
            className="input-picker  w-100 h-100"
            style={{
              overflow: "auto",
            }}
          >
            <div className="input-picker-table w-100" ref={tableRef}>
              {Object.keys(props?.data).map((item, index) => (
                <div
                  key={index}
                  className={`input-picker-table-row`}
                  onClick={() => {
                    setOpenPicker(false);
                    setIsFocused(false);
                    props.function(item);
                  }}
                >
                  <span className="">{props?.data?.[item]}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CrmDropDownPrinter;
