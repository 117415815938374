import React from "react";
import TopBarOnDialog from "../../bars/top bars/batch job/TopBarOnDialog";
import Dialog from "./Dialog";
import { useDispatch } from "react-redux";
import { setScreenState } from "../../../redux/features/ui/uiSlice";
import BatchMessage from "../BatchMessage";
import { useSelector } from "react-redux";
import { getCurrentTab } from "../../../redux/selectors";

const BatchJob = ({ tabID }) => {
  const dispatch = useDispatch();
  const tab = useSelector((state) => getCurrentTab(state, tabID));

  React.useEffect(() => {
    dispatch(setScreenState({ tabID, value: "dialog" }));
  }, []);
  return (
    <React.Fragment>
      {tab.batchLoading && <BatchMessage tabID={tabID} />}
      <TopBarOnDialog tabID={tabID} />
      <Dialog tabID={tabID} />
    </React.Fragment>
  );
};

export default BatchJob;
