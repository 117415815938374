import React from "react";
import { BsPlus } from "react-icons/bs";
import { useSelector } from "react-redux";
import preparNew from "../../../../services/prepareNew";
import {
  newRecord,
  setFormDesign,
  setLoading,
  setObjectState,
  setPrepareNew,
  setScreenState,
} from "../../../../redux/features/ui/uiSlice";
import getFormDesign from "../../../../services/getFormDesign";
import { getBrowserInfo, getBrowserView, getCommandParams, getCurrentTab } from "../../../../redux/selectors";
import { useDispatch } from "react-redux";

const NewButton = ({ tabID }) => {
  const dispatch = useDispatch();
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));
  const commandParams = useSelector((state) => getCommandParams(state, tabID));
  const tab = useSelector((state) => getCurrentTab(state, tabID));

  const handleNew = async () => {
    dispatch(setLoading({ show: true, tabID }));
    const FormDesign = tab.formDesign ?? (await getFormDesign(tabID));
    if (FormDesign.success) {
      !tab.formDesign && dispatch(setFormDesign({ value: FormDesign, idx: tab.idx, tabID }));
      var locateinfo = "";
      // if (!tab.prepareNew) {
      Object.entries(FormDesign.model).forEach(([key, value], index) => {
        locateinfo += index == 0 ? `${key}:` : `;${key}:`;
        if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
        else
          value.fields.map((item) => {
            locateinfo += `${item.name},`;
          });
      });
      const PrepareNew = await preparNew({ locateinfo, tabID });
      PrepareNew.success && dispatch(setPrepareNew({ value: PrepareNew.data, idx: tab.idx, tabID }));
      // }
      dispatch(newRecord({ tabID }));
    } else {
      console.log(FormDesign);
    }
    dispatch(setScreenState({ value: "form", tabID }));
    dispatch(setObjectState({ value: "new", tabID }));
    dispatch(setLoading({ show: false, tabID }));
  };

  return (
    <>
      {browserInfo?.browserOnly != "true" && !commandParams.noinsert && (
        <nav className="menu" onClick={handleNew} hidden={tab.screenState === "form"}>
          <label className="menu-open-button shadow ">
            <BsPlus className="pe-none" />
          </label>
        </nav>
      )}
    </>
  );
};

export default NewButton;
