import React from "react";
import TopBarOnWebPage from "../../bars/top bars/web page/TopBarOnWebPage";
import { useSelector } from "react-redux";
import { getCommandByIndex } from "../../../redux/selectors";

const WebPage = ({ tabID }) => {
  const command = useSelector((state) => getCommandByIndex(state, tabID));
  const frameRef = React.useRef();

  return (
    <>
      <TopBarOnWebPage frameRef={frameRef} />
      <iframe ref={frameRef} title="preview" style={{ height: "100%", width: "100%" }} src={command} />
    </>
  );
};

export default WebPage;
