import React from "react";
import { Dropdown } from "react-bootstrap";
import { BsBuildingGear, BsFileEarmarkMedical, BsFillFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { killTab, setTab } from "../../../redux/features/ui/uiSlice";
import { useSelector } from "react-redux";
import { getCurrentTabIndex, getNewData } from "../../../redux/selectors";
import { HiX } from "react-icons/hi";
import ModalSaveRecord from "../top bars/edit master/form/ModalSaveRecord";
import store from "../../../redux/store";

const TaskOnActiveDropDown = ({ tab }) => {
  const dispatch = useDispatch();
  const tabID = useSelector((state) => getCurrentTabIndex(state));
  const [tabID2Delete, setTabID2Delete] = React.useState(-1);
  const [showModalSave, setShowModalSave] = React.useState(false);

  const handleKillTab = (e, id) => {
    e.stopPropagation();
    const state = store.getState();
    const newData = getNewData(state, id);
    if (tab.objectState != "view" && newData) {
      dispatch(setTab(id));
      setTabID2Delete(id);
      setShowModalSave(true);
    } else {
      dispatch(killTab(id));
    }
  };

  return (
    <div className="d-flex flex-row">
      <Dropdown.Item
        href="#"
        className={`task-drop-down-item${tabID == tab.id ? " task-active" : ""} text-truncate`}
        onClick={() => dispatch(setTab(tab.id))}
      >
        <div className="d-flex justify-content-center align-items-center text-truncate" style={{ fontSize: "1.2rem" }}>
          <div className="d-flex">
            {{
              EditMaster: <BsFileEarmarkMedical color="var(--bs-orange)" />,
              Report: <BsFillFileEarmarkSpreadsheetFill color="rgb(19, 185, 148)" />,
              Dialog: <BsBuildingGear color="var(--bs-danger)" />,
            }[tab.cmd] ?? "xxx"}
          </div>
          <span className="text-truncate ps-2">{tab?.text}</span>
        </div>
      </Dropdown.Item>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minWidth: "2.5rem" }}
        onClick={(e) => {
          handleKillTab(e, tab.id);
        }}
      >
        <HiX className="align-items-end" size="1.3rem" />
      </div>
      {showModalSave && (
        <ModalSaveRecord
          tabID={tabID2Delete}
          show={showModalSave}
          setShow={setShowModalSave}
          functionNo={() => {
            setShowModalSave(false);
            dispatch(killTab(tabID2Delete));
          }}
        />
      )}
    </div>
  );
};

export default TaskOnActiveDropDown;
