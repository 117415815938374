import React from "react";
import { ImArrowUp } from "react-icons/im";
import { useSelector } from "react-redux";
import { getBrowserInfo } from "../../../../redux/selectors";

const RefreshingContent = ({ tabID }) => {
  const browserIfnfo = useSelector((state) => getBrowserInfo(state, tabID));

  return (
    <div className="pt-3 w-100 d-flex justify-content-center flex-row align-items-center">
      <ImArrowUp fontSize={"26px"} />
      <div className="ms-4 d-flex justify-content-center flex-column align-items-center">
        <div>Release to refresh</div>
        <small>{`Last Update: ${browserIfnfo?.upddate ? dateTimeConverter(browserIfnfo?.upddate) : ""}`}</small>
      </div>
    </div>
  );
};

export default RefreshingContent;

const dateTimeConverter = (date) => {
  const d = new Date(date);
  return (
    [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/") +
    " " +
    [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":")
  );
};
