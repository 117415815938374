import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import "@fullcalendar/core/locales/el";
import interactionPlugin from "@fullcalendar/interaction";
import { useDispatch, useSelector } from "react-redux/es";
import {
  getAccesRights,
  getBrowserInfo,
  getCalendarScoll,
  getCommandByIndex,
  getCommandParams,
  getFormDesign,
  getScreenState,
  getTabIdx,
  getTableData,
} from "../../../../redux/selectors";
import { calendarObjectsFields } from "../../../../logic/calendarObjectFields";
import setData from "../../../../services/setData";
import serviceGetFormDesign from "../../../../services/getFormDesign";
import {
  setCalendarDates,
  setCalendarScroll,
  setFormDesign,
  setGetData,
  setLoading,
  setScreenState,
  setSelectedRow,
} from "../../../../redux/features/ui/uiSlice";
import { setError } from "../../../../redux/features/modals/modalsSlice";
import getData from "../../../../services/getData";
import Buttons from "./Buttons";
import Details from "./Details";
import NewOnCalendar from "../buttons/NewOnCalendar";

const Calendar = React.memo(({ tabID }) => {
  const dispatch = useDispatch();
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));
  const tableData = useSelector((state) => getTableData(state, tabID));
  const object = useSelector((state) => getCommandByIndex(state, tabID).split("&")[0]);
  const accessRights = useSelector((state) => getAccesRights(state, tabID));
  const tabIDX = useSelector((state) => getTabIdx(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const commandParams = useSelector((state) => getCommandParams(state, tabID));
  const settings = useSelector((state) => state.settings.app);
  const [calendarApi, setCalendarApi] = React.useState(null);
  const screenState = useSelector((state) => getScreenState(state, tabID));
  const currentTabID = useSelector((state) => state.ui.tabID);
  const calendarScroll = useSelector((state) => getCalendarScoll(state, tabID));
  const currentLang = useSelector((state) => state.settings.language);

  var locale = "en";
  switch (currentLang) {
    case "GRE":
      locale = "el";
      break;
    case "ENG":
      locale = "en";
    default:
      locale = "en";
      break;
  }

  const [events, setEvents] = React.useState([]);
  const [showDetails, setShowDetails] = React.useState(true);

  const fromDateField = calendarObjectsFields[object].tableData.fromDate;
  const toDateField = calendarObjectsFields[object].tableData.toDate;
  const canZone = settings.calendarColorZoneField != "" && settings.calendarColorZones.length > 0;
  const colorZones = {};
  if (settings.calendarColorZones.length > 0) {
    settings.calendarColorZones.map((item) => {
      colorZones[item.VALUE] = item.COLOR;
    });
  }

  React.useEffect(() => {
    if (browserInfo.success && browserInfo.totalcount > 0) {
      setEvents(
        tableData.map((col) => {
          const hasZoneField =
            canZone && col?.[`${object}_${settings.calendarColorZoneField}`]
              ? col?.[`${object}_${settings.calendarColorZoneField}`]
              : false;
          const colValue = hasZoneField != false ? hasZoneField.split("|")[0] : undefined;
          const color = hasZoneField ? colorZones?.[colValue] ?? settings.calendarColor : settings.calendarColor;
          return {
            id: col.ZOOMINFO.split(";")[col.ZOOMINFO.split(";").length - 1],
            title: col[calendarObjectsFields[object].tableData.text] ?? "",
            start: col[fromDateField] ? new Date(col[fromDateField]).toISOString() : "",
            end: col[toDateField] ? new Date(col[toDateField]).toISOString() : "",
            color: color,
          };
        })
      );
    }
  }, [tableData]);

  React.useEffect(() => {
    if (currentTabID == tabID && calendarApi) calendarApi.updateSize();
  }, [currentTabID]);

  React.useEffect(() => {
    if (screenState == "browser" && calendarApi && calendarApi?.view?.type == "timeGridDay") {
      const scroller = calendarApi.el.querySelector(".fc-timeGridDay-view .fc-scrollgrid-section-body .fc-scroller");
      scroller && scroller.scrollTo(0, calendarScroll);
    }
    if (screenState == "browser" && calendarApi) calendarApi.updateSize();
  }, [screenState]);

  const [originalEvent, setOriginalEvent] = React.useState(null);

  const handleEventDragStart = (info) => {
    // const { start, end } = info.event;
    // setOriginalEvent({ start, end });
  };

  const handleEventDrop = async (info) => {
    const { start, end, id } = info.event;
    dispatch(setLoading({ show: true, tabID }));
    const data = {
      [calendarObjectsFields[object].setDataTable]: [
        {
          [fromDateField.split("_")[1]]: formatDateTime(start),
          [toDateField.split("_")[1]]: formatDateTime(end),
        },
      ],
    };
    const response = await setData({ tabID, data, key: id, defaultObject: object });
    if (response.success) {
      calendarApi.unselect();
      // const indexToUpdate = events.findIndex((item) => item.id == id);
      // const copyEvents = [...events];
      // copyEvents[indexToUpdate] = { ...copyEvents[indexToUpdate], start: start.toISOString(), end: end.toISOString() };

      // setEvents(copyEvents);
    } else {
      dispatch(setError({ message: response.error, show: true }));
      // info.event.setDates(originalEvent.start, originalEvent.end);
      console.log(response);
    }
    dispatch(setLoading({ show: false, tabID }));
  };

  const openRecord = async (id) => {
    var locateinfo = "";
    var FormDesign = JSON.parse(JSON.stringify(formDesign ?? {}));
    var noError = true;
    const index = events.findIndex((item) => item.id == id);
    dispatch(setSelectedRow({ value: index, tabID }));
    if (browserInfo.browserOnly != true && commandParams.browseronly != 1) {
      if (accessRights?.browserOnly != true) {
        dispatch(setLoading({ show: true, tabID }));
        if (!FormDesign?.success) {
          const fetchFormDesign = await serviceGetFormDesign(tabID);
          if (fetchFormDesign.success) {
            dispatch(setFormDesign({ value: fetchFormDesign, idx: tabIDX, tabID }));
            dispatch(setScreenState({ value: "form", tabID }));

            FormDesign = fetchFormDesign;
          } else {
            noError = false;
            dispatch(setLoading({ show: false, tabID }));
            console.log(fetchFormDesign);
          }
        } else {
          dispatch(setScreenState({ value: "form", tabID }));
        }

        if (noError) {
          Object.entries(FormDesign.model).forEach(([key, value], index) => {
            locateinfo += index == 0 ? `${key}:` : `;${key}:`;
            if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
            else
              value.fields.map((item) => {
                locateinfo += `${item.name},`;
              });
          });

          const data = await getData({ key: id, locateinfo, tabID });
          if (data.success) {
            dispatch(setGetData({ value: data, tabID }));
            dispatch(setLoading({ show: false, tabID }));
          } else {
            dispatch(setLoading({ show: false, tabID }));
          }
        }
      }
    }
  };

  const handleEventClick = (arg) => {
    const scroller = calendarApi.el.querySelector(".fc-timeGridDay-view .fc-scrollgrid-section-body .fc-scroller");
    dispatch(setCalendarScroll({ tabID, scroll: scroller.scrollTop }));
    // if (doubleClickTimeoutRef.current) {
    //   // Double-click logic here
    openRecord(arg.event.id);
    //   clearTimeout(doubleClickTimeoutRef.current);
    //   doubleClickTimeoutRef.current = null;
    // } else {
    // const index = events.findIndex((item) => item.id == arg.event.id);
    // dispatch(setSelectedRow({ value: index, tabID }));
    //   // Single-click logic here
    //   doubleClickTimeoutRef.current = setTimeout(() => {
    //     clearTimeout(doubleClickTimeoutRef.current);
    //     doubleClickTimeoutRef.current = null;
    //   }, 300); // Adjust the timeout duration as needed
    // }
  };

  const handleCalendarRef = (ref) => {
    if (ref) {
      setCalendarApi(ref.getApi());
    }
  };

  const handleSelect = (arg) => {
    dispatch(setCalendarDates({ tabID, show: true, start: formatDateTime(arg.start), end: formatDateTime(arg.end) }));
  };

  const buttonText = {
    today: "Σήμερα",
    month: "Μήνας",
    week: "Εβδομάδα",
    day: "Μέρα",
  };

  const headerToolbar = {
    start: "prev",
    center: "title",
    end: "next",
  };

  const businessHours = {
    daysOfWeek: settings.calendarBussinesDays.split(",").sort(),
    startTime: settings.calendarStart,
    endTime: settings.calendarEnd,
  };

  const views = {
    timeGridDay: {
      eventDisplay: "none",
      titleFormat: { year: "numeric", month: "short", weekday: "short", day: "numeric" },
    },
    dayGridWeek: {
      eventDisplay: "none",
      titleFormat: { year: "numeric", month: "short" },
      dayHeaderFormat: { weekday: "short" },
    },
    dayGridMonth: {
      eventDisplay: "none",
    },
  };

  const dayHeaderFormat = { weekday: "short" };

  return (
    <div className="browser-calendar w-100 h-100 p-2 d-flex flex-column user-select-none">
      <Buttons tabID={tabID} calendarApi={calendarApi} setShowDetails={setShowDetails} />
      <div style={{ flex: 1 }}>
        <FullCalendar
          showNonCurrentDates={false}
          plugins={[dayGridPlugin, timeGridPlugin, multiMonthPlugin, bootstrap5Plugin, interactionPlugin]}
          initialView="dayGridWeek"
          // themeSystem="bootstrap5"
          ref={handleCalendarRef}
          height="100%"
          firstDay={settings.calendarFirstDay}
          locale={locale}
          nowIndicator={true}
          // eventMaxStack={2}
          dayMaxEventRows={true}
          slotDuration={settings.calendarSlots}
          views={views}
          now={new Date()}
          dayHeaderFormat={dayHeaderFormat}
          headerToolbar={headerToolbar}
          buttonText={buttonText}
          businessHours={businessHours}
          editable={true}
          selectable={true}
          allDaySlot={false}
          events={events}
          eventDrop={handleEventDrop}
          eventResize={handleEventDrop}
          eventDragStart={handleEventDragStart}
          eventResizeStart={handleEventDragStart}
          // eventColor={settings.calendarColor}
          select={handleSelect}
          unselectAuto={false}
          // unselect={() => dispatch(setCalendarDates({ tabID, show: false, start: undefined, end: undefined }))}
          eventClick={handleEventClick}
          dateClick={(info) => {
            if (info.view.type != "timeGridDay") {
              calendarApi.select({ start: info.dateStr });
            }
          }}
          dayCellContent={(arg) => {
            if (arg.view.type != "timeGridDay") {
              const date = arg.date;
              const hasEvents = events.some((event) => {
                const eventsDate = new Date(event.start);
                return getOnlyDate(eventsDate) === getOnlyDate(date);
              });
              return (
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <span className="fc-daygrid-day-number">{arg.date.getDate()}</span>
                  <span className={`has-${hasEvents ? "" : "not-"}event-marker`} />
                </div>
              );
            }
          }}
        />
      </div>
      {showDetails && <Details tabID={tabID} events={events} handleClick={openRecord} />}
      <NewOnCalendar tabID={tabID} calendarApi={calendarApi} />
    </div>
  );
});

export default Calendar;

const formatDateTime = (date) =>
  [date.getFullYear(), (date.getMonth() + 1).padLeft(), date.getDate().padLeft()].join("-") +
  " " +
  [date.getHours().padLeft(), date.getMinutes().padLeft(), date.getSeconds().padLeft()].join(":");

const getOnlyDate = (date) => [date.getFullYear(), (date.getMonth() + 1).padLeft(), date.getDate().padLeft()].join("-");
