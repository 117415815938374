import React from "react";
import TopBarOnEditList from "../../bars/top bars/edit list/TopBarOnEditList";
import serviceGetTableFields from "../../../services/getTableFields";
import { useDispatch } from "react-redux";
import { editListSetGetData, setLoading, setTableFields } from "../../../redux/features/ui/uiSlice";
import Browser from "./Browser";
import { useSelector } from "react-redux";
import { getTableFields } from "../../../redux/selectors/editList";
import { getCommandByIndex, getTabIdx } from "../../../redux/selectors";
import getData from "../../../services/getData";

const EditList = ({ tabID }) => {
  const dispatch = useDispatch();
  const tableFields = useSelector((state) => getTableFields(state, tabID));
  const idx = useSelector((state) => getTabIdx(state, tabID));
  const command = useSelector((state) => getCommandByIndex(state, tabID));
  const tablewithDollar = command.split("&")[0];
  const tablename = tablewithDollar.startsWith("$") ? tablewithDollar.substring(1) : tablewithDollar;
  const company = useSelector((state) => state.systemParams.companyinfo.company);
  const openEditList = async () => {
    dispatch(setLoading({ show: true, tabID }));
    var vTableFields = tableFields;
    if (!vTableFields || !vTableFields?.success) {
      vTableFields = await serviceGetTableFields({ tabID });
      dispatch(setTableFields({ idx, tabID, value: vTableFields }));
      if (!vTableFields.success) return;
    }

    const data = await getData({ tabID });
    if (data.success) {
      dispatch(
        editListSetGetData({
          tabID,
          value: { success: true, fields: vTableFields.fields, data: data.data[tablename], company },
        })
      );
    } else {
      dispatch(editListSetGetData({ tabID, value: data }));
    }

    dispatch(setLoading({ show: false, tabID }));
  };
  React.useEffect(() => {
    openEditList();
  }, []);

  return (
    <>
      <TopBarOnEditList tabID={tabID} />
      <Browser tabID={tabID} />
    </>
  );
};

export default EditList;
