import React from "react";
import { getGadgetData } from "../../../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { setTableColumns, setTableData } from "../../../../redux/features/portal/portalSlice";
import serviceGetGadgetData from "../../../../services/getGadgetData";
import Head from "../Head";

const Category1 = ({ id, dndProps }) => {
  const gagdgetData = useSelector((state) => getGadgetData(state, id));
  const dispatch = useDispatch();

  const initGadget = async () => {
    dispatch(setTableColumns({ id, value: gagdgetData.structure }));
    const data = await serviceGetGadgetData(id);
    dispatch(setTableData({ id, value: data }));
  };
  React.useEffect(() => {
    initGadget();
  }, []);
  return (
    <div className="gadget d-flex flex-column h-100">
      <Head id={id} dndProps={dndProps} />
    </div>
  );
};

export default Category1;
