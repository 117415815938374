import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getItemQty } from "../../../../redux/selectors/showRooms";
import { setItemQty } from "../../../../redux/features/showRooms/showRoomsSlice";

const QtyInput = ({ mtrl, idx }) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const itemQty = useSelector((state) => getItemQty(state, idx, mtrl));

  React.useEffect(() => {
    setValue(itemQty);
  }, [itemQty]);

  const handleBlur = (val) => {
    dispatch(setItemQty({ idx, mtrl, value: val }));
  };

  return (
    <div
      className="s1-cart-list-row-qty"
      style={{ overflow: "hidden", borderBottom: "1px solid var(--bs-border-color)" }}
    >
      <input
        value={value}
        inputMode="decimal"
        onChange={(e) => setValue(e.target.value)}
        onBlur={(e) => handleBlur(e.target.value)}
        style={{
          border: "none",
          outline: "none",
          width: "-webkit-fill-available",
          textAlign: "center",
          overflow: "hidden",
          padding: 0,
        }}
      />
    </div>
  );
};

export default QtyInput;
