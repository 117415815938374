import React from "react";
import { Button } from "react-bootstrap";
import { TiPlus } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { getTabIdx } from "../../../../redux/selectors";
import { addItemQty } from "../../../../redux/features/showRooms/showRoomsSlice";
import { useSelector } from "react-redux";
import { getItemData } from "../../../../redux/selectors/showRooms";

const Plus = ({ tabID, mtrl, row }) => {
  const dispatch = useDispatch();
  const idx = useSelector((state) => getTabIdx(state, tabID));
  const itemData = useSelector((state) => getItemData(state, idx, tabID));

  const handleAddQty = () => {
    var data = {};
    if (!itemData) {
      Object.keys(row).map((key) => {
        if (key != "FLD-1" && key.startsWith("ITEM_") && !key.startsWith("ITEM_MTRL_")) {
          const newKey = `MTRL_${key}`;
          data[newKey] = row[key];
        } else if (key.startsWith("ITEM_MTRL_")) {
          data[key.slice(5)] = row[key];
        }
      });
      data.MTRL = mtrl;
    } else {
      data = itemData;
    }
    dispatch(addItemQty({ idx, mtrl, data, row }));
  };
  return (
    <Button variant="secondary" onClick={handleAddQty}>
      <TiPlus />
    </Button>
  );
};

export default Plus;
