import React from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { HiCheck, HiX } from "react-icons/hi";
import { languages } from "../../../../languages";
import { useSelector } from "react-redux";
import { getBrowserInfoColumns, getSortField } from "../../../../../redux/selectors";
import { setBrowserInfo, setLoading, setSortField } from "../../../../../redux/features/ui/uiSlice";
import getBrowserInfo from "../../../../../services/getBrowserInfo";
import { useDispatch } from "react-redux";

const ModalSort = ({ tabID, show, setShow }) => {
  const dispatch = useDispatch();
  const currentLang = useSelector((state) => state.settings.language);
  const [direction, setDirection] = React.useState("");
  const [field, setField] = React.useState("");
  const sortField = useSelector((state) => getSortField(state, tabID));
  const columns = useSelector((state) => getBrowserInfoColumns(state, tabID));

  const handleSort = async () => {
    setShow(false);
    if (`${direction}${field}` != sortField) {
      dispatch(setLoading({ show: true, tabID }));
      dispatch(setSortField({ tabID, value: `${direction}${field}` }));
      const browserInfo = await getBrowserInfo(tabID);
      dispatch(setBrowserInfo({ value: browserInfo, tabID }));
      dispatch(setLoading({ show: false, tabID }));
    }
  };

  React.useEffect(() => {
    if (show) {
      if (sortField) {
        setDirection(sortField.startsWith("*") ? "*" : "");
        setField(sortField.startsWith("*") ? sortField.slice(1) : sortField);
      } else {
        setDirection("");
        setField("");
      }
    }
  }, [show]);

  const hanleChangeOrder = (value) => {
    setDirection(value == 2 ? "*" : "");
  };

  return (
    <Modal
      show={show}
      //   onHide={() => setShow(false)}
      // centered
      fullscreen
      className="d-flex align-items-end"
      dialogClassName="p-3"
      contentClassName="rounded overflow-hidden shadow"
    >
      <Modal.Header>
        <Form.Select value={direction == "*" ? 2 : 1} onChange={(e) => hanleChangeOrder(e.target.value)}>
          <option value="1">{languages.generalText[currentLang].asc}</option>
          <option value="2">{languages.generalText[currentLang].desc}</option>
        </Form.Select>
      </Modal.Header>
      <div
        className="input-picker  w-100 h-100"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div className="input-picker-table w-100">
          <div>
            {columns &&
              columns.map((column, index) => {
                return (
                  <div
                    key={index}
                    className={`input-picker-table-row${
                      field == column.dataIndex ? " input-picker-table-row-selected" : ""
                    }`}
                    onClick={() => setField(column.dataIndex)}
                  >
                    <span>{column.header}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Modal.Footer className="picker-buttons">
        <ButtonGroup>
          <Button variant="outline-secondary" className="rounded-0" onClick={() => setShow(false)}>
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].closing}
            </div>
          </Button>
          <Button className="rounded-0" variant="outline-secondary" onClick={handleSort}>
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].apply}
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSort;
