import React from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { HiX, HiCheck } from "react-icons/hi";
import CrmDropDownPrinter from "../../../../ui/custom inputs/CrmDropDownPrinter";
import { useSelector } from "react-redux";
import { languages } from "../../../../languages";

const ModalSelectFormat = ({ show, setShow, outputFormats, handlePrintBrowser }) => {
  const [printer, setPrinter] = React.useState(["PDF", "PDF"]);
  const currentLang = useSelector((state) => state.settings.language);

  const handleChange = (key) => {
    setPrinter([key, outputFormats[key] ?? ""]);
  };

  React.useEffect(() => {
    setPrinter(["PDF", "PDF"]);
  }, [show]);
  return (
    <Modal show={show} animation={false} size="lg" onHide={() => setShow(false)} backdrop="static" centered>
      <Modal.Body>
        <CrmDropDownPrinter
          label={languages.generalText[currentLang].printer}
          type="text"
          name="printer"
          inputMode="none"
          required
          value={printer[1]}
          data={{ ...outputFormats }}
          function={(value) => handleChange(value)}
        />
      </Modal.Body>
      <Modal.Footer className="picker-buttons">
        <ButtonGroup>
          <Button
            variant="outline-secondary"
            className="rounded-0"
            onClick={() => {
              setShow(false);
            }}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].cancel}
            </div>
          </Button>
          <Button
            disabled={printer[0] == ""}
            className="rounded-0"
            variant="outline-secondary"
            onClick={() => {
              setShow(false);
              handlePrintBrowser(printer[0]);
            }}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].print}
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSelectFormat;
