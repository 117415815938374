import { getFilters, getFormDesign, getRecData, getScreenState } from "../../redux/selectors";
import store from "../../redux/store";

const cleanEditor = (tabID, model, element, grid) => {
  const state = store.getState();
  const data = getRecData(state, tabID);
  const screenState = getScreenState(state, tabID);
  const filters = getFilters(state, tabID);
  const formDesign = getFormDesign(state, tabID);

  const table = element.name.split(".")[0];

  const EditorArguments = Array.from(model.editor.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
  var editor = model.editor && model.editor != "" ? model.editor : element.editor;

  EditorArguments.forEach((y) => {
    if (y == "LOGINCOMPANY") {
      editor = editor.replaceAll(`[${y}]`, ":X.SYS.COMPANY");
    } else {
      var splitted = y.split(".");
      var tableName = splitted[0];
      var fieldName = splitted[1];
      var replaceValue;
      if (fieldName == "COMPANY") {
        replaceValue = ":X.SYS.COMPANY";
      } else if (screenState == "dialog") {
        replaceValue = (filters?.data?.[tableName]?.[fieldName] ?? "").split("|")[0] ?? "";
      } else if (screenState == "form") {
        var dataToLookUp;
        if (formDesign.model[tableName].relationship == "OneToMany" && table == tableName) {
          const currentGridLine = grid?.currentLine;

          dataToLookUp = currentGridLine?.line?.[fieldName];
        } else {
          dataToLookUp = data?.[tableName]?.[0]?.[fieldName];
        }
        if (dataToLookUp && dataToLookUp != "") {
          if (model.editType == "MemoryTable") {
            if (model.selectorVF.indexOf("|") > -1) {
              let vfValues = (dataToLookUp ?? "").split("|");
              replaceValue = vfValues[0];
            } else {
              replaceValue = dataToLookUp;
            }
          } else {
            replaceValue = dataToLookUp;
          }
        } else {
          switch (model.type) {
            case "int":
              replaceValue = 0;
              break;
            case "boolean":
              replaceValue = "";
              break;
            default:
              replaceValue = "";
              break;
          }
        }
      }
      editor = editor.replaceAll(`[${y}]`, replaceValue);
    }
  });
  return editor;
};

export default cleanEditor;
