import React from "react";
import { FiSave } from "react-icons/fi";

const SaveList = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-start"
      style={{
        userSelect: "none",
        // cursor: objectState != "view" ? "pointer" : "default",
        // opacity: objectState != "view" ? 1 : 0.5,
      }}
    >
      <FiSave size="1rem" className="me-1" />
      Καταχώρηση
    </div>
  );
};

export default SaveList;
