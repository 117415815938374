import React from "react";
import { Button } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import { useSelector } from "react-redux";
import { getObjectState } from "../../../../redux/selectors";
import { useDispatch } from "react-redux";
import { setCurrentDetailLine } from "../../../../redux/features/ui/uiSlice";

const AddSingleGridLine = ({ tabID, element, setShow }) => {
  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  return (
    <Button
      disabled={objectState == "view"}
      variant="secondary"
      onClick={() => {
        dispatch(setCurrentDetailLine({ tabID, name: element.model, value: undefined }));
        setShow(true);
      }}
    >
      <BsPlus size="1.5rem" />
    </Button>
  );
};

export default AddSingleGridLine;
