import React from "react";
import { useSelector } from "react-redux";
import { getBrowserInfoSums } from "../../../../../redux/selectors";
import { languages } from "../../../../languages";
import { TbSum } from "react-icons/tb";
import { Dropdown } from "react-bootstrap";
import ModalSums from "../../../../ui/edit master/browser/sums/ModalSums";

const Sums = ({ tabID }) => {
  const sums = useSelector((state) => getBrowserInfoSums(state, tabID)) ?? [""];
  const [show, setShow] = React.useState(false);
  const currentLang = useSelector((state) => state.settings.language);

  return (
    sums.some((el) => el != "") && (
      <>
        <span className="s1-browser-menu-title">{languages.generalText[currentLang].infoSums}</span>
        <Dropdown.Item className="d-flex align-items-center justify-content-start" onClick={() => setShow(true)}>
          <TbSum size="1.2rem" className="me-1" />
          <span>{languages.generalText[currentLang].sums}</span>
        </Dropdown.Item>
        <ModalSums tabID={tabID} show={show} setShow={setShow} />
      </>
    )
  );
};

export default Sums;
