import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addDetailGrid,
  deleteLineFromGrid,
  setCurrentDetailLine,
  triggerGridChange,
} from "../../../redux/features/ui/uiSlice";
import { getCurrentGridLine, getFormDesign, getNewData, getObjectState, getRecData } from "../../../redux/selectors";
import ModaDetailLine from "./ModaDetailLine";
import DeleteLineModal from "./DeleteLineModal";
import { ImBin } from "react-icons/im";
import { calculate } from "../../../services/calculate";
import S1DetailGridBar from "./S1DetailGridBar";

const S1DetailGrid = ({ element, tabID }) => {
  const dispatch = useDispatch();

  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const settings = useSelector((state) => state.settings.app);
  const dataApi = useSelector((state) => getRecData(state, tabID)?.[element.model]);
  const currentGridLine = useSelector((state) => getCurrentGridLine(state, tabID, element.model));
  const columnsApi = [
    ...element.columns.map((item) => ({
      ...item,
      Header: item.caption,
      accessor: item.name.split(".")[1],
      hidden: !item.visible,
      width: formDesign.model[element.model].fields.filter((x) => x.name == item.name.split(".")[1])[0].size * 4,
    })),
  ];

  const newData = useSelector((state) => getNewData(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const [openViewModal, setOpenViewModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const columns = React.useMemo(() => columnsApi ?? [], []);
  const data = React.useMemo(() => dataApi ?? [], [dataApi]);

  React.useEffect(() => {
    dispatch(addDetailGrid({ tabID, value: element }));
  }, []);

  const handleRowClick = (value) => {
    dispatch(setCurrentDetailLine({ tabID, name: element.model, value: { ...value, name: element.model } }));
    setOpenViewModal(true);
  };

  const handleDelete = async (e, lineIndex) => {
    e.stopPropagation();
    dispatch(setCurrentDetailLine({ tabID, name: element.model, value: { index: lineIndex, name: element.model } }));

    if (settings.askForDeleteInGrid == 1) {
      setOpenDeleteModal(true);
    } else {
      var gridLines = newData?.[element.model] ?? [];
      gridLines.length == 0 && dispatch(triggerGridChange({ tabID, name: element.model }));
      dispatch(deleteLineFromGrid({ tabID, name: element.model, lineIndex }));
      await calculate(tabID);
      dispatch(setCurrentDetailLine({ tabID, name: element.model, value: undefined }));
    }
  };

  return (
    <div className="s1detailgrid w-100">
      <S1DetailGridBar tabID={tabID} element={element} setShow={setOpenViewModal} />
      <div className="grid-lines" style={{ height: "15rem" }}>
        {(data ?? []).map((item, index) => {
          return (
            <div
              key={index}
              className={`grid-line ${currentGridLine?.index == index ? "row-selected" : ""}`}
              onClick={() => handleRowClick({ line: data[index], index })}
            >
              <div style={{ maxWidth: objectState != "view" ? "calc(100% - 2rem)" : "100%" }}>
                {columns.map((column, sIndex) => {
                  return (
                    <div key={sIndex} className="grid-line-col">
                      <span>{`${column.Header}: `}</span>
                      <span>{convertCells(item[column.accessor], column)}</span>
                    </div>
                  );
                })}
              </div>
              {objectState != "view" && (
                <div style={{ flex: 1 }} className="browser-table-td d-flex justify-content-center align-items-center">
                  <ImBin role="button" size="1.5rem" color="var(--bs-danger)" onClick={(e) => handleDelete(e, index)} />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <ModaDetailLine tabID={tabID} show={openViewModal} setShow={setOpenViewModal} element={element} />
      <DeleteLineModal tabID={tabID} show={openDeleteModal} setShow={setOpenDeleteModal} element={element} />
    </div>
  );
};

export default S1DetailGrid;

const convertCells = (value, cell) => {
  if (value) {
    if (value == "") {
      return "";
    } else {
      switch (cell?.xtype) {
        case "stringlist": //STRINGLIST
        case "s1memorytable":
          var index = value.indexOf("|");
          if (index > -1) return cell.value.substring(index + 1, value.length);
          else return value;
        case "s1checkbox":
          return <input type="checkbox" checked={value == 1} readOnly />;
        case "s1numberfield":
        case "s1intfield":
          return parseFloat(value)
            .toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: cell?.decimals ?? 2,
              maximumFractionDigits: cell?.decimals ?? 2,
            })
            .replace("€", "");
        case "s1strings":
          const array = cell.editor.split("|");
          if (array.length > 0) {
            const result = {};
            array.forEach((pair) => {
              const [key, value] = pair.split("=").map((part) => part.trim()); // Split each key-value pair by the equal sign and trim whitespace
              result[key] = value; // Assign the value to the corresponding key in the object
            });
            return result?.[value] ?? value;
          } else return value;
        case 10: //DATETIME
          var d = new Date(value);
          return (
            [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/") +
            " " +
            [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":")
          );
        case "s1calendardate": //DATE
          var d = new Date(value);
          return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
        default:
          return value;
      }
    }
  } else {
    return "";
  }
};
