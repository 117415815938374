import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  killTab,
  setBrowserInfo,
  setGetData,
  setLoading,
  setObjectState,
  setScreenState,
} from "../../../../../redux/features/ui/uiSlice";
import { FiArrowLeft } from "react-icons/fi";
import { getBrowserInfo, getJobForNew, getObjectState, getWasInTab } from "../../../../../redux/selectors";
import serviceGetBrowserInfo from "../../../../../services/getBrowserInfo";
import ModalSaveRecord from "./ModalSaveRecord";
import { Button } from "react-bootstrap";

const Back = ({ tabID }) => {
  const dispatch = useDispatch();
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const [show, setShow] = React.useState(false);
  const wasIntTab = useSelector((state) => getWasInTab(state, tabID));
  const jobForNew = useSelector((state) => getJobForNew(state, tabID));

  const handleGoToBrowser = () => {
    if (objectState == "view") {
      if (wasIntTab > 0 && jobForNew) {
        dispatch(killTab(tabID));
      } else {
        goBack();
      }
    } else {
      setShow(true);
    }
  };

  const goBack = async () => {
    if (!browserInfo) {
      dispatch(setLoading({ show: true, tabID }));
      const info = await serviceGetBrowserInfo(tabID);
      dispatch(setBrowserInfo({ value: info, tabID }));
      dispatch(setLoading({ show: false, tabID }));
    }
    dispatch(setScreenState({ value: "browser", tabID }));
    dispatch(setObjectState({ value: "view", tabID }));
    dispatch(setGetData({ value: undefined, tabID }));
  };

  const functionNo = () => {
    if (wasIntTab > 0 && jobForNew) {
      dispatch(killTab(tabID));
    } else {
      goBack();
      setShow(false);
    }
  };

  return (
    <>
      <Button onClick={handleGoToBrowser} variant="secondary">
        <FiArrowLeft />
      </Button>
      {show && <ModalSaveRecord show={show} setShow={setShow} tabID={tabID} functionNo={functionNo} />}
    </>
  );
};

export default Back;
