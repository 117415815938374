import React from "react";
import { HiPrinter } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../../redux/features/ui/uiSlice";
import { getBrowserInfo, getOutputFormats, getSN } from "../../../../../redux/selectors";
import print from "../../../../../services/print";
import { Dropdown } from "react-bootstrap";
import ModalDownload from "./ModalDownload";
import { setError } from "../../../../../redux/features/modals/modalsSlice";
import ModalSelectFormat from "./ModalSelectFormat";
import { languages } from "../../../../languages";

const PrintBrowser = ({ tabID }) => {
  const dispatch = useDispatch();
  const serialnumber = useSelector((state) => getSN(state));
  const outputFormats = useSelector((state) => getOutputFormats(state)?.list);
  const currentLang = useSelector((state) => state.settings.language);

  const [printURL, setPrintURL] = React.useState(undefined);
  const [fileName, setFileName] = React.useState("");
  const [format, setFormat] = React.useState("");
  const [showSelect, setShowSelect] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));

  const canCommit = browserInfo?.success && browserInfo?.totalcount > 0;

  const handlePrintBrowser = async (format) => {
    dispatch(setLoading({ show: true, tabID }));
    const printDoc = await print({ reqID: browserInfo.reqID, format, tabID });
    if (printDoc.success) {
      setPrintURL(`https://${serialnumber}.oncloud.gr/s1services/?filename=${printDoc.filename}`);
      setFileName(printDoc.filename.replace(/[<>:"/\\|?*\x00-\x1F]/g, "_"));
      setFormat(format);
      setShowModal(true);
    } else {
      dispatch(setError({ show: false, message: printDoc.error }));
    }
    dispatch(setLoading({ show: false, tabID }));
  };

  return (
    <>
      <Dropdown.Item
        disabled={!(browserInfo?.totalcount > 0)}
        className="d-flex align-items-center justify-content-start"
        onClick={() => setShowSelect(true)}
      >
        <HiPrinter size="1.2rem" className="me-1" />
        <span>{languages.generalText[currentLang].print}</span>
      </Dropdown.Item>

      <ModalSelectFormat
        show={showSelect}
        setShow={setShowSelect}
        outputFormats={outputFormats}
        handlePrintBrowser={handlePrintBrowser}
      />
      <ModalDownload
        show={showModal}
        setShow={setShowModal}
        url={printURL}
        fileName={fileName}
        format={format}
        tabID={tabID}
      />
    </>
  );
};

export default PrintBrowser;
