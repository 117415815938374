import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteFromDraft, set } from "../../../redux/features/settings/settingsSlice";
import { getCurrentTabIndex } from "../../../redux/selectors";

const IntegerInput = (props) => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.app);
  const [value, setValue] = React.useState(settings[props.name]);
  const tabID = useSelector((state) => getCurrentTabIndex(state));

  const handleInput = (e) => {
    const { value } = e.target;
    setValue(value.replace(/\D/g, ""));
  };

  React.useEffect(() => {
    setValue(settings[props.name]);
  }, [tabID]);

  const handleChange = (value) => {
    if ((value != settings[props.name] && value > 0) || settings[props.name]) {
      dispatch(set({ key: props.name, value: parseInt(value) }));
    } else if (value == "" || value < 1) {
      dispatch(deleteFromDraft(props.name));
      setValue(settings[props.name]);
    }
  };

  return (
    <div className="s1-browserRecordLimit w-100" style={{ flex: `100%` }}>
      <InputGroup className={`crm-floating-with-icon${value == "" || value < 1 ? " input-required" : ""}`}>
        <FloatingLabel controlId={props.name} label={props.label} style={{ whiteSpace: "nowrap" }} className="w-100">
          <Form.Control
            className="d-flex w-100 ps-1 border-0"
            inputMode="numeric"
            name={props.name}
            placeholder={props.label}
            value={value}
            onChange={(e) => handleInput(e)}
            onBlur={(e) => handleChange(e.target.value)}
          />
        </FloatingLabel>
      </InputGroup>
    </div>
  );
};

export default IntegerInput;
