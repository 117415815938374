import React from "react";
import Cookie from "../user/Cookie";
import LogOut from "../user/LogOut";
import Settings from "../user/Settings";
import HomeAction from "../user/HomeAction";
import User from "../user/User";

const ActionsOnSideBar = () => {
  return (
    <div className="p-3 d-flex w-100 justify-content-between border-bottom">
      <HomeAction />
      <Settings />
      <User />
      <Cookie />
      <LogOut />
    </div>
  );
};

export default ActionsOnSideBar;
