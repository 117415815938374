import React from "react";
import { IoClose, IoCheckmarkSharp, IoPencilSharp } from "react-icons/io5";
import {
  getBuffData,
  getCalc,
  getFormDesign,
  getJobForNew,
  getNewData,
  getObjectState,
  getPreviousScreenState,
  getWasInTab,
} from "../../../../redux/selectors";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  changeToBuffer,
  killTab,
  setBrowserInfo,
  setGetData,
  setLoading,
  setObjectState,
  setScreenState,
} from "../../../../redux/features/ui/uiSlice";
import setData from "../../../../services/setData";
import serviceGetBrowserInfo from "../../../../services/getBrowserInfo";
import { setError, setMessage } from "../../../../redux/features/modals/modalsSlice";
import { BsFillCalculatorFill } from "react-icons/bs";
import { calculate } from "../../../../services/calculate";

function EditButton({ tabID }) {
  const dispatch = useDispatch();
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const previousScreenState = useSelector((state) => getPreviousScreenState(state, tabID));
  const wasIntTab = useSelector((state) => getWasInTab(state, tabID));
  const jobForNew = useSelector((state) => getJobForNew(state, tabID));
  const shouldCalc = useSelector((state) => getCalc(state, tabID));

  const formDesign = useSelector((state) => getFormDesign(state, tabID));
  const newData = useSelector((state) => getNewData(state, tabID));
  const buffData = useSelector((state) => getBuffData(state, tabID));

  const handleSave = async () => {
    if (objectState == "view") {
      dispatch(changeToBuffer({ tabID }));
      dispatch(setObjectState({ tabID, value: "edit" }));
    } else {
      if (newData) {
        dispatch(setLoading({ show: true, tabID }));
        const key = formDesign.key;
        const tableName = key.split(".")[0];
        const fieldName = key.split(".")[1];
        const keyValue = buffData?.[tableName]?.[0]?.[fieldName] ?? "";
        var locateinfo = "";
        Object.entries(formDesign.model).forEach(([key, value], index) => {
          locateinfo += index == 0 ? `${key}:` : `;${key}:`;
          if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
          else
            value.fields.map((item) => {
              locateinfo += `${item.name},`;
            });
        });
        const data = await setData({
          key: keyValue > 0 ? keyValue : "",
          data: newData,
          locateinfo,
          tabID,
        });

        if (data.success) {
          // const browser = await serviceGetBrowserInfo(tabID);
          // dispatch(setBrowserInfo({ tabID, value: browser }));
          dispatch(setGetData({ value: data, tabID }));
          dispatch(setObjectState({ value: "view", tabID }));
          if (data.message) {
            dispatch(setMessage({ show: true, message: data.message }));
          }
        } else {
          console.log(data);
          dispatch(setError({ show: true, message: data.error }));
        }

        dispatch(setLoading({ show: false, tabID }));
      }
    }
  };
  const handleCancel = () => {
    if (objectState == "new") {
      if (wasIntTab > 0 && jobForNew) {
        dispatch(killTab(tabID));
      } else {
        dispatch(setScreenState({ value: previousScreenState ?? "dialog", tabID }));
      }
    }
    dispatch(setObjectState({ value: "view", tabID }));
  };

  return (
    <nav className="crm-edit-button menu">
      <input
        type="checkbox"
        href="#"
        className="menu-open"
        name="menu-open"
        id="menu-open"
        checked={objectState != "view"}
        readOnly={true}
      />
      <label
        className={`menu-open-button shadow  ${objectState != "view" ? "bg-success" : ""}`}
        htmlFor="menu-open"
        onClick={() => (shouldCalc ? calculate(tabID) : handleSave())}
        style={{ opacity: objectState != "view" ? (newData ? 1 : 0.7) : 1 }}
      >
        {objectState == "view" ? (
          <IoPencilSharp style={{ fontSize: "2.5rem" }} className="pe-none" />
        ) : shouldCalc ? (
          <BsFillCalculatorFill size="1.7rem" className="pe-none" />
        ) : (
          <IoCheckmarkSharp className="pe-none" />
        )}
      </label>

      <a className="menu-item shadow bg-danger" onClick={handleCancel}>
        <IoClose className="pe-none" />
      </a>
    </nav>
  );
}

// const mapStateToProps = (state) => ({
//   newdata: GETNEWDATA(state),
//   formdesign: GETFORMDESIGN(state),
//   buffData: GETBUFFDATA(state),
// });

// const mapDispatchToProps = (dispatch) => ({
//   handleSetObjectState: (value) => dispatch(SetObjectState(value)),
//   handleSetBackToList: (value) => dispatch(SetScreenState(value)),
//   handleChangeToBuff: () => dispatch(changeToBuff()),
//   handleCancelEdits: () => dispatch(CancelEdits()),
//   handleLoading: (value) => dispatch(setLoading(value)),
//   handleSetGetData: (value) => dispatch(setGetData(value)),
//   handleSetError: (value) => dispatch(setError(value)),
//   handleSetBrowserInfo: (value) => dispatch(SetBrowserInfo(value)),
//   handleSetBrowserData: (value) => dispatch(SetBrowserData(value)),
// });

export default EditButton;
