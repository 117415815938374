import axios from "axios";
import store from "../redux/store";
import { getClientID, getURL } from "../redux/selectors";

const batchStatus = async (reqid) => {
  const state = store.getState();
  const url = getURL(state);
  const clientID = getClientID(state);

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "batchStatus",
        clientID,
        reqid,
        appID: process.env.REACT_APP_SOFTONE_APPID,
        varsion: 2,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default batchStatus;
