import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentTab, getIsShowRoom, getScreenState } from "../../../redux/selectors";
import {
  canCalendar,
  initialize,
  newRecord,
  setAccessRights,
  setBrowserInfo,
  setBrowserView,
  setFormDesign,
  setGetData,
  setLoading,
  setObjectState,
  setPrepareNew,
  setScreenState,
} from "../../../redux/features/ui/uiSlice";
import getAccesRights from "../../../services/getAccessRights";
import Dialog from "./Dialog";
import Browser from "./Browser";
import Form from "./Form";
import TopBarOnDialog from "../../bars/top bars/edit master/TopBarOnDialog";
import TopBarOnBrowser from "../../bars/top bars/edit master/TopBarOnBrowser";
import TopBarOnFrom from "../../bars/top bars/edit master/TopBarOnForm";
import { setError } from "../../../redux/features/modals/modalsSlice";
import getBrowserInfo from "../../../services/getBrowserInfo";
import getFormDesign from "../../../services/getFormDesign";
import preparNew from "../../../services/prepareNew";
import getData from "../../../services/getData";
import ShowRoom from "../show room/ShowRoom";

const EditMaster = ({ tabID }) => {
  const state = useSelector((state) => getScreenState(state, tabID));
  const screenState = React.useMemo(() => state, [state]);
  const isShowRoom = useSelector((state) => getIsShowRoom(state, tabID));
  const tab = useSelector((state) => getCurrentTab(state, tabID));
  const dispatch = useDispatch();

  const initAccessRights = async () => {
    if (!tab.accessRights) {
      const accessRights = await getAccesRights(tabID);
      if (accessRights.success) {
        dispatch(setAccessRights({ value: accessRights, idx: tab.idx, tabID }));
      } else {
        console.log(accessRights);
        dispatch(setError({ show: true, message: accessRights.error }));
        return;
      }
    }
  };

  const ae1 = async () => {
    const browserInfo = await getBrowserInfo(tabID);
    dispatch(setBrowserInfo({ value: browserInfo, tabID }));
    dispatch(setScreenState({ value: "browser", tabID }));
  };

  const ae2 = async () => {
    const FormDesign = tab.formDesign ?? (await getFormDesign(tabID));
    if (FormDesign.success) {
      !tab.formDesign && dispatch(setFormDesign({ value: FormDesign, idx: tab.idx, tabID }));
      var locateinfo = "";
      Object.entries(FormDesign.model).forEach(([key, value], index) => {
        locateinfo += index == 0 ? `${key}:` : `;${key}:`;
        if (key == "XTRDOCDATA") locateinfo += "NAME,SOFNAME,LINENUM";
        else
          value.fields.map((item) => {
            locateinfo += `${item.name},`;
          });
      });
      const PrepareNew = await preparNew({ locateinfo, tabID });
      PrepareNew.success && dispatch(setPrepareNew({ value: PrepareNew.data, idx: tab.idx, tabID }));
      dispatch(newRecord({ tabID }));
    } else {
      console.log(FormDesign);
    }
    dispatch(setScreenState({ value: "form", tabID }));
    dispatch(setObjectState({ value: "new", tabID }));
  };

  const autoLocate = async () => {
    var locateinfo = "";
    var FormDesign = JSON.parse(JSON.stringify(tab.formDesign ?? {}));
    var noError = true;
    const id = tab.autoLocateID;

    if (!FormDesign?.success) {
      const fetchFormDesign = await getFormDesign(tabID);
      if (fetchFormDesign.success) {
        FormDesign = fetchFormDesign;
        dispatch(setFormDesign({ value: FormDesign, idx: tab.idx, tabID }));
      }
    }

    if (noError) {
      Object.entries(FormDesign.model).forEach(([key, value], index) => {
        locateinfo += index == 0 ? `${key}:` : `;${key}:`;
        if (key == "XTRDOCDATA") locateinfo += "NAME,";
        value.fields.map((item) => {
          locateinfo += `${item.name},`;
        });
      });

      const data = await getData({ key: id, locateinfo, tabID });
      if (data.success) {
        dispatch(setGetData({ value: data, tabID }));
        dispatch(setLoading({ show: false, tabID }));
      } else {
        dispatch(setLoading({ show: false, tabID }));
      }
      dispatch(setScreenState({ value: "form", tabID }));
    }
  };

  const checkParams = async () => {
    if (tab?.commandParams?.mode) {
      if (tab.commandParams.mode.toLowerCase() == "cal") {
        dispatch(canCalendar({ tabID }));
        dispatch(setBrowserView({ tabID, value: "calendar" }));
        await ae1();
        return;
      }
    }
    if (tab?.commandParams?.ae) {
      if (tab.commandParams.ae == 1) await ae1();
      else if (tab.commandParams.ae == 2) await ae2();
      return;
    }
    if (tab.commandParams.autoLocate) return autoLocate();

    dispatch(setScreenState({ value: "dialog", tabID }));
  };

  const openEditMaster = async () => {
    dispatch(setLoading({ show: true, tabID }));
    dispatch(initialize(tabID));
    await checkParams();
    await initAccessRights();

    dispatch(setLoading({ show: false, tabID }));
  };

  React.useEffect(() => {
    openEditMaster();
  }, []);

  return (
    <>
      {
        {
          dialog: <TopBarOnDialog tabID={tabID} />,
          browser: <TopBarOnBrowser tabID={tabID} />,
          form: <TopBarOnFrom tabID={tabID} />,
        }[screenState]
      }
      <Dialog tabID={tabID} style={{ display: screenState == "dialog" ? "block" : "none" }} />
      {isShowRoom ? (
        <ShowRoom tabID={tabID} style={{ display: screenState == "browser" ? "flex" : "none" }} />
      ) : (
        <Browser tabID={tabID} style={{ display: screenState == "browser" ? "flex" : "none" }} />
      )}
      <Form tabID={tabID} style={{ display: screenState == "form" ? "block" : "none" }} />
    </>
  );
};

export default EditMaster;
