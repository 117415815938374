import React from "react";
import { useDispatch } from "react-redux";
import { setBrowserInfo, setLoading } from "../../../../../redux/features/ui/uiSlice";
import getBrowserInfo from "../../../../../services/getBrowserInfo";
import { HiRefresh } from "react-icons/hi";
import { Button } from "react-bootstrap";

const Refresh = ({ tabID }) => {
  const dispatch = useDispatch();

  const handleReload = async () => {
    dispatch(setLoading({ show: true, tabID }));
    const browserInfo = await getBrowserInfo(tabID);
    dispatch(setBrowserInfo({ value: browserInfo, tabID }));
    dispatch(setLoading({ show: false, tabID }));
  };

  return (
    <Button variant="secondary" onClick={handleReload}>
      <HiRefresh />
    </Button>
  );
};

export default Refresh;
