import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReportData, getReportInfo } from "../../../../redux/selectors";
import { setLoading, setReportData } from "../../../../redux/features/ui/uiSlice";
import serviceGetReportData from "../../../../services/getReportData";

const Pages = ({ tabID }) => {
  const dispatch = useDispatch();
  const reportinfo = useSelector((state) => getReportInfo(state, tabID));
  const reportData = useSelector((state) => getReportData(state, tabID));
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    setPage(reportData.pageNum);
  }, [reportData.pageNum]);

  const handlePage = async (e) => {
    let pageNum;
    if (e.target.value == "") {
      pageNum = page;
    } else {
      if (e.target.value < 1) {
        pageNum = 1;
      } else if (e.target.value > reportinfo.npages) {
        pageNum = reportinfo.npages;
      } else {
        pageNum = parseInt(e.target.value);
      }
    }
    setPage(pageNum);
    if (pageNum != reportData?.pageNum) {
      dispatch(setLoading({ show: true, tabID }));
      const reportData = await serviceGetReportData({ reqID: reportinfo.reqID, pagenum: pageNum });
      reportData.pageNum = pageNum;
      dispatch(setReportData({ value: reportData, tabID }));
      dispatch(setLoading({ show: false, tabID }));
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ userSelect: "none" }}>
      {`Σελίδα`}
      <input
        type="number"
        autoComplete="off"
        value={page}
        onBlur={handlePage}
        onChange={(e) => setPage(e.target.value)}
        style={{ width: "50px", margin: "0 0.5rem", textAlign: "center" }}
      />
      {`από ${reportinfo.npages}`}
    </div>
  );
};

export default Pages;
