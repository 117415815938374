import React from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { HiCheck, HiX } from "react-icons/hi";
import { languages } from "../../../../languages";
import { useSelector } from "react-redux";
import { getBrowserInfoColumns, getBrowserInfoSums, getTableColumns } from "../../../../../redux/selectors";

const ModalSums = ({ tabID, show, setShow }) => {
  const currentLang = useSelector((state) => state.settings.language);
  const tableColumns = useSelector((state) => getTableColumns(state, tabID));
  const sums = useSelector((state) => getBrowserInfoSums(state, tabID));
  console.log(sums);

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      // centered
      // fullscreen
      // className="d-flex align-items-end"
      // dialogClassName="p-3"
      // contentClassName="rounded overflow-hidden shadow"
    >
      <Modal.Body className="d-flex flex-column py-4">
        {sums.length > 0 &&
          sums.map((value, index) => {
            if (value != "" && !tableColumns?.[index + 1]?.hidden)
              return (
                <span style={{ fontSize: 20 }}>
                  {tableColumns?.[index + 1]?.header} : {listEditor(tableColumns?.[index + 1], value)}
                </span>
              );
          })}
      </Modal.Body>
      <Modal.Footer className="picker-buttons">
        <ButtonGroup>
          <Button
            style={{ visibility: "hidden" }}
            variant="outline-secondary"
            className="rounded-0"
            onClick={() => setShow(false)}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].closing}
            </div>
          </Button>
          <Button className="rounded-0" variant="outline-secondary" onClick={() => setShow(false)}>
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].done}
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSums;

export const listEditor = (column, value) => {
  if (value != "") {
    switch (column?.decs) {
      case 2: //Float
        return parseFloat(value)
          .toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .replace("€", "");
      case 3: //Float
        return parseFloat(value)
          .toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })
          .replace("€", "");
      default:
        return value;
    }
  } else {
    return value;
  }
};
