import React from "react";
import { FiRefreshCcw } from "react-icons/fi";
import analyticGetBrowserInfo from "../../../../../services/analyticGetBrowserInfo";
import { useDispatch } from "react-redux";
import { setBrowserinfo, setLoading } from "../../../../../redux/features/portal/portalSlice";
import { Button } from "react-bootstrap";

const Refresh = ({ id }) => {
  const dispatch = useDispatch();
  const handleRefresh = async () => {
    dispatch(setLoading({ id, value: true }));

    const browserInfo = await analyticGetBrowserInfo(id);
    dispatch(setBrowserinfo({ value: browserInfo, id }));
    dispatch(setLoading({ id, value: false }));
  };
  return (
    <Button onClick={handleRefresh}>
      <FiRefreshCcw />
    </Button>
  );
};

export default Refresh;
