export const _initialize = (state, action) => {
  state.tabs[action.payload] = {
    ...state.tabs[action.payload],
    browserinfo: undefined,
    browserdata: undefined,
    pageY: undefined,
    getdata: undefined,
    buffData: undefined,
    data: undefined,
    newData: undefined,
    filters: undefined,
    screenState: {
      past: undefined,
      present: undefined,
    },
    objectState: "view",
    loading: true,
    browserView: "browser",
    // grids: undefined,
  };
};

export const _setScreenState = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].screenState.past = state.tabs[action.payload.tabID].screenState.present;
    state.tabs[action.payload.tabID].screenState.present = action.payload.value;
  }
};

export const _setObjectState = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    if (action.payload.value == "view") {
      state.tabs[action.payload.tabID].buffData = undefined;
      state.tabs[action.payload.tabID].newData = undefined;
      state.tabs[action.payload.tabID].shouldCalc = false;
    }
    state.tabs[action.payload.tabID].objectState = action.payload.value;
  }
};

export const _setAccessRights = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].accessRights = action.payload.value;
  }
};

export const _setsetFormDesign = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    const formDesign = { ...action.payload.value };
    const model = formDesign.model;
    Object.keys(model).map((tableKey) => {
      model[tableKey].fields.map((field) => {
        const EditorArguments = Array.from(field.editor.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
        EditorArguments.forEach((element) => {
          const splitted = element.split(".");
          const tableName = splitted[0];
          const fieldName = splitted[1];
          if (formDesign?.relativeFields?.[tableName]?.[fieldName]) {
            formDesign.relativeFields[tableName][fieldName].push({ table: tableKey, key: field.name });
          } else {
            formDesign.relativeFields = {
              ...formDesign.relativeFields,
              [tableName]: {
                ...formDesign?.relativeFields?.[tableName],
                [fieldName]: [{ table: tableKey, key: field.name }],
              },
            };
          }
        });
      });
    });
    state.tabs[action.payload.tabID].formDesign = formDesign;
  }
};

export const _setRelJobMenu = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].relJobMenu = action.payload.value;
  }
};

export const _setCustomRelJobMenuProperty = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].customRelJobs = {
      ...state.tabs?.[action.payload.tabID]?.customRelJobs,
      [action.payload.key]: action.payload.value,
    };
  }
};

export const _setPrepareNew = (state, action) => {
  if (state.tabs[action.payload.tabID]) {
    state.tabs[action.payload.tabID].prepareNew = action.payload.value;
    state.tabs[action.payload.tabID].buffData = action.payload.value;
    state.tabs[action.payload.tabID].newData = action.payload.value;
  }
};
