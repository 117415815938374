import React from "react";
import { useSelector } from "react-redux";
import { getCalendarSelectedDates } from "../../../../redux/selectors";
import Record from "./Record";

const Details = ({ tabID, events, handleClick }) => {
  const selectedDates = useSelector((state) => getCalendarSelectedDates(state, tabID));
  const [displayEvents, setDisplayEvents] = React.useState([]);

  React.useEffect(() => {
    if (selectedDates?.start) {
      setDisplayEvents(
        events.filter((event) => {
          const selectedStart = new Date(selectedDates.start);
          const eventStart = new Date(event.start);
          return getOnlyDate(selectedStart) === getOnlyDate(eventStart);
        })
      );
    } else {
      setDisplayEvents([]);
    }
  }, [selectedDates]);
  return (
    <div style={{ height: "30%", overflowX: "auto" }}>
      {selectedDates?.start &&
        displayEvents.length > 0 &&
        displayEvents.map((x) => <Record key={x.id} event={x} handleClick={handleClick} />)}
    </div>
  );
};

export default Details;

const getOnlyDate = (date) => [date.getFullYear(), (date.getMonth() + 1).padLeft(), date.getDate().padLeft()].join("-");
