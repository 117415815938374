import React from "react";
import { HiTrash } from "react-icons/hi";
import { useSelector } from "react-redux";
import { getAccesRights, getObjectState } from "../../../../../redux/selectors";
import ModalDelete from "../modals/ModalDelete";
import deleteRecord from "../../../../functions/deleteRecord";
import { Dropdown } from "react-bootstrap";
import { languages } from "../../../../languages";

const DeleteRecord = ({ tabID }) => {
  const objectState = useSelector((state) => getObjectState(state, tabID));
  const [showDelete, setShowDelete] = React.useState(false);
  const [response, setResponse] = React.useState(false);
  const currentLang = useSelector((state) => state.settings.language);
  const accessRights = useSelector((state) => getAccesRights(state, tabID));

  React.useEffect(() => {
    if (response) {
      deleteRecord(tabID);
      setResponse(false);
    }
  }, [response]);

  return (
    accessRights?.remove == true && (
      <React.Fragment>
        <Dropdown.Item
          onClick={() => setShowDelete(true)}
          disabled={objectState != "view"}
          className="d-flex align-items-center justify-content-start"
        >
          <div className="me-1 d-flex justify-content-start align-items-center">
            <HiTrash size="1.2rem" />
          </div>
          <span> {languages.generalText[currentLang].delete}</span>
        </Dropdown.Item>
        <ModalDelete show={showDelete} setShow={setShowDelete} setResponse={setResponse} />
      </React.Fragment>
    )
  );
};

export default DeleteRecord;
