import React from "react";
import { getCurrentTab } from "../../redux/selectors";
import ScreenSpinner from "./ScreenSpinner";
import EditMaster from "./edit master/EditMaster";
import { useSelector } from "react-redux";
import Report from "./report/Report";
import BatchJob from "./batch job/BatchJob";
import WebPage from "./web page/WebPage";
import EditList from "./edit list/EditList";

const SoftoneObject = React.memo(({ tabID }) => {
  const tab = useSelector((state) => getCurrentTab(state, tabID));

  return (
    <>
      {tab.loading && <ScreenSpinner />}
      {
        {
          editmaster: <EditMaster tabID={tabID} />,
          report: <Report tabID={tabID} />,
          dialog: <BatchJob tabID={tabID} />,
          webpage: <WebPage tabID={tabID} />,
          editlist: <EditList tabID={tabID} />,
        }[tab.cmd.toLowerCase()]
      }
    </>
  );
});

export default SoftoneObject;
