import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { languages } from "../../languages";
import { BsInfo } from "react-icons/bs";

const ModalWarningForBlock = () => {
  const [isBlocked, setIsBlocked] = React.useState(false);
  const [blcdateDD, setBlcDate] = React.useState();
  const currentLang = useSelector((state) => state.settings.language);

  const session = useSelector((state) => state.session);

  React.useEffect(() => {
    const today = new Date(`${formatUSDate(new Date())}`);
    const blckdate = new Date(session.blcdate);
    var Difference_In_Time = blckdate.getTime() - today.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    setBlcDate(formatDate(blckdate));
    setIsBlocked(Difference_In_Days <= 15 && Difference_In_Days >= 0);
  }, []);

  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={isBlocked}
      animation={false}
      centered
      backdrop="static"
      keyboard={false}
    >
      <span
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%,-50%)",
          backgroundColor: "var(--bs-primary)",
          borderRadius: "50%",
          color: "var(--bs-light)",
        }}
        className="d-flex justify-content-center align-items-center shadow"
      >
        <BsInfo size="3rem" className="m-1 p-1" />
      </span>
      <Modal.Body className="d-flex justify-content-start align-items-center mt-4" style={{ userSelect: "none" }}>
        <div>
          {languages.warnings[currentLang].aboutToEnd}
          <u>
            <span style={{ fontWeight: "bold" }}>{blcdateDD}</span>
          </u>
          .
          <br />
          {languages.warnings[currentLang].contact1}
          <a href="tel:+302165005060">+302165005060</a> {languages.warnings[currentLang].contact2}
          <br />
          {languages.warnings[currentLang].note}
        </div>
      </Modal.Body>
      <Modal.Footer className="p-0 d-flex align-items-center justify-content-center">
        <Button variant="primary w-100" onClick={() => setIsBlocked(false)}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalWarningForBlock;

const formatDate = (date) => {
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join("/");
};

const formatUSDate = (date) => {
  return [padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate()), date.getFullYear()].join("/");
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};
