import React from "react";
import { Container, Navbar, Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/logo.svg";
import { toogleMenu } from "../../redux/features/modals/modalsSlice";
import { HiOutlineMenu } from "react-icons/hi";
import { getCurrentTabIndex, getTabs } from "../../redux/selectors";
import TaskOnActiveDropDown from "./main bar/TaskOnActiveDropDown";

const MainBar = () => {
  const dispatch = useDispatch();
  const tabs = useSelector((state) => getTabs(state));
  const tabID = useSelector((state) => getCurrentTabIndex(state));
  const containerRef = React.useRef(null);
  const firstDiv = React.useRef(null);
  const secondDiv = React.useRef(null);

  const UserDropDown = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      style={{ cursor: "pointer", textDecoration: "none", color: "var(--bs-dark)" }}
      className="d-flex align-items-center justify-cintent-center user-select-none"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  return (
    <div
      id="crm-main-navigator"
      className="crm-main-navigator "
      // style={{ position: "sticky", top: "0", zIndex: 1030 }}
    >
      <Navbar>
        <Container className="d-flex" ref={containerRef} style={{ maxWidth: "100%" }}>
          <div className="d-flex gap-3" ref={firstDiv}>
            <Button
              variant="secondary"
              onClick={() => {
                dispatch(toogleMenu());
              }}
            >
              <HiOutlineMenu style={{ cursor: "pointer" }} />
            </Button>
            <Navbar.Brand className="d-flex alin-items-center">
              <img src={logo} width="25" height="25" className="me-2 align-self-center" />
              Web CRM
            </Navbar.Brand>
          </div>

          {Object.keys(tabs).length > 0 && (
            <div style={{ display: "flex", flexGrow: 1, justifyContent: "end" }}>
              <Dropdown className="d-flex" align="end">
                <Dropdown.Toggle as={UserDropDown}>
                  <Button variant="secondary" className="d-flex align-items-center">
                    <span
                      className="text-truncate"
                      style={{
                        maxWidth: containerRef?.current?.clientWidth - firstDiv?.current?.clientWidth - 56,
                        // fontSize: "1.3rem",
                      }}
                    >
                      {{ "-1": "DashBoard", "-2": "Settings", "-3": "User info" }[tabID] ?? tabs[tabID].text}
                    </span>
                  </Button>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="shadow above-all-backdrop p-0"
                  style={{
                    maxWidth: "55vw",
                    // minWidth: "55vw",
                    maxHeight: "55vh",
                    overflow: "hidden",
                    overflowY: "auto",
                  }}
                >
                  {Object.keys(tabs).map((key) => (
                    <TaskOnActiveDropDown key={key} tab={tabs[key]} />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </Container>
      </Navbar>
    </div>
  );
};

export default MainBar;
