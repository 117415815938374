import React from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getCustomRelJobs, getFormDesign, getRecData, getRelJobMenu, getTabIdx } from "../../../../../redux/selectors";
import { useDispatch } from "react-redux";
import { setNewTab } from "../../../../../redux/features/ui/uiSlice";
import { BsBuildingGear, BsFileEarmarkMedical, BsFillFileEarmarkSpreadsheetFill, BsStopwatch } from "react-icons/bs";
import { setScreenFromJob } from "../../../../../redux/features/screens/screensSlice";
import { languages } from "../../../../languages";
import CheckInOut from "./CheckInOut";

const RelJob = ({ tabID }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => getRecData(state, tabID));
  const formDesign = useSelector((state) => getFormDesign(state, tabID));

  const idx = useSelector((state) => getTabIdx(state, tabID));
  const relJobMenu = useSelector((state) => getRelJobMenu(state, tabID));
  const customrelJobs = useSelector((state) => getCustomRelJobs(state, tabID));

  const currentLang = useSelector((state) => state.settings.language);

  const handleJob = async (property) => {
    const job = { ...property };
    const pattern = /^\{([^{}]+)\}(.*)/;
    const match = job.command.match(pattern);
    let customParams = {};
    if (match) {
      const keyValuePairs = match[1].split(",");
      keyValuePairs.forEach((pair) => {
        const [key, value] = pair.split(":");
        if (key.toLowerCase() == "editoptions") {
          const editArray = value.toLowerCase().split(";");
          editArray.forEach((x) => (customParams[x.replaceAll("'", "").replaceAll('"', "")] = true));
        } else {
          customParams[key.toLowerCase()] = value.toLowerCase();
        }
      });
    }

    job.command = match ? match[2] : job.command;

    const EditorArguments = Array.from(job.command.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
    EditorArguments.forEach((y) => {
      var splitted = y.split(".");
      var tableName = splitted[0];
      var fieldName = splitted[1];
      var replaceValue;
      if (data[tableName]?.[0].hasOwnProperty(fieldName)) {
        replaceValue = data[tableName][0][fieldName].split("|")[0];
      } else {
        const replaceModel = formDesign.model?.[tableName]?.fields.filter((el) => el.name == fieldName)[0];
        if (replaceModel?.editType == "Selector") {
          replaceValue = 0;
        } else {
          replaceValue = "";
        }
      }

      job.command = job.command.replaceAll(`[${y}]`, replaceValue);
    });

    const params = new URLSearchParams(job.command);
    const properties = {};
    for (const [key, value] of params.entries()) {
      properties[key] = value;
    }
    job.commandParams = { ...properties, ...customParams };
    job.screenState = { present: undefined, past: undefined };
    job.objectState = "view";
    job.browseExpandable = job.cmd === "EditMaster" ? true : undefined;
    job.isWindow = false;
    job.wasInTab = tabID;
    job.jobForNew = job.commandParams?.ae == 2;

    console.log(job);

    const value = {
      ...job,
      idx: `job_${idx}_${job.idx}`,
    };
    dispatch(
      setScreenFromJob({
        key: `job_${idx}_${job.idx}`,
        value,
      })
    );
    dispatch(setNewTab(value));
  };

  return (
    <>
      <span className="s1-rel-job-menu-title"> {languages.generalText[currentLang].relJobs}</span>
      {relJobMenu &&
        Object.keys(relJobMenu).map((key) => {
          const job = relJobMenu[key];
          if (job.cmd == "EditMaster" && job.command.toLowerCase().startsWith("_checkin")) {
            const checkInField = customrelJobs?.checkIn;
            if (checkInField) {
              const table = checkInField.split(".")[0];
              const field = checkInField.split(".")[1];
              const hasValue = data?.[table]?.[0]?.[field];
              if (hasValue) {
                return;
              } else {
                return <CheckInOut tabID={tabID} key={key} id={key} job={job} type={"checkIn"} />;
              }
            } else {
              return;
            }
          } else if (job.cmd == "EditMaster" && job.command.toLowerCase().startsWith("_checkout")) {
            const checkOutField = customrelJobs?.checkOut;
            if (checkOutField) {
              const table = checkOutField.split(".")[0];
              const field = checkOutField.split(".")[1];
              const hasValue = data?.[table]?.[0]?.[field];
              if (hasValue) {
                return;
              } else {
                const checkInField = customrelJobs?.checkIn;
                if (checkInField) {
                  const tableIn = checkInField.split(".")[0];
                  const fieldIn = checkInField.split(".")[1];
                  const hasValueIn = data?.[tableIn]?.[0]?.[fieldIn];
                  if (hasValueIn) {
                    return <CheckInOut tabID={tabID} key={key} id={key} job={job} type={"checkOut"} />;
                  } else {
                    return;
                  }
                } else {
                  return;
                }
              }
            } else {
              return;
            }
          } else {
            return (
              <Dropdown.Item
                id={`id-mn${key}`}
                key={key}
                className="d-flex align-items-center justify-content-start"
                onClick={() => handleJob(job)}
              >
                <div className="me-1 d-flex justify-content-start align-items-center">
                  {{
                    EditMaster: <BsFileEarmarkMedical color="var(--bs-orange)" />,
                    Report: <BsFillFileEarmarkSpreadsheetFill color="rgb(19, 185, 148)" />,
                    Dialog: <BsBuildingGear color="var(--bs-danger)" />,
                  }[job.cmd] ?? "default value"}
                </div>
                <span className="text-truncate me-2">{job?.text}</span>
              </Dropdown.Item>
            );
          }
        })}
    </>
  );
};

export default RelJob;
