import React from "react";
import { Button, Modal, ButtonGroup } from "react-bootstrap";
import { HiX, HiCheck } from "react-icons/hi";
import { useSelector } from "react-redux";
import { languages } from "../../../../languages";
import { BiQuestionMark } from "react-icons/bi";

const ModalDelete = ({ show, setShow, setResponse }) => {
  const currentLang = useSelector((state) => state.settings.language);

  const handleAccept = () => {
    setShow(false);
    setResponse(true);
  };

  const handleDecline = () => {
    setShow(false);
  };

  return (
    <Modal
      backdropClassName="above-all-backdrop print"
      dialogClassName="above-all-dialog"
      show={show}
      // animation={false}
      centered
      onHide={handleDecline}
      backdrop="static"
      size="lg"
    >
      <span
        style={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%,-50%)",
          backgroundColor: "var(--bs-warning)",
          color: "var(--bs-dark)",
          borderRadius: "50%",
        }}
        className="d-flex justify-content-center align-items-center shadow"
      >
        <BiQuestionMark size="3rem" className="m-1 p-1" />
      </span>

      <Modal.Header className="p-2 ps-3 pb-0 border-0" closeButton />
      <Modal.Body className="d-flex justify-content-start align-items-center mt-0" style={{ userSelect: "none" }}>
        {languages.generalText[currentLang].aboutToDelete}
        <br />
        {languages.generalText[currentLang].wishToContinue}
      </Modal.Body>
      <Modal.Footer className="picker-buttons">
        <ButtonGroup>
          <Button variant="outline-secondary" className="rounded-0" onClick={handleDecline}>
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].cancel}
            </div>
          </Button>
          <Button className="rounded-0" variant="outline-secondary" onClick={handleAccept}>
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20 }}>
              {languages.generalText[currentLang].delete}
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDelete;
