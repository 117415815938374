import React from "react";
import { useSelector } from "react-redux";
import { getRecData, getURL } from "../../../redux/selectors";
import noImage from "../../../assets/noimage.svg";

const S1Image = ({ element, tabID, screenState }) => {
  const url = useSelector((state) => getURL(state));

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const data = useSelector((state) => getRecData(state, tabID));

  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    if (data?.[table]?.[0]?.[key]) {
      console.log(data?.[table]?.[0]?.[key]);
      if (screenState == "form") {
        setValue(data?.[table]?.[0]?.[key]);
      }
    } else {
      setValue(undefined);
    }
  }, [data?.[table]?.[0]?.[key]]);
  return (
    <div
      className="s1image w-100 d-flex align-items-start justify-content-center pt-2"
      style={{ flex: `${element.flex * 100}%` }}
    >
      <div
        style={{
          backgroundImage: value ? `url(${url}/?filename=${value})` : `url(${noImage})`,
          backgroundSize: "contain",
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          height: "250px",
          width: "100%",
          display: element.visible ? "block" : "none",
        }}
      ></div>
    </div>
  );
};

export default S1Image;
