import React from "react";
import SideBar from "../../bars/SideBar";
import { useSelector } from "react-redux";
import { getCurrentTabIndex, getTabs } from "../../../redux/selectors";
import SoftoneObject from "../../ui/SoftoneObject";
import DashBoard from "../../ui/portal/DashBoard";
import Settings from "../../ui/settings/Settings";
import User from "../../ui/user info/User";

const MainPanel = ({ webMenuData }) => {
  const [currentMenus, setCurrentMenus] = React.useState(webMenuData);
  const [previousStack, setPreviousStack] = React.useState([]);
  const [currrentTitle, setCurrentTitle] = React.useState("");
  const [titleStack, setTitleStack] = React.useState([]);

  const tabs = useSelector((state) => getTabs(state));
  const currentTabID = useSelector((state) => getCurrentTabIndex(state));

  React.useEffect(() => {
    setCurrentMenus(webMenuData);
    setPreviousStack([]);
  }, []);

  return (
    <div className="d-flex overflow-hidden" style={{ flex: 1 }}>
      <SideBar
        webMenuData={webMenuData}
        currentMenus={currentMenus}
        setCurrentMenus={setCurrentMenus}
        previousStack={previousStack}
        setPreviousStack={setPreviousStack}
        currrentTitle={currrentTitle}
        setCurrentTitle={setCurrentTitle}
        titleStack={titleStack}
        setTitleStack={setTitleStack}
      />
      <div
        className=" d-flex flex-column"
        style={{
          flex: 1,
          overflowX: "hidden",
        }}
      >
        <div className={`tab-panel dashboard${currentTabID == -1 ? " active" : ""}`} data-tab-id="portal">
          <DashBoard />
        </div>
        <div className={`tab-panel settings${currentTabID == -2 ? " active" : ""}`} data-tab-id="settings">
          <Settings />
        </div>
        <div className={`tab-panel user${currentTabID == -3 ? " active" : ""}`} data-tab-id="user">
          <User />
        </div>
        {Object.keys(tabs).map((key) => (
          <div className={`tab-panel${key == currentTabID ? " active" : ""}`} key={key} data-tab-id={key}>
            <SoftoneObject tabID={key} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainPanel;
