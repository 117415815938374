import React from "react";
import { BsChevronDown } from "react-icons/bs";
import printTemplates from "../../../services/printTemplates";
import PickerSpinner from "../PickerSpinner";
import { useSelector } from "react-redux";
import { getCommand, getCommandByIndex } from "../../../redux/selectors";
import sqlData from "../../../services/sqlData";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";

const CrmDropDownTemplates = (props) => {
  const boundRef = React.useRef(null);
  const tableRef = React.useRef(null);
  const pickerRef = React.useRef(null);

  const [openPicker, setOpenPicker] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);

  const [data, setData] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);

  const command = useSelector((state) => getCommandByIndex(state, props.tabID));

  React.useEffect(() => {
    if (openPicker) {
      const clickListener = (e) => {
        const cliii = pickerRef.current.contains(e.target) || boundRef.current.contains(e.target);
        if (!cliii) {
          setOpenPicker(false);
          setIsFocused(false);
        }
      };

      document.addEventListener("mousedown", clickListener);
      return () => document.removeEventListener("mousedown", clickListener);
    }
  }, [openPicker]);

  const handleClick = async () => {
    if (openPicker) {
      setOpenPicker(false);
      setIsFocused(false);
    } else {
      setOpenPicker(true);
      setIsFocused(true);
      if (!data) {
        setLoading(true);
        if (command.split("&")[0] == "SOEXPENDITURES") {
          const response = await sqlData({ SqlName: "printTemplates", sosource: 202111 });
          console.log(response);
          if (response.success && response.totalcount > 0) {
            response.data = response.rows.map((item) => [item.TEMPLATES, item.NAME]);
            setData(response);
          } else {
            setData(response);
          }
        } else {
          const response = await printTemplates(props.tabID);
          setData(response);
        }
        setLoading(false);
      }
    }
  };

  return (
    <div className="s1templateselect w-100" style={{ flex: `100%`, overflow: "hidden" }}>
      <InputGroup ref={boundRef} className={`crm-floating-with-icon${isFocused ? " underline-focus" : ""}`}>
        <FloatingLabel controlId={props.name} label={props.label} style={{ whiteSpace: "nowrap" }} className="w-100">
          <Form.Control
            className="d-flex w-100 ps-1 border-0"
            readOnly={true}
            value={props.value}
            name={props.name}
            placeholder={props.label}
            onClick={handleClick}
            onFocus={(e) => e.target.blur()}
          />
        </FloatingLabel>
        {props.value != "" && (
          <div
            className="d-flex align-items-center justify-content-center me-1"
            onClick={() => {
              setIsFocused(false);
              setOpenPicker(false);
              props.function(["", ""]);
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}

        <div className="d-flex align-items-center justify-content-center me-1" onClick={handleClick}>
          <BsChevronDown color="var(--bs-primary)" size="1.5rem" />
        </div>
      </InputGroup>
      {openPicker && (
        <div
          className="input-picker-div custom"
          ref={pickerRef}
          style={{
            left: boundRef.current.getBoundingClientRect().left,
            width: boundRef.current.getBoundingClientRect().width,
            top:
              boundRef.current.getBoundingClientRect().bottom -
              (boundRef.current.getBoundingClientRect().bottom + 200 > window.innerHeight
                ? 200 + boundRef.current.getBoundingClientRect().height
                : 0),
            height: "200px",
            overflow: "hidden",
          }}
        >
          <div
            className="input-picker  w-100 h-100"
            style={{
              overflow: "auto",
            }}
          >
            {loading && <PickerSpinner />}
            {data?.success ? (
              <div className="input-picker-table w-100" ref={tableRef}>
                {data.data.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`input-picker-table-row`}
                      onClick={() => {
                        setOpenPicker(false);
                        setIsFocused(false);
                        props.function(item);
                      }}
                    >
                      <span>{item[1]}</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="d-flex h-100 justify-content-center align-items-center">{data?.error}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CrmDropDownTemplates;
