import React from "react";
import { useSelector } from "react-redux";
import {
  getBrowserInfo,
  getBrowserView,
  getCanCalendar,
  getCommandByIndex,
  gethasGroups,
} from "../../../redux/selectors";
import ErrorDisplay from "../ErrorDisplay";
import NoBrowserData from "../NoBrowserData";
import Calendar from "./calendar/Calendar";
import { calendarObjects } from "../../../logic/calendarObjects";
import { useDispatch } from "react-redux/es";
import { canCalendar, setBrowserInfo, setLoading } from "../../../redux/features/ui/uiSlice";
import GroupBar from "./browser/grouping/GroupBar";
import NewButton from "./buttons/NewButton";
import PullToRefresh from "react-simple-pull-to-refresh";
import PullingContent from "./refresh/PullingContent";
import RefreshingContent from "./refresh/RefreshingContent";
import serviceGetBrowserInfo from "../../../services/getBrowserInfo";
import List from "./browser/List";

const Browser = (props) => {
  const dispatch = useDispatch();
  const browserInfo = useSelector((state) => getBrowserInfo(state, props.tabID));
  const browserView = useSelector((state) => getBrowserView(state, props.tabID));
  const canCalendar1 = useSelector((state) => getCanCalendar(state, props.tabID));
  const object = useSelector((state) => getCommandByIndex(state, props.tabID)).split("&")[0];
  const grouping = useSelector((state) => gethasGroups(state, props.tabID));

  React.useEffect(() => {
    calendarObjects.indexOf(object) > -1 && dispatch(canCalendar({ tabID: props.tabID }));
  }, []);

  const handleRefresh = async () => {
    dispatch(setLoading({ show: true, tabID: props.tabID }));
    const browserInfo = await serviceGetBrowserInfo(props.tabID);
    dispatch(setBrowserInfo({ value: browserInfo, tabID: props.tabID }));
    dispatch(setLoading({ show: false, tabID: props.tabID }));
  };

  return (
    <div style={{ ...props.style }} className="s1-object-browser">
      <PullToRefresh
        onRefresh={handleRefresh}
        pullingContent={<PullingContent tabID={props.tabID} />}
        refreshingContent={<RefreshingContent tabID={props.tabID} />}
        className="s1-pull-to-refresh"
        isPullable={browserView == "browser"}
        pullDownThreshold={110}
        maxPullDownDistance={120}
      >
        {browserInfo &&
          (browserInfo?.success ? (
            browserInfo?.totalcount > 0 ? (
              <>
                {/* {grouping && <GroupBar tabID={props.tabID} />} */}
                <div
                  className="browser-table w-100 h-100"
                  style={{
                    flexGrow: 1,
                    overflow: "hidden",
                    display: browserView == "browser" ? "flex" : "none",
                    flex: browserView == "browser" ? "1 1 100%" : undefined,
                  }}
                >
                  <List tabID={props.tabID} />
                </div>
                {canCalendar1 && browserView == "calendar" && <Calendar tabID={props.tabID} />}
              </>
            ) : browserView == "calendar" && canCalendar1 ? (
              <Calendar tabID={props.tabID} />
            ) : (
              <NoBrowserData tabID={props.tabID} />
            )
          ) : (
            <ErrorDisplay error={browserInfo?.error} />
          ))}
      </PullToRefresh>
      {browserView == "browser" && <NewButton tabID={props.tabID} />}
    </div>
  );
};

export default Browser;
