import React from "react";
import { useSelector } from "react-redux";
import { getBrowserInfo } from "../../../redux/selectors";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useDispatch } from "react-redux";
import { setBrowserInfo, setLoading } from "../../../redux/features/ui/uiSlice";
import serviceGetBrowserInfo from "../../../services/getBrowserInfo";
import PullingContent from "../edit master/refresh/PullingContent";
import RefreshingContent from "../edit master/refresh/RefreshingContent";
import NoBrowserData from "../NoBrowserData";
import ErrorDisplay from "../ErrorDisplay";
import ShowRoomList from "./ShowRoomList";
import CartButton from "../edit master/buttons/CartButton";

const ShowRoom = ({ tabID, style }) => {
  const dispatch = useDispatch();
  const browserInfo = useSelector((state) => getBrowserInfo(state, tabID));

  const handleRefresh = async () => {
    dispatch(setLoading({ show: true, tabID }));
    const browserInfo = await serviceGetBrowserInfo(tabID);
    dispatch(setBrowserInfo({ value: browserInfo, tabID }));
    dispatch(setLoading({ show: false, tabID }));
  };

  return (
    <div className="s1-object-browser" style={{ ...style }}>
      <PullToRefresh
        onRefresh={handleRefresh}
        pullingContent={<PullingContent tabID={tabID} />}
        refreshingContent={<RefreshingContent tabID={tabID} />}
        className="s1-pull-to-refresh"
        // isPullable={browserView == "browser"}
        pullDownThreshold={110}
        maxPullDownDistance={120}
      >
        {browserInfo &&
          (browserInfo?.success ? (
            browserInfo?.totalcount > 0 ? (
              <ShowRoomList tabID={tabID} />
            ) : (
              <NoBrowserData tabID={tabID} />
            )
          ) : (
            <ErrorDisplay error={browserInfo?.error} />
          ))}
      </PullToRefresh>
      <CartButton tabID={tabID} />
    </div>
  );
};

export default ShowRoom;
