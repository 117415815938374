import React from "react";
import { AiOutlinePoweroff } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setLogOut, toogleMenu } from "../../../redux/features/modals/modalsSlice";
import axios from "axios";
import { clear as clearSession } from "../../../redux/features/session/sessionSlice";
import { messaging } from "../../../firebase";
import { deleteToken } from "firebase/messaging";
import { getClientID, getURL } from "../../../redux/selectors";

const LogOut = () => {
  const dispatch = useDispatch();
  const url = useSelector((state) => getURL(state));
  const clientID = useSelector((state) => getClientID(state));
  const session = useSelector((state) => state.session);
  const settings = useSelector((state) => state.settings.app);

  const handleLogOut = async () => {
    dispatch(setLoading(true));
    await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/logout`, {
      key: session?.loginid,
      notificationID: session?.notificationID,
      url,
      clientID,
      appID: process.env.REACT_APP_SOFTONE_APPID,
    });
    session?.notificationID && deleteToken(messaging);
    dispatch(clearSession());
    dispatch(setLoading(false));
  };

  return (
    <>
      <div
        onClick={(e) => {
          dispatch(toogleMenu());
          if (settings.confirmExit == 1) dispatch(setLogOut(true));
          else handleLogOut();
        }}
      >
        <AiOutlinePoweroff size="1.5rem" color="var(--bs-danger)" />
      </div>
    </>
  );
};

export default LogOut;
