import React from "react";
import { useSelector } from "react-redux";
import { languages } from "../../languages";
import CrmInput from "../CrmInput";

const UserPanel = () => {
  const login = useSelector((state) => state.login);
  const session = useSelector((state) => state.session);
  const systemParams = useSelector((state) => state.systemParams);
  const currentLang = useSelector((state) => state.settings.language);

  const render = [
    { readOnly: true, label: "Username", value: login?.username },
    { readOnly: true, label: languages.user[currentLang].name, value: session?.refIDName },
    { readOnly: true, label: languages.user[currentLang].companyName, value: systemParams?.companyinfo?.name },
    { readOnly: true, label: languages.user[currentLang].branchName, value: systemParams?.companyinfo?.branch?.name },
    { readOnly: true, label: languages.user[currentLang].address, value: systemParams?.companyinfo?.address },
    { readOnly: true, label: languages.user[currentLang].afm, value: systemParams?.companyinfo?.afm },
    { readOnly: true, label: languages.user[currentLang].irs, value: systemParams?.companyinfo?.irsdata },
    { readOnly: true, label: "SΝ:", value: systemParams?.serialnumber },
    { readOnly: true, label: "S1 version", value: systemParams?.s1version },
    { readOnly: true, label: "Web CRM version:", value: process.env.REACT_APP_VERSION },
    { readOnly: true, label: languages.user[currentLang].d1users, value: session?.users },
    { readOnly: true, label: languages.user[currentLang].d1blcdate, value: dateformat(session?.blcdate) },
  ];

  return (
    <>
      {render.map((item, index) => (
        <CrmInput {...item} key={index} />
      ))}
    </>
  );
};

export default UserPanel;

const dateformat = (date) => {
  const d = new Date(date);
  return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
};
